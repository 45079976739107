<ng-template #content let-cs="close('save-changes')" let-dc="dismiss('close-window')" let-db="dismiss('close-button')">
  <div class="modal-header">
    <h4 class="modal-title">Edycja koloru</h4>
    <button type="button" class="close" aria-label="Close" (click)="dc">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="change-color">
      <span [cpToggle]="true" [cpDialogDisplay]="'inline'" [cpOutputFormat]="'hex'" [style.background]="selected_colour"
        [cpPresetColors]="preset_colors" [cpPresetLabel]="''" [cpAlphaChannel]="'disabled'" [cpDisableInput]="true"
        [cpCloseClickOutside]="false" [(colorPicker)]="selected_colour"></span>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" class="btn btn-primary" (click)="cs">Zapisz zmiany</button>
    </div>
  </div>
</ng-template>