import { map, single } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppHelperService } from './app-helper.service';
import { SharedDataService } from './shared-data.service';

class SessionAuthData {
  public is_authenticated: boolean;
  public is_admin: boolean;
  public username: string;
  public dbname: string;
}

@Injectable()
export class AuthenticationService {

  private authdata_key = 'appdata';

  constructor(
    private http: HttpClient,
    private apphelper: AppHelperService,
    private sharedData: SharedDataService
  ) {
  }

  public clear_model(model: SessionAuthData) {
    model.is_authenticated = false;
    model.is_admin = false;
    model.username = '';
    model.dbname = '';
  }

  public model_to_json(model: SessionAuthData): string {
    const s = JSON.stringify(model);
    // encrypt s
    return s;
  }

  public json_to_model(val: string): SessionAuthData {
    // decrypt val
    const ret: SessionAuthData = JSON.parse(val);
    return ret;
  }

  public login(_username: string, _password: string, _dbname: string) {
    return this.http.post('/api/login', { username: _username, password: _password, dbname: _dbname }).pipe(
      single(),
      map((data: any) => {
        const model = new SessionAuthData();
        model.is_authenticated = true;
        model.is_admin = data.admin;
        model.dbname = _dbname;
        model.username = data.username;
        sessionStorage.setItem(this.authdata_key, this.model_to_json(model));

        this.sharedData.user_name = _username;
        this.sharedData.db_name = _dbname;

        this.apphelper.clearSettings();
        sessionStorage.removeItem('filter_date');
        sessionStorage.removeItem('filter_wyjpow');
        sessionStorage.removeItem('filter_sam');
        sessionStorage.removeItem('last_sel_car_id');
        return data;
      }));
  }

  public logout() {
    sessionStorage.removeItem(this.authdata_key);
    this.sharedData.user_name = '';
    this.sharedData.db_name = '';
    this.apphelper.clearSettings();
    sessionStorage.removeItem('filter_date');
    sessionStorage.removeItem('filter_wyjpow');
    sessionStorage.removeItem('filter_sam');
    sessionStorage.removeItem('last_sel_car_id');
  }

  public is_authenticated(): boolean {
    const obj = this.get_model();
    return obj ? obj.is_authenticated : false;
  }

  public is_admin(): boolean {
    const obj = this.get_model();
    return obj ? obj.is_admin : false;
  }

  private get_model(): SessionAuthData {
    let ret = null;
    const val = sessionStorage.getItem(this.authdata_key);
    if (val)
      ret = this.json_to_model(val);
    return ret;
  }

  public get_username(): string {
    const obj = this.get_model();
    return obj ? obj.username : '';
  }

  public get_dbname(): string {
    const obj = this.get_model();
    return obj ? obj.dbname : '';
  }


}
