<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-6 offset-3 text-center">
      <h3>Nie można wyświetlić strony</h3>
      <br>
      <p>Możliwe przyczyny:</p>
      <ul class="mt-4">
        <li>Brak uprawnień</li>
        <li>Przekroczony czas sesji</li>
        <li>Błąd w aplikacji</li>
      </ul>
    </div>
  </div>
</div>