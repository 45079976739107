<div class="d-flex justify-content-between align-items-center card-stop-point">
  <div>
    <span class="point-title">
      {{model.nazwa}}
    </span><br>
    <span class="point-info">
      Trasa: {{model.trasa}} ({{model.pora_dnia()}})
    </span>
  </div>
  <div class="ml-auto mr-1" *ngIf="!model.has_gps_data()">
    <span class="badge badge-pill badge-danger">Brak GPS</span>
  </div>
</div>