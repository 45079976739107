<ng-template #content let-cs="close('save-changes')" let-dc="dismiss('close-window')" let-db="dismiss('close-button')">
  <div class="modal-header">
    <h4 class="modal-title">Zapis pozycji GPS</h4>
    <button type="button" class="close" aria-label="Close" (click)="dc">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Zapisz pozycję GPS dla zadania {{cartask_name}}</p>
    <form>
      <section *ngIf="stoppoints.length > 0">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioStopPoint" value="sp"
                 (change)="onRadioChange('sp')" [checked]="selected_sp()">
          <label class="form-check-label" for="radioStopPoint">z punktu zbiórki</label>
        </div>
        <div class="form-group">
          <select type="text" class="form-control" id="select-stoppoint" #t1 (change)="onChangeStopPoint(t1.value)"
                  [disabled]="attr_disabled('sp')">
            <option *ngFor="let sp of stoppoints" [value]="sp.id" [ngClass]="{ 'd-none': !sp.has_gps_data()}">
              {{sp.nazwa}}
            </option>
          </select>
        </div>
      </section>
      <section *ngIf="transferpoints.length > 0">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioTransferPoint" value="tp"
                 (change)="onRadioChange('tp')" [checked]="selected_tp()">
          <label class="form-check-label" for="radioTransferPoint">z punktu przesiadki</label>
        </div>
        <div class="form-group">
          <select type="text" class="form-control" id="select-transferpoint" #t2
                  (change)="onChangeTransferPoint(t2.value)" [disabled]="attr_disabled('tp')">
            <option *ngFor="let tp of transferpoints" [value]="tp.id" [ngClass]="{ 'd-none': !tp.has_gps_data()}">
              {{tp.nazwa}}
            </option>
          </select>
        </div>
      </section>
      <section>
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioAddressPoint" value="ap"
                 (change)="onRadioChange('ap')" [checked]="selected_ap()">
          <label class="form-check-label" for="radioAddressPoint">dla adresu</label>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" id="text-address" name="text-address" #t3
                 [readonly]="attr_readonly('ap')" [(ngModel)]="selected_address">
        </div>
      </section>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" class="btn btn-primary" (click)="cs">Zapisz zmiany</button>
    </div>
  </div>
</ng-template>
