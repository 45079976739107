<ng-template #content let-dw="dismiss('close-window')" let-db="dismiss('close-button')" let-cs="close('save-changes')">
  <div class="modal-header">
    <h4 class="modal-title">Zapis pozycji GPS</h4>
    <button type="button" class="close" aria-label="Close" (click)="dw">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="has_transferpoints()||has_stoppoints()">Zapisz wskazaną pozycję GPS dla:</p>
    <p *ngIf="!(has_transferpoints()||has_stoppoints())">Nie można zapisać pozycji - brak punktów do wyboru.</p>
    <form>
      <section *ngIf="has_stoppoints()">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioStopPoint" value="sp"
            (change)="onRadioChange('sp')" [checked]="selected_sp()">
          <label class="form-check-label" for="radioStopPoint">punktu zbiórki</label>
        </div>
        <div class="form-group">
          <select type="text" class="form-control" id="select-stoppoint" #t1 (change)="onChangeStopPoint(t1.value)"
            [disabled]="attr_disabled('sp')">
            <option *ngFor="let p of stop_points" [value]="p.id">{{p.nazwa}} ({{p.pora_dnia()}})</option>
          </select>
        </div>
      </section>
      <section *ngIf="has_transferpoints()">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioTransferPoint" value="tp"
            (change)="onRadioChange('tp')" [checked]="selected_tp()">
          <label class="form-check-label" for="radioTransferPoint">punktu przesiadki</label>
        </div>
        <div class="form-group">
          <select type="text" class="form-control" id="select-transferpoint" #t2
            (change)="onChangeTransferPoint(t2.value)" [disabled]="attr_disabled('tp')">
            <option *ngFor="let p of transfer_points" [value]="p.id">{{p.nazwa}}</option>
          </select>
        </div>
      </section>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" class="btn btn-primary" (click)="cs" *ngIf="has_transferpoints()||has_stoppoints()">Zapisz
        zmiany</button>
    </div>
  </div>
</ng-template>