import * as moment from 'moment';

export class CarTask {

  id: number;
  id_samochod: number;
  odb_doc: number;
  data: string;
  powrot: number;
  pora_wyj: number;
  id_zadanie: number;
  zadanie_info: string;
  lp_rozp: number;
  gps_lat: string;
  gps_lon: string;
  address: string;
  godz: string;
  czas_2: string;
  time_hour: number; // nie ma w bazie danych
  time_minutes: number;  // nie ma w bazie danych
  sam_wirt: boolean;
  rejon: string;
  kraj: string;

  // --------------------------------------------------------------------------
  constructor(obj: any) {
    this.id = Number(obj.ID);
    this.id_samochod = Number(obj.ID_SAMOCHOD);
    this.odb_doc = Number(obj.ODB_DOC);
    this.data = obj.DATA;
    this.powrot = Number(obj.POWROT);
    this.pora_wyj = Number(obj.PORA_WYJ);
    this.id_zadanie = Number(obj.ID_ZADANIE);
    this.zadanie_info = obj.ZADANIE_INFO;
    this.lp_rozp = Number(obj.LP_ROZP);
    this.gps_lat = obj.GPS_LAT;
    this.gps_lon = obj.GPS_LON;
    this.address = obj.ADDRESS;
    this.godz = obj.GODZ;
    this.czas_2 = obj.CZAS_2;
    this.time_hour = 0;
    this.time_minutes = 0;
    this.sam_wirt = obj.SAM_WIRT == 1 ? true : false;
    this.rejon = '';
    this.kraj = '';
  }

  // --------------------------------------------------------------------------
  public static createObject(obj: any): CarTask {
    return new CarTask(obj);
  }

  // --------------------------------------------------------------------------
  public has_gps_data(): boolean {
    return this.gps_lat.length !== 0 && this.gps_lon.length !== 0 ? true : false;
  }

  // --------------------------------------------------------------------------
  public has_valid_time(): boolean {
    return this.time_hour >= 0 && this.time_hour < 24 && this.time_minutes >= 0 && this.time_minutes < 60 ? true : false;
  }

  // --------------------------------------------------------------------------
  public is_start() {
    return this.id_zadanie === 1;
  }

  // --------------------------------------------------------------------------
  public is_stop() {
    return this.id_zadanie === 2;
  }


  // --------------------------------------------------------------------------
  public refresh_time_props() {
    // najpierw probuje z godz
    if (this.godz.length > 0) {
      const a = this.godz.split(':');
      const h = Number(a[0]);
      const m = Number(a[1]);
      if (!isNaN(h) && !isNaN(m)) {
        this.time_hour = h;
        this.time_minutes = m;
        return;
      }
    }
    // potem probuje z czas_2
    if (this.czas_2 && this.czas_2.length > 0) {
      const dt = moment(this.czas_2);
      if (dt.isValid()) {
        this.time_hour = dt.hour();
        this.time_minutes = dt.minute();
        return;
      }
    }
    console.log('CarTask.update_time_props: could not set value for time props');
  }

  // --------------------------------------------------------------------------
  public dump(): string {
    return `${this.zadanie_info} | ${this.id} | ${this.address}`;
  }

  // --------------------------------------------------------------------------
  private get_hour_string(_hours: number, _minutes: number): string {
    const s_hour = (_hours < 10) ? `0${_hours}` : `${_hours}`;
    const s_min = (_minutes < 10) ? `0${_minutes}` : `${_minutes}`;
    return `${s_hour}:${s_min}`;
  }

  // --------------------------------------------------------------------------
  private change_timestamp(cur_timestamp: string, delta_hours: number, delta_minutes: number): string {
    let ret = cur_timestamp;
    let mom = moment(cur_timestamp, 'YYYY-MM-DD HH:mm:ss');
    if (mom.isValid()) {
      const total_minutes = (delta_hours * 60) + delta_minutes;
      const dur = moment.duration(Math.abs(total_minutes), 'minutes');
      if (total_minutes > 0)
        mom.add(dur);
      else
        mom.subtract(dur);
      ret = mom.format('YYYY-MM-DD HH:mm:00');
    }
    return ret;
  }

  // --------------------------------------------------------------------------
  public on_time_props_changed(_delta_hours: number, _delta_minutes: number) {
    if (this.sam_wirt == false || this.is_start() || this.is_stop())
      this.godz = this.get_hour_string(this.time_hour, this.time_minutes);
    this.czas_2 = this.change_timestamp(this.czas_2, _delta_hours, _delta_minutes);
  }

  // --------------------------------------------------------------------------
  public get_date_from_czas2(): Date | null {
    let ret = null;
    if (this.czas_2 && this.czas_2.length > 0) {
      const dt = moment(this.czas_2);
      if (dt.isValid())
        ret = dt.toDate();
    }
    return ret;
  }
}
