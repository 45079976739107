import { map } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Car } from '../car';
import { DataService } from '../_services/data.service';
import { Router } from '@angular/router';
import { AppHelperService } from '../_services/app-helper.service';

@Component({
  selector: 'app-modal-add-car',
  templateUrl: './modal-add-car.component.html',
  styleUrls: ['./modal-add-car.component.css']
})
export class ModalAddCarComponent implements OnInit {

  closeResult: string;

  @ViewChild('content', { static: true }) content: any;
  @Input() filter_date = null;
  @Input() filter_wyjpow = null;
  @Input() filter_samtyp = null;
  public cars: Car[] = [];
  public s_wyjpow = '';
  public s_porawyj = '';
  public s_auto = '';
  public s_date = '';

  public selected_car_id = '1';
  constructor(
    private modal: NgbModal,
    private data_service: DataService,
    private apphelper: AppHelperService,
    public router: Router,
  ) { }

  ngOnInit() {
  }

  public refresh_data(): Promise<any> {
    this.cars.length = 0;
    // const _date = new Date(this.filter_date.year, this.filter_date.month - 1, this.filter_date.day);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);

    this.s_date = `${_date.toLocaleDateString()}`;
    this.s_wyjpow = _powrot === 0 ? 'wyjazd' : 'powrót';
    this.s_porawyj = _porawyj === 1 ? 'po południu' : 'przed południem';
    this.s_auto = this.apphelper.get_auto_typ(this.filter_samtyp);

    return this.data_service.getCarsNotUsed$(_date, _powrot, _porawyj, this.filter_samtyp).pipe(
      map((data: any) => { this.cars = data.map((obj: any) => Car.createObject(obj)); }))
      .toPromise();
  }

  public open_promise(): Promise<any> {
    const _this = this;
    return _this.refresh_data()
      .then(function () {
        if (_this.cars.length > 0)
          _this.selected_car_id = _this.cars[0].id.toString();
        return _this.modal.open(_this.content).result;
      });
  }

  public open(): void {
    this.open_promise().then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onChange(value: string): void {
    this.selected_car_id = value;
  }
}
