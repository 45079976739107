export class StopPoint {
  id: number;
  nazwa: string;
  id_odjazd: number;
  gps_lat: string;
  gps_lon: string;
  trasa: string;
  id_pora_odjazdu: number;

  constructor(obj: any) {
    this.id = Number(obj.ID);
    this.nazwa = obj.NAZWA;
    this.id_odjazd = Number(obj.ID_ODJAZD);
    this.gps_lat = obj.GPS_LAT;
    this.gps_lon = obj.GPS_LON;
    this.trasa = obj.TRASA;
    this.id_pora_odjazdu = Number(obj.ID_PORA_ODJAZDU);
  }

  public static createObject(obj: any): StopPoint {
    return new StopPoint(obj);
  }

  public has_gps_data(): boolean {
    return this.gps_lat.length !== 0 && this.gps_lon.length !== 0 ? true : false;
  }

  public is_inne(): boolean {
    const s_nazwa = this.nazwa.toLocaleUpperCase();
    return s_nazwa.startsWith('INNE');
  }

  public pora_dnia(): string {
    let ret = '';
    if (Number(this.id_pora_odjazdu) === 1)
      ret = 'po południu';
    else
      ret = 'przed południem';
    return ret;
  }

  public dump(): string {
    return `${this.nazwa} | ${this.id} | ${this.trasa}`;
  }
}
