import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CarTask } from '../car-task';
import { AppHelperService } from '../_services/app-helper.service';

@Component({
  selector: 'app-card-car-task',
  templateUrl: './card-car-task.component.html',
  styleUrls: ['./card-car-task.component.css']
})
export class CardCarTaskComponent implements OnInit {

  @Input() model: CarTask;
  @Output() onEditCarTask = new EventEmitter<number>();

  public is_valid_hour = true;
  public is_valid_minutes = true;

  public get_label(): string {
    return this.model.is_start() ? 'Wyjazd:' : 'Przyjazd:';
  }

  private validate_hour(val: number): boolean {
    return val >= 0 && val < 24;
  }

  private validate_minutes(val: number): boolean {
    return val >= 0 && val < 60;
  }

  public edit_cartask(id_cartask: number) {
    this.onEditCarTask.emit(id_cartask);
  }

  constructor(private apphelper: AppHelperService) { }

  ngOnInit() {
    this.model.refresh_time_props();
  }

  public onTimeHourChange(new_val) {
    const new_hour = Number(new_val);
    this.is_valid_hour = this.validate_hour(new_hour);
    if (this.is_valid_hour) {
      const delta_hour = new_hour - this.model.time_hour;
      this.model.time_hour = new_hour;
      this.model.on_time_props_changed(delta_hour, 0);
    }
  }

  public onTimeMinutesChange(new_val) {
    const new_min = Number(new_val);
    this.is_valid_minutes = this.validate_minutes(new_min);
    if (this.is_valid_minutes) {
      const delta_minutes = new_min - this.model.time_minutes;
      this.model.time_minutes = new_min;
      this.model.on_time_props_changed(0, delta_minutes);
    }
  }
}
