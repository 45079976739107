<ng-template #content let-cs="close('save-changes')" let-dc="dismiss('close-window')" let-db="dismiss('close-button')">
  <div class="modal-header">
    <h4 class="modal-title">Usuń samochód</h4>
    <button type="button" class="close" aria-label="Close" (click)="dc">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <b>Data:</b>&nbsp;{{s_date}}<br>
    <b>Rodzaj:</b>&nbsp;{{s_wyjpow}} {{s_porawyj}}<br>
    <b>Samochód:</b>&nbsp;{{s_auto}}<br>
    <div *ngIf="cars.length == 0">
      <br>
      <p><b>Brak samochodów do usunięcia.</b></p>
    </div>
    <form *ngIf="cars.length > 0">
      <div class="form-group">
        <label for="select-car" class="col-form-label">Wybierz samochód:</label>
        <select type="text" class="form-control" id="select-car" #t (change)="onChange(t.value)">
          <option *ngFor="let c of cars; let i = index" [value]="c.id" [attr.selected]="i == 0 ? true : null">{{c.name}}
          </option>
        </select>
      </div>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" *ngIf="cars.length > 0" class="btn btn-primary" (click)="cs">Usuń samochód</button>
    </div>
  </div>
</ng-template>