import { from, forkJoin, Observable } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Car } from '../car';
import { Person } from '../person';
import { CarInfo } from '../car-info';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';
import { MapPoint } from '../map-point';
import { CarTask } from '../car-task';
import { DataService } from '../_services/data.service';
import { HereMapsService } from '../_services/heremaps.service';
import { AppHelperService } from '../_services/app-helper.service';
import { ModalChangeCarComponent } from '../modal-change-car/modal-change-car.component';
import { ModalAddCarComponent } from '../modal-add-car/modal-add-car.component';
import { ModalDelCarComponent } from '../modal-del-car/modal-del-car.component';
import { ModalMapPointPositionComponent } from '../modal-map-point-position/modal-map-point-position.component';
import { ModalChangeColourComponent } from '../modal-change-colour/modal-change-colour.component';
import { Address } from '../address';

declare var H: any;

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modalChangeCar', { static: true }) modal_chg_car: ModalChangeCarComponent;
  @ViewChild('modalAddCar', { static: true }) modal_add_car: ModalAddCarComponent;
  @ViewChild('modalMapPointPosition', { static: true }) modal_map_pt_pos: ModalMapPointPositionComponent;
  @ViewChild('modalDelCar', { static: true }) modal_del_car: ModalDelCarComponent;
  @ViewChild('modalChangeColour', { static: true }) modal_chg_colour: ModalChangeColourComponent;

  public id_auto_unknown = 1; // id_auto_empty z autka

  public filter_date: any = { year: 1970, month: 1, day: 1 };
  public filter_wyjpow = 1; // 0 - powrot po pol, 1 - wyj po pol, 2 - wyj przed pol, 3 - powrot przed pol
  public filter_sam = 1; // 1 - dowozacy, 2 - docelowy, 3 - rozwozacy
  public filter_color = 'all'; //

  public map_cars = {}; // car_id => Car object

  private stop_points: StopPoint[] = []; // should be std::map
  private transfer_points: TransferPoint[] = []; // should be std::map
  public total_persons = 0;
  public total_persons_assigned = 0;
  public total_persons_not_assigned = 0;

  public car_info: CarInfo[] = [];
  public map_car_info = {}; // car_id => CarInfo object

  public mappoints: MapPoint[] = [];
  public gps_errors: any[] = [];
  public app_errors: string[] = [];

  private the_map: any = null;

  public selected_person_name = '';
  public selected_person_car = '';
  public last_sel_car_id = -1;

  public isCollapsedGPSError = true;
  public isCollapsedAppError = true;

  public carPersonsEnabled = false;
  public sel_car: Car = null;
  public sel_car_persons: Person[] = [];
  public sel_car_persons_org: Person[] = [];
  public sel_car_rect: any = null;
  public sel_car_dirty = false;

  public restore_zoom_on_refresh = false;

  public polylines_on = false;
  private CSS_COLOR_NAMES = ['red', 'aqua', 'black', 'blue', 'fuchsia', 'gray',
    'green', 'lime', 'maroon', 'navy', 'olive', 'purple', 'silver', 'teal',
    'lightgray', 'yellow'];
  public css_colors: string[] = [];

  // ---------------------------------------------------------------------------
  constructor(
    private cd: ChangeDetectorRef,
    private data_service: DataService,
    private maps_service: HereMapsService,
    private apphelper: AppHelperService,
    public router: Router,
    private _ngZone: NgZone) {

    window['angularComponentRef'] = {
      zone: this._ngZone,
      // updatePersonPositionFn: (value) => this.updatePersonPosition(value),
      updateMapPointPositionFn: (val1, val2) => this.updateMapPointPosition(val1, val2),
      changePersonCarFn: (value) => this.changePersonCar(value),
      changeAllPersonsCarFn: (value) => this.changeAllPersonsCar(value),
      changePersonColourFn: (value) => this.changePersonColour(value),
      changeAllPersonsColourFn: (value) => this.changeAllPersonsColour(value),
      component: this
    };

    this.css_colors = this.apphelper.get_css_colours();
  }

  // ---------------------------------------------------------------------------
  public ngOnInit() {
    this.transfer_points.length = 0;
    this.stop_points.length = 0;

    if (sessionStorage.getItem('filter_date'))
      this.filter_date = JSON.parse(sessionStorage.getItem('filter_date'));
    else
      this.filter_date = this.apphelper.get_filter_date_from_date(new Date());

    if (sessionStorage.getItem('filter_wyjpow'))
      this.filter_wyjpow = Number(sessionStorage.getItem('filter_wyjpow'));

    if (sessionStorage.getItem('filter_sam'))
      this.filter_sam = Number(sessionStorage.getItem('filter_sam'));

    if (sessionStorage.getItem('filter_color'))
      this.filter_color = sessionStorage.getItem('filter_color');

    let arr_cars: any[] = [];
    const o1 = this.data_service.getCarsAll$();
    const o2 = this.data_service.getPunktPrzesiadkiAll$();
    const o3 = this.data_service.getPunktZbiorkiAll$();

    forkJoin([o1, o2, o3]).subscribe(
      (data: any) => {
        arr_cars = data[0].map((obj: any) => Car.createObject(obj));
        this.transfer_points = data[1].map((obj: any) => TransferPoint.createObject(obj));
        this.stop_points = data[2].map((obj: any) => StopPoint.createObject(obj));
      },
      error => {
        this.apphelper.error_handler(error, 'MasterComponent::ngOnInit');
      },
      () => {
        for (const o of arr_cars)
          this.map_cars[o.id] = o;
      }
    );
  }

  // ---------------------------------------------------------------------------
  public ngAfterViewInit() {
    this.the_map = this.maps_service.createHereMap();
    this.the_map.addEventListener('tap', (evt: any) => {
      const target = evt.target;
      const pointer = evt.currentPointer;
      if (target instanceof H.Map) {
        const pos = this.the_map.screenToGeo(pointer.viewportX, pointer.viewportY);
        this.setMapPointPosition(pos);
      }
    }, false);
    this.showData();
    this.cd.detectChanges();
  }

  // ---------------------------------------------------------------------------
  public ngOnDestroy() {
    window['angularComponentRef'] = null;
    this.clear_car_persons();
  }

  // ---------------------------------------------------------------------------
  // sets local variable when datepicker value changed
  public onNotify(date): void {
    this.filter_date = date;
    sessionStorage.setItem('filter_date', JSON.stringify(this.filter_date));
  }

  // ---------------------------------------------------------------------------
  public onChangeSam(val): void {
    this.filter_sam = Number(val);
    sessionStorage.setItem('filter_sam', this.filter_sam.toString());
  }

  // ---------------------------------------------------------------------------
  public onChangeWyjPow(val): void {
    this.filter_wyjpow = Number(val);
    sessionStorage.setItem('filter_wyjpow', this.filter_wyjpow.toString());
  }

  // ---------------------------------------------------------------------------
  public onChangeFilterColor(val): void {
    this.filter_color = val;
    sessionStorage.setItem('filter_color', this.filter_color.toString());
  }

  // ---------------------------------------------------------------------------
  public get_filter_date_str(): string {
    return `${this.filter_date.year}-${this.filter_date.month}-${this.filter_date.day}`;
  }

  // ---------------------------------------------------------------------------
  private refresh_totals(): void {
    let tot = 0;
    let tot_ass = 0;
    let tot_not_ass = 0;

    this.mappoints.forEach(mp => {
      mp.persons.forEach(obj => {
        if (obj.is_real_person()) {
          tot++;
          const id_auto = obj.id_auto;
          // tslint:disable-next-line:triple-equals
          if (id_auto != this.id_auto_unknown)
            tot_ass++;
          else
            tot_not_ass++;
        }
      });
    });

    this.total_persons = tot;
    this.total_persons_assigned = tot_ass;
    this.total_persons_not_assigned = tot_not_ass;
  }

  // ---------------------------------------------------------------------------
  private get_default_car_color(id_auto: number): string {
    let ret = 'black';
    if (id_auto != null && Number(id_auto) === 1)
      ret = 'red';
    return ret;
  }

  // ---------------------------------------------------------------------------
  private get_default_car_visible(id_auto: number): boolean {
    // let ret = false;
    // if (id_auto != null && Number(id_auto) === 1)
    //   ret = true;
    // return ret;
    return true;
  }

  // ---------------------------------------------------------------------------
  private refresh_carinfo_list(map_persons: any, map_cartasks: any, map_cars: any): CarInfo[] {
    const arr: CarInfo[] = [];
    const map_ci: any = {};
    const it_persons = Object.getOwnPropertyNames(map_persons);
    for (const it of it_persons) {
      const person: Person = map_persons[it];
      const id_auto = person.id_auto;
      let car_info_obj: CarInfo = map_ci[id_auto];
      if (!car_info_obj) {
        const car_obj = map_cars[id_auto];
        if (car_obj) {
          const color = this.get_default_car_color(id_auto);
          const visible = this.get_default_car_visible(id_auto);
          car_info_obj = new CarInfo(car_obj, 0, color, visible);
          arr.push(car_info_obj);
          map_ci[car_obj.id] = car_info_obj;
        }
        else {
          console.log(`Unknown car id: ${id_auto} (samtyp: ${this.filter_sam}) ` +
            `in person: ${person.nazwisko} ${person.imie} (id: ${person.id})`);
        }
      }
      if (car_info_obj) {
        if (person.is_real_person())
          car_info_obj.count++;
        if (!person.has_gps_data())
          car_info_obj.no_gps_count++;
      }
    }

    const it_cartasks = Object.getOwnPropertyNames(map_cartasks);
    for (const it of it_cartasks) {
      const cartask: CarTask = map_cartasks[it];
      const id_auto = cartask.id_samochod;
      let car_info_obj: CarInfo = map_ci[id_auto];
      if (!car_info_obj) {
        const car_obj = map_cars[id_auto];
        if (car_obj) {
          const color = this.get_default_car_color(id_auto);
          const visible = this.get_default_car_visible(id_auto);
          car_info_obj = new CarInfo(car_obj, 0, color, visible);
          arr.push(car_info_obj);
          map_ci[car_obj.id] = car_info_obj;
        }
        else {
          console.log(`Unknown car id: ${id_auto} (samtyp: ${this.filter_sam}) ` +
            `in cartask: ${cartask.zadanie_info} (id: ${cartask.id})`);
        }
      }
      if (car_info_obj) {
        if (!cartask.has_gps_data())
          car_info_obj.no_gps_count++;
      }
    }
    // sort by car name
    arr.sort((ci1, ci2) => ci1.car.name.localeCompare(ci2.car.name));
    this.map_car_info = map_ci;
    return arr;
  }

  // ---------------------------------------------------------------------------
  public refresh_carinfo_objects(mp: MapPoint): void {
    if (!mp) return;
    for (const p of mp.persons) {
      const id_auto = p.id_auto;
      for (const ci of this.car_info) {
        if (ci.car.id === id_auto) {
          ci.no_gps_count--;
        }
      }
    }
    for (const ct of mp.cartasks) {
      for (const ci of this.car_info) {
        if (ci.car.id === ct.id_samochod) {
          ci.no_gps_count--;
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  public refresh_gps_errors(map_persons: any, map_cartasks: any, map_cars: any): any[] {
    const ret: any[] = [];
    const brak_adresu = '[brak adresu]';
    const it_persons = Object.getOwnPropertyNames(map_persons);
    for (const it of it_persons) {
      const p: Person = map_persons[it];
      if (p && !p.has_gps_data()) {
        let s_addr = this.apphelper.get_person_address(p, this.filter_sam);
        if (s_addr.length === 0)
          s_addr = brak_adresu;
        const id_auto = p.id_auto;
        const s_auto = map_cars[id_auto] ? map_cars[id_auto].name : '???';
        const ob = { name: '', addr: '', car: '' };
        ob.name = `${p.nazwisko} ${p.imie}`;
        ob.addr = s_addr;
        ob.car = s_auto;
        ret.push(ob);
      }
    }
    const it_cartasks = Object.getOwnPropertyNames(map_cartasks);
    for (const it of it_cartasks) {
      const ct: CarTask = map_cartasks[it];
      if (ct && !ct.has_gps_data()) {
        let s_addr = ct.address;
        if (s_addr.length === 0)
          s_addr = brak_adresu;
        const s_auto = map_cars[ct.id_samochod] ? map_cars[ct.id_samochod].name : '???';
        const ob = { name: '', addr: '', car: '' };
        ob.name = ct.zadanie_info;
        ob.addr = s_addr;
        ob.car = s_auto;
        ret.push(ob);
      }
    }
    ret.sort((a, b) => a.car.localeCompare(b.car));
    return ret;
  }

  // ---------------------------------------------------------------------------
  private clear_map(): void {
    this.maps_service.clearHereMap(this.the_map);
  }

  // ---------------------------------------------------------------------------
  private get_persons$(_samtyp = null, _id_auto = null): Observable<any> {
    this.filter_wyjpow = Number(this.filter_wyjpow);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    const _kolor = this.filter_color !== 'all' ? this.filter_color : '';
    return this.data_service.getPersons$(_date, _powrot, _porawyj, _kolor, _samtyp, _id_auto);
  }

  // ---------------------------------------------------------------------------
  private get_cartasks$(): Observable<any> {
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_sam = Number(this.filter_sam);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    const _samtyp = this.filter_sam;
    return this.data_service.getCarTasks$(_date, _powrot, _porawyj, _samtyp);
  }

  // ---------------------------------------------------------------------------
  private save_persons(data: any, map_persons: any, samtyp: number) {
    for (const o of data) {
      const obj = Person.createObject(o, samtyp);
      map_persons[obj.id] = obj;
    }
  }

  // ---------------------------------------------------------------------------
  private save_cartasks(data: any, map_cartasks: any) {
    for (const o of data) {
      const obj = CarTask.createObject(o);
      map_cartasks[obj.id] = obj;
    }
  }

  // ---------------------------------------------------------------------------
  private get_geocoding_data$(map_persons: any, map_cartasks: any, persons_nogps: number[],
    cartasks_nogps: number[]): Observable<any[]> {
    const observ_objs: Observable<any>[] = [];
    for (let i = 0; i < persons_nogps.length; i++) {
      const p = map_persons[persons_nogps[i]];
      if (p) {
        let addr = new Address(this.apphelper);
        addr.from_person(p, this.filter_sam);
        const o = this.maps_service.geocodeObservable$(addr);
        if (o)
          observ_objs.push(o);
      }
    }
    for (let i = 0; i < cartasks_nogps.length; i++) {
      const ct = map_cartasks[cartasks_nogps[i]];
      if (ct) {
        let addr = new Address(this.apphelper);
        addr.from_cartask(ct);
        const o = this.maps_service.geocodeObservable$(addr);
        if (o)
          observ_objs.push(o);
      }
    }
    if (observ_objs.length !== (persons_nogps.length + cartasks_nogps.length))
      console.log('MasterComponent.getGeocodingData$: some observables were not created.');
    return forkJoin(observ_objs);
  }

  // ---------------------------------------------------------------------------
  private save_geocoding_data(data: any[], map_persons: any, map_cartasks: any,
    persons_nogps: number[], cartasks_nogps: number[],
    persons_updatedb: number[], cartasks_updatedb: number[]): void {
    for (let i = 0; i < data.length; i++) {
      const pos = this.maps_service.geocodeResult(data[i]);
      if (i < persons_nogps.length) {
        const p = map_persons[persons_nogps[i]];
        if (pos && p && this.apphelper.postal_code_matches(p, pos)) {
          p.gps_lat = pos.Latitude;
          p.gps_lon = pos.Longitude;
          p.rejon = pos.State;
          p.kraj = pos.Country;
          persons_updatedb.push(p.id);
        }
      }
      else {
        const j = i - persons_nogps.length;
        const ct = map_cartasks[cartasks_nogps[j]];
        if (pos && ct) {
          ct.gps_lat = pos.Latitude;
          ct.gps_lon = pos.Longitude;
          ct.rejon = pos.State;
          ct.kraj = pos.Country;
          cartasks_updatedb.push(ct.id);
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  private refresh_map_objects(_map_points: MapPoint[], _map_cars: any, _filter_sam: number): void {
    const _map_zoom = this.the_map.getZoom();
    const _map_center = this.the_map.getCenter();
    this.clear_map();
    const marker_objs: any[] = this.apphelper.prepare_map_markers(this.maps_service, _map_points, _map_cars, _filter_sam, 1);
    if (marker_objs && marker_objs.length > 0) {
      const marker_group: any = this.maps_service.marker_group_create(marker_objs);
      if (marker_group) {
        this.the_map.addObject(marker_group);
        if (this.restore_zoom_on_refresh) {
          this.the_map.setZoom(_map_zoom);
          this.the_map.setCenter(_map_center, false);
        }
        else {
          this.the_map.getViewModel().setLookAtData({ bounds: marker_group.getBoundingBox() });
          this.restore_zoom_on_refresh = true;
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  private refresh_error_info(errorlog: string[]): string[] {
    for (let i = 0; i < errorlog.length; i++) {
      if (i === 0)
        console.log('MasterComponent.refresh_error_info');
      console.log(errorlog[i]);
    }
    return errorlog;
  }

  // ---------------------------------------------------------------------------
  public showData(): void {
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_sam = Number(this.filter_sam);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _samtyp = this.filter_sam;

    this.mappoints = [];
    this.map_car_info = {};
    this.clear_car_persons();

    const map_persons: any = {}; // std::map kind: map_persons[person.id] = person_object
    const map_cartasks: any = {}; // std::map kind: map_cartasks[cartask.id] = cartask_object
    const persons_nogps: number[] = []; // array of Person.id without GPS data
    const persons_updatedb: number[] = []; // array of Person.id that should update GPS to database
    const cartasks_nogps: number[] = []; // array of CarTask.id without GPS data
    const cartasks_updatedb: number[] = []; // array of CarTask.id that should update GPS to database
    const errorlog: string[] = [];

    const o1 = this.get_persons$();
    const o2 = this.get_cartasks$();
    forkJoin([o1, o2]).pipe(
      mergeMap(data => {
        this.save_persons(data[0], map_persons, _samtyp);
        this.save_cartasks(data[1], map_cartasks);
        this.apphelper.prepare_persons_for_geocoding(map_persons, this.stop_points, _powrot, _samtyp,
          persons_nogps, persons_updatedb, errorlog);
        this.apphelper.prepare_cartasks_for_geocoding(map_cartasks, this.stop_points, _powrot, _samtyp,
          cartasks_nogps, cartasks_updatedb, errorlog);
        return this.get_geocoding_data$(map_persons, map_cartasks, persons_nogps, cartasks_nogps);
      }))
      .subscribe(
        data => {
          this.save_geocoding_data(data, map_persons, map_cartasks, persons_nogps, cartasks_nogps, persons_updatedb, cartasks_updatedb);
        },
        error => {
          this.apphelper.error_handler(error, 'MasterComponent::showData');
        },
        () => {
          this.mappoints = this.apphelper.prepare_mappoints(map_persons, map_cartasks, _samtyp, _powrot == 1, errorlog, false);
          this.car_info = this.refresh_carinfo_list(map_persons, map_cartasks, this.map_cars); // updates this.map_car_info inside
          this.gps_errors = this.refresh_gps_errors(map_persons, map_cartasks, this.map_cars);
          this.refresh_map_objects(this.mappoints, this.map_cars, this.filter_sam);
          this.refresh_totals();
          this.apphelper.update_gps_and_address_state_to_db(this.data_service, _samtyp, map_persons, map_cartasks, persons_updatedb, cartasks_updatedb);
          this.app_errors = this.refresh_error_info(errorlog);
          if (this.polylines_on)
            this.showPolylines();
        }
      );
  }

  // ---------------------------------------------------------------------------
  public updateMapPointPosition(arr_id_person: number[], arr_id_cartask: number[]): void {
    const observ_objs: Observable<any>[] = [];
    for (let i = 0; i < arr_id_person.length; i++) {
      const p_id = arr_id_person[i];
      const gps = this.apphelper.find_position_of_person(this.the_map, p_id);
      const p: Person = this.apphelper.get_person_object(this.mappoints, p_id);
      if (p && gps) {
        p.gps_lat = gps[0];
        p.gps_lon = gps[1];
        const o = this.data_service.updatePerson$(p, this.filter_sam);
        if (o)
          observ_objs.push(o);
        else
          console.log('MasterComponent.updateMapPointPosition: null observable got from this.data_service.updatePerson of id', p.id);
      }
    }
    for (let i = 0; i < arr_id_cartask.length; i++) {
      const ct_id = arr_id_cartask[i];
      const gps = this.apphelper.find_position_of_cartask(this.the_map, ct_id);
      const ct: CarTask = this.apphelper.get_cartask_object(this.mappoints, null, null, ct_id);
      if (ct && gps) {
        ct.gps_lat = gps[0];
        ct.gps_lon = gps[1];
        const o = this.data_service.updateCarTask$(ct);
        if (o)
          observ_objs.push(o);
        else
          console.log('MasterComponent.updateMapPointPosition: null observable got from this.data_service.updateCarTask of id', ct.id);
      }
    }
    forkJoin(observ_objs).subscribe(
      data => { },
      error => {
        this.apphelper.error_handler(error, 'MasterComponent.updateMapPointPosition');
      },
      () => {
        const mp = this.apphelper.get_mappoint_object(this.mappoints, arr_id_person, arr_id_cartask);
        if (mp)
          mp.update_all();
      }
    );
  }

  // ---------------------------------------------------------------------------
  public changePersonCar(id_person: number) {
    const p: Person = this.apphelper.get_person_object(this.mappoints, id_person);
    if (p) {
      this.selected_person_name = `${p.nazwisko} ${p.imie}`;
      const id_auto = p.id_auto;
      const car_obj = this.map_cars[id_auto];
      this.selected_person_car = car_obj ? car_obj.name : '???';
      this.last_sel_car_id = this.apphelper.getLastSelectedCarId();
      this.modal_chg_car.open_promise().then(
        (submit) => {
          if (submit === 'save-changes') {
            p.id_auto = Number(this.modal_chg_car.selected_car_id);
            this.apphelper.setLastSelectedCarId(p.id_auto);
            this.data_service.updatePerson$(p, this.filter_sam).subscribe(
              data => () => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changePersonCar');
              },
              () => { this.showData(); }
            );
          }
        },
        (cancel) => { /* console.log('cancelled with ', cancel); */ }
      );
    }
  }

  // ---------------------------------------------------------------------------
  public changePersonColour(id_person: number) {
    const p: Person = this.apphelper.get_person_object(this.mappoints, id_person);
    if (p) {
      this.selected_person_name = `${p.nazwisko} ${p.imie}`;
      let sel_color = p.pin_kolor && p.pin_kolor.length > 0 ? p.pin_kolor : 'blue';
      this.modal_chg_colour.selected_colour = sel_color;
      this.modal_chg_colour.open_promise().then(
        (submit) => {
          if (submit === 'save-changes') {
            console.log('color changed to ', this.modal_chg_colour.selected_colour);
            const post_data = { PIN_KOLOR: this.modal_chg_colour.selected_colour };
            this.data_service.updatePersonData$(p, post_data).subscribe(
              data => () => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changePersonCarColour');
              },
              () => { this.showData(); }
            );
          }
        },
        (cancel) => { /* console.log('cancelled with ', cancel); */ }
      );
    }
  }


  // ---------------------------------------------------------------------------
  public changeAllPersonsCar(arr_id_person: number[]) {
    this.selected_person_name = 'Zaznaczone osoby';
    this.selected_person_car = 'Zaznaczone samochody';
    this.last_sel_car_id = this.apphelper.getLastSelectedCarId();
    this.modal_chg_car.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const selected_id_auto = Number(this.modal_chg_car.selected_car_id);
          this.apphelper.setLastSelectedCarId(selected_id_auto);
          const arr_objs: Observable<any>[] = [];
          for (let i = 0; i < arr_id_person.length; i++) {
            const p: Person = this.apphelper.get_person_object(this.mappoints, arr_id_person[i]);
            if (p) {
              p.id_auto = selected_id_auto;
              const o = this.data_service.updatePerson$(p, this.filter_sam);
              arr_objs.push(o);
            }
          }
          if (arr_objs.length > 0) {
            forkJoin(arr_objs).subscribe(
              data => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changeAllPersonsCar');
              },
              () => {
                this.showData();
              }
            );
          }
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  public changeAllPersonsColour(arr_id_person: number[]) {
    let sel_color = 'blue';
    this.modal_chg_colour.selected_colour = sel_color;
    this.modal_chg_colour.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const arr_objs: Observable<any>[] = [];
          for (let i = 0; i < arr_id_person.length; i++) {
            const p: Person = this.apphelper.get_person_object(this.mappoints, arr_id_person[i]);
            if (p) {
              const post_data = { PIN_KOLOR: this.modal_chg_colour.selected_colour };
              const o = this.data_service.updatePersonData$(p, post_data);
              arr_objs.push(o);
            }
          }
          if (arr_objs.length > 0) {
            forkJoin(arr_objs).subscribe(
              data => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changeAllPersonsColour');
              },
              () => {
                this.showData();
              }
            );
          }
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  public addCar(): void {
    // const _date = new Date(this.filter_date.year, this.filter_date.month - 1, this.filter_date.day);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_sam = Number(this.filter_sam);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    this.modal_add_car.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const _id_auto = Number(this.modal_add_car.selected_car_id);
          this.data_service.addCarTasks$(_date, _powrot, _porawyj, this.filter_sam, _id_auto).subscribe(
            data => () => { },
            error => {
              this.apphelper.error_handler(error, 'MasterComponent::addCar');
            },
            () => {
              this.showData();
            }
          );
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  public delCar(): void {
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_sam = Number(this.filter_sam);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    this.modal_del_car.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const _id_auto = Number(this.modal_del_car.selected_car_id);

          // usuwam tylko CarTasks, bo warunkiem zadzialania funkcji jest, zeby nie bylo zadnej przypisanej osoby
          // a z zadan moze byc tylko START lub STOP - patrz logika /api/carsempty
          this.data_service.delCarTasks$(_date, _powrot, _porawyj, this.filter_sam, _id_auto).subscribe(
            data => { },
            error => {
              this.apphelper.error_handler(error, 'MasterComponent::delCar');
            },
            () => {
              this.showData();
            }
          );
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  // pos: Object { lat: 50.82744843143973, lng: 17.788191840809304 }
  public setMapPointPosition(pos: any) {
    const dlg = this.modal_map_pt_pos;
    dlg.mappoints.length = 0;
    for (let i = 0; i < this.mappoints.length; i++) {
      const mpobj = this.mappoints[i];
      if (!mpobj.has_gps_data()) {
        mpobj['idx'] = i;
        dlg.mappoints.push(mpobj);
      }
    }
    const observ_objs: Observable<any>[] = [];
    let mp: MapPoint = null;
    dlg.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          if (dlg.selected_radio === 'mp') {
            if (dlg.selected_mappoint_idx >= 0) {
              mp = this.mappoints[dlg.selected_mappoint_idx];
              if (mp) {
                for (const p of mp.persons) {
                  p.gps_lat = pos.lat;
                  p.gps_lon = pos.lng;
                  observ_objs.push(this.data_service.updatePerson$(p, this.filter_sam));
                }
                for (const ct of mp.cartasks) {
                  ct.gps_lat = pos.lat;
                  ct.gps_lon = pos.lng;
                  observ_objs.push(this.data_service.updateCarTask$(ct));
                }
              }
            }
          }
          forkJoin(observ_objs).subscribe(
            data => () => { },
            error => {
              this.apphelper.error_handler(error, 'MasterComponent::setMapPointPosition');
            },
            () => {
              this.showData();
            }
          );
        }
      },
      (cancel) => { }
    );
  }

  // ---------------------------------------------------------------------------
  // pokazywanie/chowanie samochodow przy pomocy checkboxow
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  public checkAllCars(): void {
    this.common_check_all(true);
  }

  // ---------------------------------------------------------------------------
  public uncheckAllCars(): void {
    this.common_check_all(false);
  }

  // ---------------------------------------------------------------------------
  private common_check_all(checked: boolean) {
    for (let i = 0; i < this.car_info.length; i++)
      this.car_info[i].visible = checked;

    // markery mappointow zawierajacychosoby/zadania z roznych samochodow
    // moga miec zmienione ikony jako efekt pojedynczego filtrowania aut
    // na wszelki wypadek wszystkie takie ikony aktualizuje, zeby uniknac
    // klopotliwego sprawdzania, ktore ikony trzeba zaktualizowac, a ktore nie
    const arr_change_mappoint_icon: MapPoint[] = [];
    for (const mp of this.mappoints) {
      const arr_car_id: number[] = [];
      for (const _p of mp.persons) {
        if (arr_car_id.length > 1)
          break;
        if (arr_car_id.includes(_p.id_auto) === false)
          arr_car_id.push(_p.id_auto);
      }
      for (const _t of mp.cartasks) {
        if (arr_car_id.length > 1)
          break;
        if (arr_car_id.includes(_t.id_samochod) === false)
          arr_car_id.push(_t.id_samochod);
      }

      if (arr_car_id.length > 1)
        arr_change_mappoint_icon.push(mp);
    }

    // zmiana ikon z iloscia osob dla markerow, w ktorych wystepuja osoby z roznych aut
    // po schowaniu/pokazaniu auta liczba osob na ikonie powinna sie zaktualizowac
    for (const mp of arr_change_mappoint_icon) {
      // find corresponding map_marker
      const mp_persons_id = mp.persons.map(personobj => personobj.id);
      const mp_cartasks_id = mp.cartasks.map(cartaskobj => cartaskobj.id);
      let arr_mapobj = this.find_map_objects(this.the_map, mp_persons_id, mp_cartasks_id);

      for (const mapobj of arr_mapobj) {
        if (mapobj instanceof H.map.Marker) {
          // create new icon using temp mappoint, and replace it in map marker
          const svg_icon_markup = this.apphelper.create_mappoint_icon_svg(mp, 1);
          if (svg_icon_markup.length > 0) {
            const icon = new H.map.Icon(svg_icon_markup);
            mapobj.setIcon(icon);
          }
        }
      }
    }

    const mapobjs = this.get_all_map_marker_objects(this.the_map);
    if (mapobjs && mapobjs.length > 0)
      for (let i = 0; i < mapobjs.length; i++)
        mapobjs[i].setVisibility(checked);

    const mapobjs2 = this.get_all_map_polyline_objects(this.the_map);
    if (mapobjs2 && mapobjs2.length > 0)
      for (let i = 0; i < mapobjs2.length; i++)
        mapobjs2[i].setVisibility(checked);
  }

  // ---------------------------------------------------------------------------
  public onFilterCar(id_auto: any): void {
    const carinfo_obj = this.map_car_info[id_auto];
    if (carinfo_obj) {
      this.show_car_mappoints(this.mappoints, carinfo_obj.car.id, carinfo_obj.visible);
      if (carinfo_obj.polyline_id != -1)
        this.show_car_polyline(carinfo_obj.polyline_id, carinfo_obj.visible);
    }
    else {
      console.log('onFilter car_info not found');
    }
  }

  // ---------------------------------------------------------------------------
  /**
   * Zwraca tablice wszystkich obiektów H.map.Marker znajdujących się na mapie.
   * @param mapobj obiekt mapy (H.Map)
   */
  public get_all_map_marker_objects(mapobj: any /* H.Map */): any[] /* H.map.Marker */ | null {
    if (mapobj === undefined || mapobj === null)
      return null;
    if (!(mapobj instanceof H.Map))
      return null;
    const ret: any[] = [];
    const objs = mapobj.getObjects(true);
    if (objs) {
      for (let i = 0; i < objs.length; i++) {
        if (objs[i] instanceof H.map.Marker)
          ret.push(objs[i]);
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Zwraca tablice wszystkich obiektów H.map.Polyline znajdujących się na mapie.
   * @param mapobj obiekt mapy (H.Map)
   */
  public get_all_map_polyline_objects(mapobj: any /* H.Map */): any[] /* H.map.Marker */ | null {
    if (mapobj === undefined || mapobj === null)
      return null;
    if (!(mapobj instanceof H.Map))
      return null;
    const ret: any[] = [];
    const objs = mapobj.getObjects(true);
    if (objs) {
      for (let i = 0; i < objs.length; i++) {
        if (objs[i] instanceof H.map.Polyline)
          ret.push(objs[i]);
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Zwraca tablice obiektów H.map.Marker pokazujących na mapie osob i zadan z identyfikatorami
   * podanymi w tabeli.
   * @param mapobj obiekt mapy (H.Map)
   * @param _arr_id_person tablica z identyfikatorami osób z bazy danych
   * @param _arr_id_cartask tablica z identyfikatorami zadan z bazy danych
   */
  public find_map_objects(mapobj: any /* H.Map */, _arr_id_person: number[], _arr_id_cartask: number[]): any[] /* H.map.Marker */ | null {
    const ret: any[] = [];
    const objs = this.get_all_map_marker_objects(mapobj);
    if (!objs)
      return ret;

    for (const o of objs) {
      let found = false;
      if (_arr_id_person) {
        for (const id_person of _arr_id_person) {
          if (this.apphelper.array_has_value(o.id_persons, id_person)) {
            found = true;
            break;
          }
        }
      }
      if (_arr_id_cartask && !found) {
        for (const id_cartask of _arr_id_cartask) {
          if (this.apphelper.array_has_value(o.id_cartasks, id_cartask)) {
            found = true;
            break;
          }
        }
      }
      if (found)
        ret.push(o);
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  private show_car_mappoints(_map_points: MapPoint[], id_auto: number, show: boolean) {
    const arr_id_person: number[] = [];
    const arr_id_cartask: number[] = [];
    const arr_change_mappoint_icon: MapPoint[] = [];

    const arr_filtered_car_id: number[] = [];
    for (const _ci of this.car_info) {
      if (!_ci.visible)
        arr_filtered_car_id.push(_ci.car.id);
    }

    if (show === false && arr_filtered_car_id.includes(id_auto) === false)
      arr_filtered_car_id.push(id_auto);

    for (const mp of _map_points) {
      let person_in_other_car = undefined;
      let cartask_in_other_car = undefined;

      // pokazywanie pinezki - jesli ma chociaz jedna osobe z danego auta, pokaz pinezke
      // chowanie pinezki = jesli nie zadnej osoby z innego auta, to mozesz schowac
      if (mp.persons.length > 0) {
        const person_in_this_car = mp.persons.find(obj => obj.id_auto == id_auto);
        if (person_in_this_car !== undefined) {
          person_in_other_car = mp.persons.find(obj => arr_filtered_car_id.includes(obj.id_auto) === false);
          if (show == true) {
            arr_id_person.push(Number(person_in_this_car.id));
          }
          else { // show = false
            if (person_in_other_car === undefined)
              arr_id_person.push(Number(person_in_this_car.id));
          }
        }
      }

      // pokazywanie pinezki/flagi - jesli ma chociaz jedno zadanie z danego auta, pokaz pinezke
      // chowanie pinezki/flagi = jesli nie zadnego zadania z innego auta, to mozesz schowac
      if (mp.cartasks.length > 0) {
        const cartask_in_this_car = mp.cartasks.find(obj => obj.id_samochod == id_auto && obj.odb_doc === this.filter_sam);
        if (cartask_in_this_car !== undefined) {
          cartask_in_other_car = mp.cartasks.find(obj => obj.odb_doc === this.filter_sam && arr_filtered_car_id.includes(obj.id_samochod) === false);
          if (show == true) {
            arr_id_cartask.push(Number(cartask_in_this_car.id));
          }
          else { // show == false
            if (cartask_in_other_car === undefined)
              arr_id_cartask.push(Number(cartask_in_this_car.id));
          }
        }
      }

      if (person_in_other_car !== undefined || cartask_in_other_car !== undefined)
        arr_change_mappoint_icon.push(mp);
    }

    // zmiana ikon z iloscia osob dla markerow, w ktorych wystepuja osoby z roznych aut
    // po schowaniu/pokazaniu auta liczba osob na ikonie powinna sie zaktualizowac
    for (const mp of arr_change_mappoint_icon) {
      // find corresponding map_marker
      const mp_persons_id = mp.persons.map(personobj => personobj.id);
      const mp_cartasks_id = mp.cartasks.map(cartaskobj => cartaskobj.id);
      let arr_mapobj = this.find_map_objects(this.the_map, mp_persons_id, mp_cartasks_id);

      for (const mapobj of arr_mapobj) {
        if (mapobj instanceof H.map.Marker) {
          // create clone of mappoint with or without filtered car
          // we do not alter this.mappoints, since it holds all nonfiltered data
          const _temp = mp.clone();
          _temp.persons.length = 0;
          for (const _p of mp.persons) {
            if (arr_filtered_car_id.includes(_p.id_auto) === false)
              _temp.persons.push(_p);
          }
          _temp.cartasks.length = 0;
          for (const _t of mp.cartasks) {
            if (_t.odb_doc === this.filter_sam && arr_filtered_car_id.includes(_t.id_samochod) === false)
              _temp.cartasks.push(_t);
          }
          _temp.update_all();

          // create new icon using temp mappoint, and replace it in map marker
          const svg_icon_markup = this.apphelper.create_mappoint_icon_svg(_temp, 1);
          if (svg_icon_markup.length > 0) {
            const icon = new H.map.Icon(svg_icon_markup);
            mapobj.setIcon(icon);
          }
        }
      }
    }

    // wreszcie, pokaz lub ukryj ikony na mapie
    const arr = this.find_map_objects(this.the_map, arr_id_person, arr_id_cartask);
    if (arr && arr.length > 0) {
      for (const mapobj of arr)
        mapobj.setVisibility(show);
    }
  }

  // ---------------------------------------------------------------------------
  // okno do edycji osob z wybranego samochodu
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  private clear_car_persons() {
    this.hideMapSelection();
    this.sel_car = null;
    this.sel_car_persons = [];
    this.sel_car_persons_org = [];
    this.carPersonsEnabled = false;
    this.sel_car_dirty = false;
  }

  // ---------------------------------------------------------------------------
  private refresh_car_persons(car_id: number) {
    this.sel_car_persons = [];
    this.sel_car_persons_org = [];
    this.sel_car_dirty = false;
    const _samtyp = this.filter_sam;
    this.get_persons$(_samtyp, car_id)
      .subscribe(
        data => { this.sel_car_persons = data.map(obj => Person.createObject(obj, _samtyp)) },
        error => { this.apphelper.error_handler(error, 'MasterComponent::refresh_car_persons'); },
        () => {
          this.sel_car_persons_org = this.sel_car_persons.slice();
        }
      );
  }

  // ---------------------------------------------------------------------------
  public removeCarPersonsItem(person_id: number) {
    const p_idx = this.sel_car_persons.findIndex(
      (p: Person) => { return p.id == person_id; });
    if (p_idx != -1) {
      this.sel_car_persons.splice(p_idx, 1);
      this.sel_car_dirty = true;
    }
  }

  // ---------------------------------------------------------------------------
  public addCarPersonsItem(person_id: number) {
    const _p_exists = this.sel_car_persons.find(
      (p: Person) => { return p.id == person_id; });
    if (_p_exists === undefined) {
      const p: Person = this.apphelper.get_person_object(this.mappoints, person_id);
      if (p) {
        this.sel_car_persons.push(p);
        this.sel_car_dirty = true;
      }
      else
        console.log('unknown person id: ', person_id);
    }
  }

  // ---------------------------------------------------------------------------
  public showCarPersons(car_id: number) {
    this.carPersonsEnabled = true;
    const car_obj = this.map_cars[car_id];
    this.sel_car = car_obj ? car_obj : null;
    this.refresh_car_persons(car_id);
  }

  // ---------------------------------------------------------------------------
  @HostListener('window:here_left_click', ['$event'])
  public onHereMapLeftClick(event: any) {
    // console.log('received customevent', event);
    if (!this.carPersonsEnabled)
      return false;

    const target = event.detail.target;
    if (target) {
      for (const id of target.id_persons)
        this.addCarPersonsItem(id);
      // for (const id of target.id_cartasks)
      //   console.log('adding cartask', id);
    }
    return false;
  }

  // ---------------------------------------------------------------------------
  public removeCarPersonsAll() {
    this.sel_car_persons = [];
    this.sel_car_dirty = true;
  }

  // ---------------------------------------------------------------------------
  public cancelCarPersons() {
    this.clear_car_persons();
  }

  // ---------------------------------------------------------------------------
  public showMapSelection() {
    this.hideMapSelection();
    this.sel_car_rect = this.maps_service.create_selection_rect(this.the_map);
    this.maps_service.cur_select_rect = this.sel_car_rect;
  }

  // ---------------------------------------------------------------------------
  public hideMapSelection() {
    if (this.sel_car_rect) {
      this.maps_service.remove_selection_rect(this.the_map, this.sel_car_rect);
      this.sel_car_rect = null;
      this.maps_service.cur_select_rect = null;
    }
  }

  // ---------------------------------------------------------------------------
  public addCarPersonsFromSelection() {
    if (!this.sel_car_rect)
      return;

    const box = this.sel_car_rect.getBoundingBox();
    this.mappoints.forEach(mp => {
      const mp_persons_id = mp.persons.map(personobj => personobj.id);
      const mp_cartasks_id = mp.cartasks.map(cartaskobj => cartaskobj.id);
      let arr_mapobj = this.find_map_objects(this.the_map, mp_persons_id, mp_cartasks_id);
      let mapobj = null;
      if (arr_mapobj.length > 0)
        mapobj = arr_mapobj[0];

      if (mapobj && mapobj.getVisibility() == true) {
        if (box.containsLatLng(mp.gps_lat, mp.gps_lon)) {
          mp.persons.forEach(obj => this.addCarPersonsItem(obj.id));
        }
      }
    });
  }

  // ---------------------------------------------------------------------------
  public changePersonsColorFromSelection() {
    if (!this.sel_car_rect)
      return;

    let sel_color = 'blue';
    this.modal_chg_colour.selected_colour = sel_color;
    this.modal_chg_colour.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const arr_objs: Observable<any>[] = [];
          if (this.sel_car_rect) {
            const box = this.sel_car_rect.getBoundingBox();
            this.mappoints.forEach(mp => {
              const mp_persons_id = mp.persons.map(personobj => personobj.id);
              const mp_cartasks_id = []; // mp.cartasks.map(cartaskobj => cartaskobj.id);
              let arr_mapobj = this.find_map_objects(this.the_map, mp_persons_id, mp_cartasks_id);
              let mapobj = null;
              if (arr_mapobj.length > 0)
                mapobj = arr_mapobj[0];

              if (mapobj && mapobj.getVisibility() == true) {
                if (box.containsLatLng(mp.gps_lat, mp.gps_lon)) {
                  mp.persons.forEach(obj => {
                    const post_data = { PIN_KOLOR: this.modal_chg_colour.selected_colour };
                    const o = this.data_service.updatePersonData$(obj, post_data);
                    arr_objs.push(o);
                  });
                }
              }
            });
          }
          if (arr_objs.length > 0) {
            forkJoin(arr_objs).subscribe(
              data => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changeAllPersonsColour');
              },
              () => {
                this.showData();
              }
            );
          }
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }


  // ---------------------------------------------------------------------------
  public saveCarPersons() {
    const _id_auto = this.sel_car.id;
    const _samtyp = Number(this.filter_sam);
    const update_objs: Observable<any>[] = [];

    // jesli osoba jest w liscie sel_car_persons_org, to byla poczatkowo w aucie
    // jesli teraz jej nie ma w liscie sel_car_persons, to zostala usunieta z auta
    for (let p of this.sel_car_persons_org) {
      if (!this.sel_car_persons.find((obj) => { return obj.id == p.id; })) {
        p.id_auto = this.id_auto_unknown;
        const o = this.data_service.updatePerson$(p, _samtyp);
        update_objs.push(o);
      }
    }
    // osoby, ktore sa w liscie sel_car_persons, trzeba dodac do auta
    // chyba ze juz sa do niego przypisane
    let idx = 1;
    for (let p of this.sel_car_persons) {
      if (p.id_auto != _id_auto)
        p.id_auto = _id_auto;
      p.lp_rozp = idx++;
      const o = this.data_service.updatePerson$(p, _samtyp);
      update_objs.push(o);
    }
    // zapisz zmiany w bazie danych
    forkJoin(update_objs).subscribe(
      data => { },
      error => {
        this.apphelper.error_handler(error, 'MasterComponent.saveCarPersons');
      },
      () => {
        this.showData();
      }
    );
  }

  // pokazywanie kolorowych polaczen miedzy osobami w tym samym aucie

  // ---------------------------------------------------------------------------
  public showPolylines() {
    this.polylines_on = true;
    for (const ci of this.car_info)
      ci.polyline_id = -1;

    // build map od id => geopoints
    let car_points: any = {}; // car_id => [ geopoints sorted by lp rozp ]
    for (const mp of this.mappoints) {
      for (const objp of mp.persons) {
        let car_points_item = car_points[objp.id_auto];
        if (car_points_item) {
          car_points_item.push({ lat: mp.gps_lat, lng: mp.gps_lon, pos: objp.lp_rozp });
        }
        else {
          car_points[objp.id_auto] = [{ lat: mp.gps_lat, lng: mp.gps_lon, pos: objp.lp_rozp }];
        }
      }
    }

    // sort points
    const car_points_props = Object.getOwnPropertyNames(car_points);
    for (const prop of car_points_props) {
      let arr_sorted = car_points[prop].sort((l, r) => l.pos - r.pos);
      car_points[prop] = arr_sorted;
    }

    // show polylines
    let idx = 0;
    const colors_count = this.CSS_COLOR_NAMES.length;
    for (const prop of car_points_props) {
      const _color = this.CSS_COLOR_NAMES[idx % colors_count];
      let ci = this.map_car_info[prop];
      if (ci) {
        ci.colour = _color;
        ci.polyline_id = -1;
        if (car_points[prop].length > 1)
          ci.polyline_id = this.maps_service.addPolylineToMap(this.the_map, car_points[prop], _color, 6, ci.visible);
      }
      idx++;
    }
  }

  // ---------------------------------------------------------------------------
  public hidePolylines() {
    this.polylines_on = false;
    for (const ci of this.car_info) {
      this.maps_service.clearPolylineShape(this.the_map, ci.polyline_id);
      ci.polyline_id = -1;
    }
  }

  // ---------------------------------------------------------------------------
  public show_car_polyline(polyline_id: number, show: boolean) {
    const obj = this.maps_service.findPolylineShape(this.the_map, polyline_id);
    if (obj)
      obj.setVisibility(show);
  }

  // ---------------------------------------------------------------------------
  public onChangeColour(car_id: number, color: string) {
    // console.log('onChangeColour, car_id:', car_id, 'event data:', color);
    const ci = this.map_car_info[car_id];
    if (ci && ci.polyline_id != -1) {
      this.maps_service.changePolylineColor(this.the_map, ci.polyline_id, color);
    }
  }

  // ---------------------------------------------------------------------------
  public onSelectColour(car_id: number, color: string) {
    // console.log('onSelectColour, car_id:', car_id, 'event data:', color);
    const ci = this.map_car_info[car_id];
    if (ci && ci.polyline_id != -1) {
      ci.colour = color;
      this.maps_service.changePolylineColor(this.the_map, ci.polyline_id, color);
    }
  }

  // ---------------------------------------------------------------------------
  public onColorPickerOpen(car_id: number, color: string) {
    // console.log('onSelectColour, car_id:', car_id, 'event data:', color);
    const ci = this.map_car_info[car_id];
    if (ci && ci.polyline_id != -1) {
      ci.colour = color;
      this.maps_service.changePolylineWidth(this.the_map, ci.polyline_id, 12);
    }
  }

  // ---------------------------------------------------------------------------
  public onColorPickerClose(car_id: number, color: string) {
    // console.log('onSelectColour, car_id:', car_id, 'event data:', color);
    const ci = this.map_car_info[car_id];
    if (ci && ci.polyline_id != -1) {
      ci.colour = color;
      this.maps_service.changePolylineWidth(this.the_map, ci.polyline_id, 6);
    }
  }

  // -----------------------------------------------------------------------------
  public resetZoom() {
    const arr_objects = this.the_map.getObjects();
    if (arr_objects && arr_objects.length) // powinna byc tylko jedna grupa
      this.the_map.getViewModel().setLookAtData({ bounds: arr_objects[0].getBoundingBox() });
  }
}
