import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-change-colour',
  templateUrl: './modal-change-colour.component.html',
  styleUrls: ['./modal-change-colour.component.css']
})
export class ModalChangeColourComponent implements OnInit {

  closeResult: string;

  @ViewChild('content', { static: true }) content: any;
  @Input() selected_colour = '';
  @Input() preset_colors = [];

  constructor(private modal: NgbModal) { }

  public open_promise(): Promise<any> {
    return this.modal.open(this.content, { size: 'sm' }).result;
  }

  public open(): void {
    this.open_promise().then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
  }

}
