<ng-template #content let-cs="close('save-changes')" let-dc="dismiss('close-window')" let-db="dismiss('close-button')">
  <div class="modal-header">
    <h4 class="modal-title">Zapis pozycji GPS</h4>
    <button type="button" class="close" aria-label="Close" (click)="dc">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="is_editable()">Zapisz wskazaną pozycję GPS dla:</p>
    <p *ngIf="!(is_editable())">Nie można zapisać pozycji - brak punktów do wyboru.</p>
    <form>
      <section *ngIf="mappoints.length > 0">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioMapPoint" value="mp"
            (change)="onRadioChange('mp')" [checked]="selected_mp()">
          <label class="form-check-label" for="radioMapPoint">nie znalezionego adresu</label>
        </div>
        <div class="form-group">
          <select type="text" class="form-control" id="select-mappoint" #t1 (change)="onChangeMapPoint(t1.value)"
            [disabled]="attr_disabled('mp')">
            <option *ngFor="let mp of mappoints" [value]="mp.idx">
              {{mp.addr1}}<span *ngIf="mp.addr2.length > 0">, {{mp.addr2}}</span>
            </option>
          </select>
        </div>
      </section>
      <section *ngIf="cartask_start != null">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioCarStart" value="start"
            (change)="onRadioChange('start')" [checked]="selected_start()">
          <label class="form-check-label" for="radioCarStart">punktu START</label>
        </div>
      </section>
      <section *ngIf="cartask_stop != null">
        <div class="form-check mb-1">
          <input class="form-check-input" type="radio" name="radioPoint" id="radioCarStop" value="stop"
            (change)="onRadioChange('stop')" [checked]="selected_stop()">
          <label class="form-check-label" for="radioCarStop">punktu STOP</label>
        </div>
      </section>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" class="btn btn-primary" (click)="cs" *ngIf="is_editable()">Zapisz zmiany</button>
    </div>
  </div>
</ng-template>