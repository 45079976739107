<div class="d-flex card-cartask">
  <div class="flex-grow-1 text-left">
    <p class="cartask-info">{{model.zadanie_info}}</p>
    <p class="cartask-address" *ngIf="model.address.length>0">{{model.address}}</p>
    <label class="cartask-label">{{get_label()}}</label>
    <input type="number" min="0" max="23" placeholder="G" [ngModel]="model.time_hour"
      (ngModelChange)="onTimeHourChange($event)" [ngClass]="{'error': !is_valid_hour}">
    :
    <input type="number" min="0" max="59" placeholder="M" [ngModel]="model.time_minutes"
      (ngModelChange)="onTimeMinutesChange($event)" [ngClass]="{'error': !is_valid_minutes}">
  </div>
  <div class="d-inline-block">
    <span *ngIf="!model.has_gps_data()" class="badge badge-pill badge-danger">GPS</span>
    <span *ngIf="model.is_start() || model.is_stop()">
      <a [routerLink]="" class="edit-cartask" (click)="edit_cartask(model.id)" title="Ustaw pozycję">
        <img src="/assets/edit-tool-svgrepo-com.svg" height="20px" class="mx-2 my-2">
      </a>
    </span>
  </div>
</div>