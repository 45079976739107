<div class="container-fluid">
  <form>
    <div class="form-group row">
      <label for="colHereApiKey" class="col-4 offset-1 col-form-label">Klucz HERE API</label>
      <div class="col-4">
        <input type="text" required class="form-control" name="colHereApiKey" id="colHereApiKey"
          [(ngModel)]="settings.here_api_key" placeholder="Klucz API otrzymany od firmy HERE">
      </div>
    </div>
    <div class="form-group row">
      <label for="colStopTime" class="col-4 offset-1 col-form-label">Czas postoju dla jednego pasażera</label>
      <div class="col-2">
        <input type="number" required class="form-control" name="colStopTimePerson" id="colStopTimePerson"
          [(ngModel)]="settings.stop_time_person" placeholder="Czas postoju potrzebny do obsługi jednego pasażera">
      </div>
      <div class="col-2">
        minut
      </div>
    </div>
    <div class="form-group row">
      <label for="colStopTime" class="col-4 offset-1 col-form-label">Minimalny czas całego postoju</label>
      <div class="col-2">
        <input type="number" required class="form-control" name="colStopTimeMin" id="colStopTimeMin"
          [(ngModel)]="settings.stop_time_min" placeholder="Minimalny czas postoju">
      </div>
      <div class="col-2">
        minut
      </div>
    </div>
    <div class="form-group row">
      <label for="colRoundTime" class="col-4 offset-1 col-form-label">Zaokrąglaj obliczone czasy w dół do
        najbliższych</label>
      <div class="col-2">
        <input type="number" required class="form-control" name="colRoundTimeMin" id="colRoundTimeMin"
          [(ngModel)]="settings.round_time_to_min" placeholder="Zaokrąglenie obliczonego czasu">
      </div>
      <div class="col-2">
        minut
      </div>
    </div>
    <div class="form-group row mt-4">
      <div class="col-7 offset-1 text-center">
        <button type="submit" class="btn btn-primary" (click)="saveChanges()">Zapisz zmiany</button>
      </div>
    </div>
  </form>
</div>