import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Person } from '../person';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';
import { CarTask } from '../car-task';

@Injectable()
export class DataService {

  constructor(private http: HttpClient) { }

  // --------------------------------------------------------------------------
  getServerUrl(): string {
    return ''; // 'http://gtvmap-api.local';
  }

  // --------------------------------------------------------------------------
  getDateString(_date: Date): string {
    let ret = '';
    if (_date) {
      let day = '' + _date.getDate();
      let mon = '' + (_date.getMonth() + 1);
      const year = '' + _date.getFullYear();
      if (mon.length < 2) { mon = '0' + mon; }
      if (day.length < 2) { day = '0' + day; }
      ret = [year, mon, day].join('-');
    }
    return ret;
  }

  // --------------------------------------------------------------------------
  public getCarsAll$() {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/cars';
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getCars$(_date: Date, _powrot: number, _porawyj: number, _samtyp: number) {
    const querysrv = this.getServerUrl();
    const queryurl = `/api/cars?data=${this.getDateString(_date)}&powrot=${_powrot}&porawyj=${_porawyj}&samtyp=${_samtyp}`;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getCarsNotUsed$(_date: Date, _powrot: number, _porawyj: number, _samtyp: number) {
    const querysrv = this.getServerUrl();
    const queryurl = `/api/carsnotused?data=${this.getDateString(_date)}&powrot=${_powrot}&porawyj=${_porawyj}&samtyp=${_samtyp}`;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getCarsEmpty$(_date: Date, _powrot: number, _porawyj: number, _samtyp: number) {
    const querysrv = this.getServerUrl();
    const queryurl = `/api/carsempty?data=${this.getDateString(_date)}&powrot=${_powrot}&porawyj=${_porawyj}&samtyp=${_samtyp}`;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getPersons$(_date: Date, _powrot: number, _porawyj: number, _kolor: string, _samtyp?: number, _id_auto?: number) {
    const querysrv = this.getServerUrl();
    let queryurl = `/api/persons?data=${this.getDateString(_date)}&powrot=${_powrot}&porawyj=${_porawyj}`;
    if (_kolor.length > 0)
      queryurl += `&kolor=${encodeURIComponent(_kolor)}`
    if (_id_auto != null && _samtyp != null)
      queryurl = queryurl + `&samtyp=${_samtyp}&id_auto=${_id_auto}`;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getCarTasks$(_date: Date, _powrot: number, _porawyj: number, _samtyp: number, _id_auto?: number): Observable<any> {
    const querysrv = this.getServerUrl();
    let queryurl = `/api/cartasks?data=${this.getDateString(_date)}&powrot=${_powrot}&porawyj=${_porawyj}&samtyp=${_samtyp}`;
    if (_id_auto != null)
      queryurl = queryurl + `&id_auto=${_id_auto}`;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getPunktZbiorki$(_name: string) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/stoppoints?nazwa=' + _name;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getPunktZbiorkiAll$() {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/stoppoints';
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getPunktPrzesiadki$(_name: string) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/transferpoints?nazwa=' + _name;
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getPunktPrzesiadkiAll$() {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/transferpoints';
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public getSettingsAll$() {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/settings';
    return this.http.get(querysrv + queryurl);
  }

  // --------------------------------------------------------------------------
  public updatePerson$(_person: Person, _samtyp: number) {
    const _id_auto = _person.id_auto.toString();
    const _lp_rozp = _person.lp_rozp.toString();
    // const _czas_1 = (_person.czas_1 && _person.czas_1.length > 0) ? _person.czas_1 : null;
    const _czas_2 = (_person.czas_2 && _person.czas_2.length > 0) ? _person.czas_2 : null;

    const querysrv = this.getServerUrl();
    const queryurl = '/api/persons';
    const data = {
      'ID': _person.id.toString(),
      'GODZ_SUG': _person.godz_sug
    };
    switch (_samtyp) {
      case 1: // dow
        data['ID_AUTO_DOW'] = _id_auto;
        data['LP_ROZP_DOW'] = _lp_rozp;
        data['GPS_LAT_ODB'] = _person.gps_lat;
        data['GPS_LON_ODB'] = _person.gps_lon;
        // data['CZAS_ODB_1'] = _czas_1;
        data['CZAS_ODB_2'] = _czas_2;
        break;
      case 2: // doc
        data['ID_AUTO_DOC'] = _id_auto;
        data['LP_ROZP_DOC'] = _lp_rozp;
        // data['CZAS_DOC_1'] = _czas_1;
        data['CZAS_DOC_2'] = _czas_2;
        if (_person.powrot === 1) {
          data['GPS_LAT_ODB'] = _person.gps_lat;
          data['GPS_LON_ODB'] = _person.gps_lon;
        } else {
          data['GPS_LAT_DOC'] = _person.gps_lat;
          data['GPS_LON_DOC'] = _person.gps_lon;
        }
        break;
      case 3: // roz
        data['ID_AUTO_ROZ'] = _id_auto;
        data['LP_ROZP_ROZ'] = _lp_rozp;
        data['GPS_LAT_DOC'] = _person.gps_lat;
        data['GPS_LON_DOC'] = _person.gps_lon;
        // data['CZAS_ROZ_1'] = _czas_1;
        data['CZAS_ROZ_2'] = _czas_2;
        break;
    }
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updatePersonData$(_person: Person, _data: any) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/persons';
    const post_data = {
      'ID': _person.id.toString(),
    };

    for (const key in _data) {
      if (Object.prototype.hasOwnProperty.call(_data, key)) {
        const val = _data[key];
        post_data[key] = _data[key];
      }
    }
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, post_data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updatePersonAddressState$(_person: Person, _samtyp: number) {
    const _powrot = _person.powrot == 1 ? 'true' : 'false';

    const querysrv = this.getServerUrl();
    const queryurl = `/api/person_address_state/${_powrot}/${_samtyp}`;
    const data = {
      'id_klientwyjazd': _person.id,
      'state_name': _person.rejon,
      'country_iso_code': _person.kraj,
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updateCarTask$(_cartask: CarTask) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/cartasks';
    const data = {
      'ID': _cartask.id.toString(),
      'GPS_LAT': _cartask.gps_lat,
      'GPS_LON': _cartask.gps_lon,
      'GODZ': _cartask.godz,
      // 'CZAS_1': (_cartask.czas_1 && _cartask.czas_1.length > 0) ? _cartask.czas_1 : null,
      'CZAS_2': (_cartask.czas_2 && _cartask.czas_2.length > 0) ? _cartask.czas_2 : null,
      'LP_ROZP': _cartask.lp_rozp.toString(),
      'ADDRESS': _cartask.address
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updateCarTaskAddressState$(_cartask: CarTask) {
    const querysrv = this.getServerUrl();
    const queryurl = `/api/task_address_state`;
    const data = {
      'id_zadaniesamochod': _cartask.id,
      'state_name': _cartask.rejon,
      'country_iso_code': _cartask.kraj,
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updateStopPoint$(_stoppoint: StopPoint) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/stoppoints';
    const data = {
      'ID': _stoppoint.id.toString(),
      'GPS_LAT': _stoppoint.gps_lat,
      'GPS_LON': _stoppoint.gps_lon
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updateTransferPoint$(_transferpoint: TransferPoint) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/transferpoints';
    const data = {
      'ID': _transferpoint.id.toString(),
      'GPS_LAT': _transferpoint.gps_lat,
      'GPS_LON': _transferpoint.gps_lon
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public updateSettings$(_paramname: string, _paramval: string) {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/settings';
    const data = {
      'PARAMNAME': _paramname,
      'PARAMVALUE': _paramval
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public addCarTaskStart$(date: Date, powrot: number, porawyj: number, samtyp: number, id_auto: number): Observable<any> {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/cartasks';
    const data = {
      'ID_SAMOCHOD': id_auto.toString(),
      'DATA': this.getDateString(date),
      'POWROT': powrot.toString(),
      'PORA_WYJ': porawyj.toString(),
      'ODB_DOC': samtyp.toString(),
      'ID_ZADANIE': '1',
      'ZADANIE_INFO': 'START'
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public addCarTaskStop$(date: Date, powrot: number, porawyj: number, samtyp: number, id_auto: number): Observable<any> {
    const querysrv = this.getServerUrl();
    const queryurl = '/api/cartasks';
    const data = {
      'ID_SAMOCHOD': id_auto.toString(),
      'DATA': this.getDateString(date),
      'POWROT': powrot.toString(),
      'PORA_WYJ': porawyj.toString(),
      'ODB_DOC': samtyp.toString(),
      'ID_ZADANIE': '2',
      'ZADANIE_INFO': 'STOP'
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(querysrv + queryurl, data, httpoptions);
  }

  // --------------------------------------------------------------------------
  public addCarTasks$(date: Date, powrot: number, porawyj: number, samtyp: number, id_auto: number) {
    const o1 = this.addCarTaskStart$(date, powrot, porawyj, samtyp, id_auto);
    const o2 = this.addCarTaskStop$(date, powrot, porawyj, samtyp, id_auto);
    return forkJoin([o1, o2]);
  }

  // --------------------------------------------------------------------------
  public delCarTasks$(date: Date, powrot: number, porawyj: number, samtyp: number, id_auto: number) {
    const querysrv = this.getServerUrl();
    const delobjs: Observable<any>[] = [];
    return this.getCarTasks$(date, powrot, porawyj, samtyp, id_auto).pipe(
      mergeMap(data => {
        for (let i = 0; i < data.length; i++) {
          const obj_id = data[i].ID;
          const queryurl = `/api/cartasks/${obj_id}`;
          const obj = this.http.delete(querysrv + queryurl);
          delobjs.push(obj);
        }
        return forkJoin(delobjs);
      }
      ));
  }

  // --------------------------------------------------------------------------
  public updateRoute$(_date: Date, _powrot: number, _odbdoc: number, _id_auto: number,
    _route_map: string, _route_params: string, _route_distance: number) {
    const _date_str = this.getDateString(_date);
    const querysrv = this.getServerUrl();
    const queryurl = `/api/route/${_date_str}/${_powrot}/${_odbdoc}/${_id_auto}`;
    const data = {
      'route_map': _route_map,
      'route_params': _route_params,
      'route_distance': _route_distance
    };
    const httpoptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(querysrv + queryurl, data, httpoptions);
  }
}
