import { Injectable, ErrorHandler } from '@angular/core';
import { AppHelperService } from './app-helper.service';
import { Address } from '../address';
import { Observable, from, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';

declare var H: any;

@Injectable()
export class HereMapsService {

  public platform: any = null;
  public ui: any = null;
  public behavior: any = null;
  public cur_select_rect = null;
  public default_bus_speed_kph = 130;

  constructor(
    private apphelper: AppHelperService
  ) {
    this.platform = this.getPlatform();
  }

  public ngOnDestroy() {
  }

  /**
   * Zwraca klucz API lub null.service.Platform potrzebny do inicjalizacji HERE API
   */
  protected getHereApiKey(): any {
    let ret: any = null;
    const _sett = this.apphelper.getSettings();
    if (_sett)
      ret = _sett.here_api_key;
    return ret;
  }

  /**
   * Zwraca obiekt H.service.Platform potrzebny do inicjalizacji HERE API
   */
  protected getPlatform(): any {
    let ret: any = null;
    const _api_key = this.getHereApiKey();
    if (_api_key) {
      ret = new H.service.Platform({
        apikey: _api_key // 'w2CSyDUWhMUU1OpWty-5iSqvxkdWgY3L5YvLkt90ZmE' // moje konto Freemium,
      });
      const buildinfo = H.buildInfo();
      if (buildinfo && buildinfo.version)
        console.log(`Here API version: 3.${buildinfo.version}`);
    }
    return ret;
  }

  /**
   * Tworzy i inicjuje obiekt H.Map
   */
  public createHereMap(): any {
    const defaultLayers = this.platform.createDefaultLayers();
    const mapobj = new H.Map(
      document.getElementById('themap'),
      defaultLayers.vector.normal.map,
      {
        zoom: 10,
        center: { lat: 50.76254, lng: 17.85235 },
        pixelRatio: window.devicePixelRatio || 1
      });

    // Make the map interactive
    // const behavior = this.getBehavior(mapobj);
    const mapEvents = new H.mapevents.MapEvents(mapobj);
    this.behavior = new H.mapevents.Behavior(mapEvents);

    // Create the default UI:
    this.ui = H.ui.UI.createDefault(mapobj, defaultLayers, 'pl-PL');

    const _this = this;

    // disable the default draggability of the underlying map when starting to drag a marker object:
    mapobj.addEventListener('dragstart', function (ev) {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        _this.behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map when dragging has completed
    mapobj.addEventListener('dragend', function (ev) {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        _this.behavior.enable();
      }
    }, false);

    // Listen to the drag event and move the position of the marker as necessary
    mapobj.addEventListener('drag', function (ev) {
      const target = ev.target;
      const pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        let handle_event = true;
        if (_this.cur_select_rect) {
          const box = _this.cur_select_rect.getBoundingBox();
          const target_geo = target.getGeometry();
          if (box.containsPoint(target_geo)) {
            handle_event = false;
            // let evt1 = ev;
            // evt1.target = _this.cur_select_rect;
            // _this.cur_select_rect.dispatchEvent(evt1);
          }
        }
        if (handle_event)
          target.setGeometry(mapobj.screenToGeo(pointer.viewportX, pointer.viewportY));
      }
    }, false);

    return mapobj;
  }

  /**
   * Usuwa wszystkie obiekty (markery, grupy markerów, okna infobubble) z obiektu mapy
   * @param mapobj obiekt klasy H.Map
   */
  public clearHereMap(mapobj: any): void {

    if (mapobj && (mapobj instanceof H.Map)) {

      const objs = mapobj.getObjects(true);
      if (objs) {
        for (let i = 0; i < objs.length; i++) {
          if (objs[i] && (objs[i] instanceof H.map.Group)) {
            objs[i].dispose(); // remove event listeners
            objs[i].removeAll(); // remove objects
          }
        }
      }

      const objs2 = mapobj.getObjects(true);
      if (objs2) {
        for (let i = 0; i < objs2.length; i++) {
          if (objs2[i]) {
            //objs2[i].dispose(); // remove event listeners
            mapobj.removeObject(objs2[i]); // remove object
          }
        }
      }

      const bubbles = this.ui.getBubbles();
      if (bubbles) {
        for (let i = 0; i < bubbles.length; i++)
          this.ui.removeBubble(bubbles[i]);
      }
    }
  }


  // 
  //  geocoding section - interfejs publiczny
  //

  /**
   * Zwraca obiekt typu Promise, w ktorym opakowane jest wywolanie geocoder.geocode
   * @param {string} addr - adres, ktorego pozycje mamy znalezc
   */
  public geocodePromise(addr: Address): Promise<any> {
    // return this.geocodePromise_v6(address);
    return this.geocodePromise_v7(addr);
  }

  /**
   * Zwraca obiekt typu Observable, w ktorym opakowane jest wywolanie geocoder.geocode
   * @param {string} addr - adres, ktorego pozycje mamy znalezc
   */
  public geocodeObservable$(addr: Address): Observable<any> {
    const o = from(this.geocodePromise(addr))
      .pipe(
        debounceTime(150),
        catchError(error => of({ "error_info": "geocoding request failed", "error_details": error.query_address }))
      );
    return o;
  }

  /**
   * Wyciaga dane lokalizacji z obiektu zwroconego przez geocode
   * Zwraca obiekt {Latitude: 50.05747, Longitude: 19.92937, State: "Woj. Dolnośląskie"} lub null
   * @param result : obiekt zwrocony przez geocoder.geocode
   */
  public geocodeResult(result: any): any {
    if (this.geocodeResultIsError(result))
      return null;
    else
      // return this.geocodeResult_v6(result);
      return this.geocodeResult_v7(result);
  }

  /**
   * Sprawdza, czy zwrocone dane lokalizacji nie sa komunikatem o bledzie
   * Zwraca true jesli obiekt jest bledem, false jesli nie
   * @param result : obiekt zwrocony przez geocoder.geocode
   */
  public geocodeResultIsError(result: any): boolean {
    let ret = false;
    if (("error_info" in result) && ("error_details" in result)) {
      console.error(result.error_info, "details:", result.error_details);
      ret = true;
    }
    return ret;
  }

  //
  // geocoding here v6 - implementacja
  //

  /**
   * Wyciaga dane lokalizacji z obiektu zwroconego przez geocode
   * Zwraca obiekt {Latitude: 50.05747, Longitude: 19.92937, State: "Woj. Dolnośląskie"} lub null
   * @param result : obiekt zwrocony przez geocoder.geocode
   */
  // protected geocodeResult_v6(result: any): any {
  //   let ret = null;
  //   if (result)
  //     if (result.Response)
  //       if (result.Response.View)
  //         if (result.Response.View.length > 0)
  //           if (result.Response.View[0].Result.length > 0) {
  //             console.log(result.Response.View['0'].Result['0']);
  //             const display_position = result.Response.View['0'].Result['0'].Location.DisplayPosition;
  //             const address = result.Response.View['0'].Result['0'].Location.Address;
  //             ret = {
  //               Latitude: display_position.Latitude,
  //               Longitude: display_position.Longitude,
  //               State: address.State,
  //               Country: address.Country
  //             };
  //           }
  //   return ret;
  // }

  /**
   * Zwraca obiekt typu Promise, w ktorym opakowane jest wywolanie geocoder.geocode
   * @param {Address} addr - obiekt z danymi adresowymi, ktorych pozycje mamy znalezc
   */
  // protected geocodePromise_v6(addr: Address): Promise<any> {
  //   // tslint:disable-next-line:no-var-keyword prefer-const
  //   var _this = this;
  //   return new Promise<any>(function (resolve, reject) {
  //     const geocoder = _this.platform.getGeocodingService();
  //     console.log('geocode search v6 for', addr.fulltext);
  //     const parameters = { searchtext: addr.fulltext, gen: '9' };
  //     geocoder.geocode(parameters,
  //       function (result) { result.query_address = addr.dump(); resolve(result); },
  //       function (error) { reject(error); });
  //   });
  // }

  // 
  // geocode v7 - implementacja
  //  

  /**
   * Zwraca obiekt typu Promise, w ktorym opakowane jest wywolanie geocoder.geocode
   * @param {Address} addr - obiekt z danymi adresowymi, ktorych pozycje mamy znalezc
   */
  protected geocodePromise_v7(addr: Address): Promise<any> {
    // tslint:disable-next-line:no-var-keyword prefer-const
    var _this = this;
    return new Promise<any>(function (resolve, reject) {
      const geocoder = _this.platform.getSearchService();
      const parameters = _this.createGeocodeParameters(addr);
      console.log('geocode search v7 started, query:', parameters);
      geocoder.geocode(parameters,
        function (result) { result.query_address = addr.dump(); resolve(result); },
        function (error) { error.query_address = addr.dump(); reject(error); });
    });
  }

  /**
   * Zwraca obiekt z parametrami zapytania geocode v7
   * @param {Address} addr - obiekt z danymi adresowymi, ktorych pozycje mamy znalezc
   */
  protected createGeocodeParameters(addr: Address): any {
    let ret: any = {};

    if (addr.has_structured_address()) {
      ret.qq = addr.get_structured_address();
    }
    else if (addr.fulltext.length > 0) {
      ret.q = addr.fulltext;
    }
    else {
      console.error("Bad person object no structured or fulltext address found:", addr);
      ret.q = addr.create_fulltext_address();
    }

    if (addr.country_code.length > 0 && addr.country_code[0] != '?')
      ret.in = `countryCode:${addr.country_code}`

    return ret;
  }

  /**
   * Wyciaga dane lokalizacji z obiektu zwroconego przez geocode
   * Zwraca obiekt z wybranymi danymi{Latitude: 50.05747, Longitude: 19.92937, State: "Woj. Dolnośląskie", Country: "Polska" } lub null
   * @param result : obiekt zwrocony przez geocoder.geocode
   */
  protected geocodeResult_v7(result: any): any {
    let ret = null;
    console.log("geocoding result: ", result);
    let item = this.findBestGeocodeResult_v7(result);
    if (item) {
      ret = {
        Latitude: item.position.lat,
        Longitude: item.position.lng,
        State: item.address.state,
        Country: item.address.countryName,
        CountryCode: item.address.countryCode,
        PostalCode: item.address.postalCode
      }
    }
    return ret;
  }

  /**
   * Wybiera najlepszy wynik z wyszukanych pozycji z tabeli zwroconej przez HERE. 
   * Tabela jest posortowana przez HERE wg parametru item.scoring.queryScore
   * Zwraca pojeynczy obiekt z tabeli result.items
   * @param result : obiekt zwrocony przez geocoder.geocode
   */
  protected findBestGeocodeResult_v7(result: any) {
    let ret = null;
    if (result) {
      if (result.items) {
        // dopisuje pozycje tego wyniku w odpowiedzi HERE
        for (let i = 0; i < result.items.length; i++)
          result.items[i].result_idx = i + 1

        for (let i = 0; i < result.items.length; i++) {
          const obj = result.items[i];
          if (i == 0)
            ret = obj;
          else
            ret = this.better_result_of(ret, obj);
        }
      }
    }
    return ret;
  }

  /**
   * algorym wyboru lepszego z dwoch wynikow wyszukiwania geocode v2
   * @param obj1 obiekt z tabeli items zwracanej przez geocode
   * @param obj2 obiekt z tabeli items zwracanej przez geocode
   * @returns lepszy z obiektow: obj1 lub obj2
   */
  protected better_result_of(obj1: any, obj2: any): any {
    const code1 = this.get_fieldscore(obj1, "postalCode");
    const city1 = this.get_fieldscore(obj1, "city");
    const pos1 = obj1.result_idx ? obj1["result_idx"] : -1;

    const code2 = this.get_fieldscore(obj2, "postalCode");
    const city2 = this.get_fieldscore(obj2, "city");
    const pos2 = obj2.result_idx ? obj2["result_idx"] : -1;

    if (pos1 == -1 || pos2 == -1) {
      console.error("heremaps.services better_result_of: application error, result_idx field is missing", obj1, obj2);
      return obj1;
    }

    // dla takich samych code wybieram wedlug city, a potem
    // wedlug pozycji na liscie wynikow(pierwsze pozycje sa lepsze)
    if (code1 == code2) {
      if (city1 == city2) {
        if (pos1 < pos2)
          return obj1;
        else  // pos1 i pos2 cannot be equal
          return obj2;
      }
      else if (city1 > city2)
        return obj1;
      else
        return obj2;
    }
    // specjalny przypadek, niby powinienem wybierac wedlug lepszego code,
    // ale jesli city1 i city2 roznia sie o prawie 100%, to wybieram wedlug city
    // wykluczam jednak przypadki, gdy ktorys z kodow jest wyznaczony na 100 %
    else if ((code1 != 1.0) && (code2 != 1.0) && (Math.abs(city1 - city2) >= 0.98)) {
      if (city1 > city2)
        return obj1;
      else
        return obj2;
    }
    // ostatecznie wybieram wedlug kodow pocztowych
    else if (code1 > code2)
      return obj1;
    else
      return obj2;
  }

  /**
   * zwraca wartosc part_name z obiektu fieldScore z odpowiedzi z geocodingu
   * @param item obiekt z tabeli items zwracanej przez geocode
   * @param part_name nazwa pola z obiektu fieldScore
   * @returns wartosc pola fieldscore
   */
  protected get_fieldscore(item: any, part_name: string): number {
    let ret = 0.0;
    if (item)
      if (item.scoring)
        if (item.scoring.fieldscore)
          if (item.scoring.fieldscore.hasOwnProperty(part_name))
            ret = Number(item.scoring.fieldscore[part_name])
    return ret
  }

  /* circle markup
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
  <circle stroke="white" fill="#1b468d" cx="12" cy="12" r="12" />
  <text x="11" y="18" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">24</text>
  </svg>
  */

  /**
   * Tworzy ikonę-pinezkę z markupu SVG o zadanym kolorze
   * @param colour
   */
  public create_icon_pin(colour: string): any {
    const svg_markup = `<svg width="32px" height="32px" viewBox="0 0 400 600" xmlns="http://www.w3.org/2000/svg">` +
      `<path d="m 365.027,44.5 c -30,-29.667 -66.333,-44.5 -109,-44.5 -42.667,0 -79,14.833 -109,44.5 -30,29.667 ` +
      `-45,65.5 -45,107.5 0,25.333 12.833,67.667 38.5,127 25.667,59.334 51.333,113.334 77,162 25.667,48.666 38.5,72.334 ` +
      `38.5,71 4,-7.334 9.5,-17.334 16.5,-30 7,-12.666 19.333,-36.5 37,-71.5 17.667,-35 33.167,-67.166 46.5,-96.5 13.334, ` +
      `-29.332 25.667,-59.667 37,-91 11.333,-31.333 17,-55 17,-71 0,-42 -15,-77.833 -45,-107.5 z m -76,139.5 c -9.333,9.333 ` +
      `-20.5,14 -33.5,14 -13,0 -24.167,-4.667 -33.5,-14 -9.333,-9.333 -14,-20.5 -14,-33.5 0,-13 4.667,-24 14,-33 9.333,-9 ` +
      `20.5,-13.5 33.5,-13.5 13,0 24.167,4.5 33.5,13.5 9.333,9 14,20 14,33 0,13 -4.667,24.167 -14,33.5 z" ` +
      `style="fill:${colour}" /></svg>`;
    return new H.map.Icon(svg_markup);
  }

  /**
   * Tworzy ikonę-kwadrat z markupu SVG o zadanym kolorze
   * @param colour
   */
  public marker_icon_create(colour: string): any {
    const svg_markup = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">` +
      `<rect stroke="white" fill="${colour}" x="1" y="1" width="22" height="22" /></svg>`;
    return new H.map.Icon(svg_markup);
  }

  /**
   * creates marker object with custom html data shown on click
   * returns javascript object: H.map.Marker
   * @param val_lat szerokosc geograficzna (string)
   * @param val_lng dlugosc geograficzna (string)
   * @param html opcjonalny, zawartość wyświetlana w okienku po kliknieciu w marker
   * @param bgcolor opcjonalny, kolor markera
   */
  public marker_create(val_lat: string, val_lng: string, html: string = '', bgcolor: string = 'red'): any {
    const svgicon = this.marker_icon_create(bgcolor);
    const marker = new H.map.Marker({ lat: val_lat, lng: val_lng }, { icon: svgicon });
    // const marker = new H.map.Marker({lat: val_lat, lng: val_lng});
    if (html.length > 0)
      marker.setData(html);
    return marker;
  }

  /**
   * creates marker object with custom html data shown on click
   * returns javascript object: H.map.Marker
   * @param val_lat szerokosc geograficzna (string)
   * @param val_lng dlugosc geograficzna (string)
   * @param html opcjonalny, zawartość wyświetlana w okienku po kliknieciu w marker
   */
  public marker_create_default(val_lat: string, val_lng: string, html: string = ''): any {
    const marker = new H.map.Marker({ lat: val_lat, lng: val_lng });
    if (html.length > 0)
      marker.setData(html);
    return marker;
  }

  /**
   * creates marker object with custom SVG icon and optional html data shown on click
   * returns javascript object: H.map.Marker
   * @param val_lat szerokosc geograficzna (string)
   * @param val_lng dlugosc geograficzna (string)
   * @param html opcjonalny, zawartość wyświetlana w okienku po kliknieciu w marker
   */
  public marker_create_custom_icon(val_lat: string, val_lng: string, svgicon: any, html: string = ''): any {
    if (val_lat.length === 0 ||
      val_lng.length === 0 ||
      svgicon == null || svgicon === undefined)
      return null;
    const marker = new H.map.Marker({ lat: val_lat, lng: val_lng }, { icon: svgicon });
    if (html.length > 0)
      marker.setData(html);
    return marker;
  }

  /**
   * Tworzy grupe markerow: obiekt H.map.Group
   * @param arr_marker tablica z obiektami H.map.Marker, ktore maja nalezec do grupy
   */
  public marker_group_create(arr_marker: any[]): any {
    const marker_group = new H.map.Group();

    marker_group.addEventListener('contextmenu', (evt) => {
      //console.log(evt);
      const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), { content: evt.target.getData() });
      // podmieniam funkcje close, bo oryginalna nie usuwala obiektu z DOM, co powodowalo
      // problemy z kodem jquery wewnatrz htmlu markera (zaznaczanie checkboxow)
      const close_org = bubble.close;
      const this_ = this;
      bubble.close = function () {
        close_org.apply(bubble);
        this_.ui.removeBubble(bubble);
        bubble.dispose();
      };
      this.ui.addBubble(bubble);
      return false;
    }, false);

    marker_group.addEventListener('tap', (evt) => {
      if (evt.originalEvent && evt.originalEvent.button == 0) { // left click
        //console.log('marker group on left click, evt:', evt);
        const cust_event = new CustomEvent('here_left_click', { detail: evt, bubbles: true });
        window.dispatchEvent(cust_event);
      }
      return false;
    }, false);

    marker_group.addObjects(arr_marker);
    return marker_group;
  }

  /**
   * Dodaje linie-trase przejazdu do mapy, wersja kompatybilna z HERE ROUTING API v8
   * @param mapobj
   * @param route
   */
  public addRouteShapeToMap(mapobj: any, route: any): void {
    let geoRect = null;
    route.sections.forEach((section) => {
      let linestring = new H.geo.LineString.fromFlexiblePolyline(section.polyline);
      let polyline = new H.map.Polyline(linestring, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(0, 128, 255, 0.7)'
        }
      });
      mapobj.addObject(polyline);
      if (!geoRect)
        geoRect = polyline.getBoundingBox();
      else
        geoRect = geoRect.mergeRect(polyline.getBoundingBox());
    });
    mapobj.getViewModel().setLookAtData({ bounds: geoRect });
  }

  /**
   * przelicza predkosc z km/h na m/s
   * @param val_kph 
   * @returns 
   */
  protected get_meters_per_second(val_kph: number): number {
    return val_kph * 1000.0 / 3600.0;
  }


  /**
   * 
   * zwraca { origin: [string, string], via: [string, string][], destination: [string, string]}
   */
  private getRouteRequestParamsPoints(waypoints: [string, string][]): any {
    if (waypoints.length < 2)
      throw Error('HereMapsService.getRouteRequestParamsPoints: not enough waypoints to calculate route');

    const wp_origin = waypoints[0];
    const wp_destination = waypoints[waypoints.length - 1];

    let ret = {
      origin: `${wp_origin[0]},${wp_origin[1]}`,
      destination: `${wp_destination[0]},${wp_destination[1]}`,
      via: []
    };

    if (waypoints.length > 2) {
      let via_arr: string[] = [];
      for (let i = 1; i < waypoints.length - 1; i++) {
        const p = waypoints[i];
        via_arr.push(`${p[0]},${p[1]}`);
      }
      ret.via = via_arr;
    }

    return ret;
  }

  /**
   * 
   * zwraca { origin: [string, string], via: [string, string][], destination: [string, string]}
   */
  private getRouteRequestParamsPointsNew(waypoints: [string, string][]): any {
    if (waypoints.length < 2)
      throw Error('HereMapsService.getRouteRequestParamsPointsNew: not enough waypoints to calculate route');

    const wp_origin = waypoints[0];
    const wp_destination = waypoints[waypoints.length - 1];

    let ret = {
      origin: `${wp_origin[0]},${wp_origin[1]}`,
      destination: `${wp_destination[0]},${wp_destination[1]}`,
      via: []
    };

    if (waypoints.length > 2) {
      let via_arr: string[] = [];
      for (let i = 1; i < waypoints.length - 1; i++) {
        const p = waypoints[i];
        via_arr.push(`${p[0]},${p[1]}`);
      }
      ret.via = via_arr;
    }

    return ret;
  }


  /**
   * Zwraca podsumowanie trasy przejazdu, wykorzystuje Routing API v8
   * @param waypoints
   * @param dt_start
   * @param route_type 'fast'|'short'
   */
  public getRouteSummary(waypoints: [string, string][], dt_start: Date): Promise<any> {
    const platform = this.platform;
    const router = platform.getRoutingService(null, 8);
    const routeRequestParams = {
      routingMode: 'fast', // 'fast | 'short'
      transportMode: 'car',
      lang: 'pl-pl',
      'vehicle[speedCap]': this.get_meters_per_second(this.default_bus_speed_kph),
      departureTime: dt_start.toISOString(), // converted to UTC, '2013-07-04T17:00:00+02', 
      return: 'summary,typicalDuration'
    };

    let route_points = this.getRouteRequestParamsPoints(waypoints);
    routeRequestParams['origin'] = route_points.origin;
    routeRequestParams['destination'] = route_points.destination;
    if (waypoints.length > 2)
      routeRequestParams['via'] = new H.service.Url.MultiValueQueryParameter(route_points.via);

    return new Promise(function (resolve, reject) {
      router.calculateRoute(routeRequestParams,
        function (result: any) { resolve(result); },
        function (error: any) { console.log('HereMapsService.getRouteSummary error'); reject(error); });
    });
  }

  /**
    * Zwraca szczegolowe dane o trasie przejazdu, wykorzystuje Routing API v8
   * @param waypoints 
   * @param dt_start 
   */
  public getRouteDetails(waypoints: [string, string][], dt_start: Date): Promise<any> {
    const platform = this.platform;
    const router = platform.getRoutingService(null, 8);
    const routeRequestParams = {
      routingMode: 'fast',
      transportMode: 'car',
      lang: 'pl-pl',
      'vehicle[speedCap]': this.get_meters_per_second(this.default_bus_speed_kph),
      departureTime: dt_start.toISOString(), // converted to UTC, '2013-07-04T17:00:00+02', 
      spans: 'names,length,duration,baseDuration,typicalDuration',
      return: 'summary,typicalDuration,travelSummary,polyline,actions,instructions,turnByTurnActions'
    };

    let route_points = this.getRouteRequestParamsPoints(waypoints);
    routeRequestParams['origin'] = route_points.origin;
    routeRequestParams['destination'] = route_points.destination;
    if (waypoints.length > 2)
      routeRequestParams['via'] = new H.service.Url.MultiValueQueryParameter(route_points.via);

    return new Promise(function (resolve, reject) {
      router.calculateRoute(routeRequestParams,
        function (result: any) { resolve({ route: result.routes[0], params: routeRequestParams }); },
        function (error: any) { console.log('HereMapsService.getRouteDetails error'); reject(error); });
    });
  }

  /**
   * Pobiera szczegoly trasy, HERE ROUTING API v8
   * @param waypoints tablica z punktami trasy: [lat, lng]
   * @param dt_start? zadana data rozpoczecia przejazdu 
   * @param dt_stop? zadana data zakonczenia przejazdu
   * @returns 
   */
  public getRouteDetailsNew(waypoints: [string, string][], dt_start?: Date, dt_stop?: Date): Promise<any> {
    const platform = this.platform;
    const router = platform.getRoutingService(null, 8);
    const routeRequestParams: any = {
      routingMode: 'fast',
      transportMode: 'car',
      lang: 'pl-pl',
      'vehicle[speedCap]': this.get_meters_per_second(this.default_bus_speed_kph),
      spans: 'names,length,duration,baseDuration,typicalDuration',
      return: 'summary,typicalDuration,polyline,actions,instructions,turnByTurnActions' // travelSummary docelowo do usuniecia
    };

    if (dt_start)
      routeRequestParams.departureTime = dt_start.toISOString(); // converted to UTC, '2013-07-04T17:00:00+02', 
    else if (dt_stop)
      routeRequestParams.arrivalTime = dt_stop.toISOString(); // converted to UTC, '2013-07-04T17:00:00+02', 
    else
      console.log('getRouteDetailsNew: empty dt_start and dt_stop parameters');

    let route_points = this.getRouteRequestParamsPointsNew(waypoints);
    routeRequestParams['origin'] = route_points.origin;
    routeRequestParams['destination'] = route_points.destination;
    if (waypoints.length > 2)
      routeRequestParams['via'] = new H.service.Url.MultiValueQueryParameter(route_points.via);

    return new Promise(function (resolve, reject) {
      router.calculateRoute(routeRequestParams,
        function (result: any) { resolve({ route: result.routes[0], params: routeRequestParams }); },
        function (error: any) { console.log('HereMapsService.getRouteDetails error'); reject(error); });
    });
  }

  /**
   * znajduje na mapie obiekt Polyline z zadanym id
   * @param mapobj
   */
  public findPolylineShape(mapobj: any, id: number): any {
    if (mapobj && (mapobj instanceof H.Map)) {
      const objs = mapobj.getObjects(true);
      if (objs) {
        for (let i = 0; i < objs.length; i++) {
          const _obj = objs[i];
          if (_obj && (_obj instanceof H.map.Polyline)) {
            if (_obj.getId() == id)
              return _obj;
          }
        }
      }
    }
    return null;
  }

  /**
   * usuwa z mapy wyrysowane linie Polyline, wszystkie  (id=-1) lub linie z wybranym id
   * @param mapobj
   */
  public clearPolylineShape(mapobj: any, id = -1): void {
    if (mapobj && (mapobj instanceof H.Map)) {
      const objs = mapobj.getObjects(true);
      if (objs) {
        for (let i = 0; i < objs.length; i++) {
          const _obj = objs[i];
          if (_obj && (_obj instanceof H.map.Polyline)) {
            if (id == -1 || (_obj.getId() == id))
              mapobj.removeObject(_obj);
          }
        }
      }
    }
  }

  /**
   * Dodaje do mapy prostokąt do zaznaczania
   * Zwraca obiekt H.map.Group do ktorego dodano prostokat
   * @param mapobj
   */
  public create_selection_rect(mapobj: any): any {

    // obliczanie dynamicznie wielkosci tworzonego prostokąta
    // w zależności od wyswietlanego obszaru mapy
    // let viewport_rect = H.geo.Rect(0,0,0,0);
    // const bounds = mapobj.getViewModel().getLookAtData().bounds;
    // if (bounds instanceof H.geo.Polygon) // engine WEBGL
    //   viewport_rect = bounds.getBoundingBox();
    // else // engine P2D, bounds is a H.geo.Recti
    //   viewport_rect = bounds;

    // let my_rect = H.geo.Rect(0,0,0,0);
    // if (!viewport_rect.isEmpty()) {
    //   my_rect = new H.geo.Rect(
    //     viewport_rect.getTop() + (viewport_rect.getHeight() / 4),
    //     viewport_rect.getLeft() + (viewport_rect.getWidth() / 4),
    //     viewport_rect.getTop() +  (3 * viewport_rect.getHeight() / 4),
    //     viewport_rect.getLeft() + (3 * viewport_rect.getWidth() / 4)
    //   );
    // }

    //const behavior = this.getBehavior(mapobj);

    const pt_center = mapobj.getCenter(); // H.geo.Point
    const my_rect = new H.geo.Rect(pt_center.lat - 1, pt_center.lng - 2,
      pt_center.lat + 1, pt_center.lng + 2);

    const rect = new H.map.Rect(my_rect, {
      style: { fillColor: 'rgba(100, 100, 100, 0.5)', lineWidth: 0 },
      zIndex: 100
    });

    const rectOutline = new H.map.Polyline(
      rect.getGeometry().getExterior(), {
      style: { lineWidth: 8, strokeColor: 'rgba(255, 0, 0, 0)', fillColor: 'rgba(0, 0, 0, 0)', lineCap: 'square' },
      zIndex: 100
    });
    let rectTimeout: any = null;

    const rectGroup = new H.map.Group({
      volatility: true, // mark the group as volatile for smooth dragging of all it's objects
      objects: [rect, rectOutline],
      zIndex: 100
    });

    rect.draggable = true;
    rectOutline.draggable = true;

    // extract first point of the rect's outline polyline's LineString and
    // push it to the end, so the outline has a closed geometry
    rectOutline.getGeometry().pushPoint(rectOutline.getGeometry().extractPoint(0));

    // place group with objects on the map
    mapobj.addObject(rectGroup);

    const _this = this;

    // pointerenter
    // event listener for rectangle group to show outline (polyline) if moved in with mouse (or touched on touch devices)
    rectGroup.addEventListener('pointerenter', function (evt) {
      const currentStyle = rectOutline.getStyle();
      const newStyle = currentStyle.getCopy({ strokeColor: 'rgb(255, 0, 0)' });

      if (rectTimeout) {
        clearTimeout(rectTimeout);
        rectTimeout = null;
      }

      rectOutline.setStyle(newStyle);
    }, true);

    // pointerleave
    // event listener for rectangle group to hide outline if moved out with mouse (or released finger on touch devices)
    // the outline is hidden on touch devices after specific timeout
    rectGroup.addEventListener('pointerleave', function (evt) {
      const currentStyle = rectOutline.getStyle();
      const newStyle = currentStyle.getCopy({ strokeColor: 'rgba(255, 0, 0, 0)' });
      const timeout = (evt.currentPointer.type == 'touch') ? 1000 : 0;

      rectTimeout = setTimeout(function () {
        rectOutline.setStyle(newStyle);
      }, timeout);

      document.body.style.cursor = 'default';
    }, true);

    // pointermove
    // event listener for rectangle group to change the cursor if mouse position is over the outline polyline (resizing is allowed)
    rectGroup.addEventListener('pointermove', function (evt) {
      const pointer = evt.currentPointer;
      const objectTopLeftScreen = mapobj.geoToScreen(evt.target.getGeometry().getBoundingBox().getTopLeft());
      const objectBottomRightScreen = mapobj.geoToScreen(evt.target.getGeometry().getBoundingBox().getBottomRight());
      let draggingType = '';

      // only set cursor and draggingType if target is outline polyline
      if (evt.target != rectOutline) {
        return;
      }

      // change document cursor depending on the mouse position
      if (pointer.viewportX < (objectTopLeftScreen.x + 4)) {
        document.body.style.cursor = 'ew-resize'; // mouse position is at left side
        draggingType = 'left';
      } else if (pointer.viewportX > (objectBottomRightScreen.x - 4)) {
        document.body.style.cursor = 'ew-resize'; // mouse position is at right side
        draggingType = 'right';
      } else if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
        document.body.style.cursor = 'ns-resize'; // mouse position is at top side
        draggingType = 'top';
      } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
        document.body.style.cursor = 'ns-resize'; // mouse position is at the bottom side
        draggingType = 'bottom';
      } else {
        document.body.style.cursor = 'pointer'
        draggingType = 'move';
      }

      if (draggingType == 'left') {
        if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
          document.body.style.cursor = 'nwse-resize'; // mouse position is at the top-left corner
          draggingType = 'left-top';
        } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
          document.body.style.cursor = 'nesw-resize'; // mouse position is at the bottom-left corner
          draggingType = 'left-bottom';
        }
      } else if (draggingType == 'right') {
        if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
          document.body.style.cursor = 'nesw-resize'; // mouse position is at the top-right corner
          draggingType = 'right-top';
        } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
          document.body.style.cursor = 'nwse-resize'; // mouse position is at the bottom-right corner
          draggingType = 'right-bottom';
        }
      }

      rectGroup.setData({ 'draggingType': draggingType });
    }, true);

    // dragstart
    // add event listeners for rect object
    rectGroup.addEventListener('dragstart', function (evt) {
      const pointer = evt.currentPointer;
      const object = evt.target;

      // store the starting geo position
      object.setData({
        startCoord: mapobj.screenToGeo(pointer.viewportX, pointer.viewportY)
      });
      if (object === rectOutline)
        _this.behavior.disable();
      evt.stopPropagation();
    }, true);

    // drag
    // event listener for rect group to resize the geo rect object if dragging over outline polyline
    rectGroup.addEventListener('drag', function (evt) {
      const pointer = evt.currentPointer;
      if (evt.target instanceof H.map.Polyline) { // we're resizing
        const pointerGeoPoint = mapobj.screenToGeo(pointer.viewportX, pointer.viewportY);
        const currentGeoRect = rect.getGeometry().getBoundingBox();
        //const objectTopLeftScreen = mapobj.geoToScreen(currentGeoRect.getTopLeft());
        //const objectBottomRightScreen = mapobj.geoToScreen(currentGeoRect.getBottomRight());
        const currentTopLeft = currentGeoRect.getTopLeft();
        const currentBottomRight = currentGeoRect.getBottomRight();
        let newGeoRect: any = null;
        let outlineLinestring: any = null;

        // update rect's size depending on dragging type:
        switch (rectGroup.getData()['draggingType']) {
          case 'left-top':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            newGeoRect = H.geo.Rect.fromPoints(pointerGeoPoint, currentGeoRect.getBottomRight());
            break;
          case 'left-bottom':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            currentTopLeft.lng = pointerGeoPoint.lng;
            currentBottomRight.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight);
            break;
          case 'right-top':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            currentTopLeft.lat = pointerGeoPoint.lat;
            currentBottomRight.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight);
            break;
          case 'right-bottom':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), pointerGeoPoint);
            break;
          case 'left':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng >= currentBottomRight.lng) {
              return;
            }
            currentTopLeft.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight());
            break;
          case 'right':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lng <= currentTopLeft.lng) {
              return;
            }
            currentBottomRight.lng = pointerGeoPoint.lng;
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
            break;
          case 'top':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lat <= currentBottomRight.lat) {
              return;
            }
            currentTopLeft.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight());
            break;
          case 'bottom':
            // we don't allow resizing to 0 or to negative values
            if (pointerGeoPoint.lat >= currentTopLeft.lat) {
              return;
            }
            currentBottomRight.lat = pointerGeoPoint.lat;
            newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
            break;
          case 'move':
            console.log('drag: oops entered resizing code while moving');
            break;
        }

        // set the new bounding box for rect object
        if (newGeoRect)
          rect.setBoundingBox(newGeoRect);

        // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
        outlineLinestring = rect.getGeometry().getExterior();
        outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
        rectOutline.setGeometry(outlineLinestring);
      }
      else { // we're moving
        const object = evt.target;
        const startCoord = object.getData()['startCoord'];
        const newCoord = mapobj.screenToGeo(pointer.viewportX, pointer.viewportY);
        let outlineLinestring: any = null;

        // create new Rect with updated coordinates
        let newGeoRect = object.getGeometry().getBoundingBox();
        if (!newCoord.equals(startCoord)) {
          const currentGeoRect = object.getGeometry().getBoundingBox();
          const newTop = currentGeoRect.getTop() + newCoord.lat - startCoord.lat;
          const newLeft = currentGeoRect.getLeft() + newCoord.lng - startCoord.lng;
          const newBottom = currentGeoRect.getBottom() + newCoord.lat - startCoord.lat;
          const newRight = currentGeoRect.getRight() + newCoord.lng - startCoord.lng;
          newGeoRect = new H.geo.Rect(newTop, newLeft, newBottom, newRight);

          // prevent dragging to latitude over 90 or -90 degrees to prevent loosing altitude values
          if (newTop >= 90 || newBottom <= -90) {
            return;
          }

          if (newGeoRect)
            object.setBoundingBox(newGeoRect);
          object.setData({
            startCoord: newCoord
          });

          // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
          outlineLinestring = rect.getGeometry().getExterior();
          outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
          rectOutline.setGeometry(outlineLinestring);
        }
      }
      // prevent event from bubling, so map doesn't receive this event and doesn't pan
      evt.stopPropagation();
    }, true);

    // dragend
    // event listener for rect group to enable map's behavior
    rectGroup.addEventListener('dragend', function (evt) {
      // enable behavior
      _this.behavior.enable();
    }, true);

    return rectGroup;
  }

  /**
   * Usuwa z mapy prostokąt do zaznaczania
   * @param mapobj
   * @param sel_rect
   */
  public remove_selection_rect(mapobj: any, sel_rect: any) {
    if (sel_rect) {
      sel_rect.dispose(); // remove event listeners
      if (sel_rect instanceof H.map.Group)
        sel_rect.removeAll(); // remove objects
      else
        mapobj.removeObject(sel_rect);
    }
  }

  public addPolylineToMap(mapobj: any, geopoints: any[], color: string, width: number, visible: boolean) {
    let lineString = new H.geo.LineString();

    for (const obj of geopoints)
      lineString.pushPoint({ lat: obj.lat, lng: obj.lng });

    const polyline = new H.map.Polyline(lineString, {
      style: { lineWidth: width, strokeColor: color },
      visibility: visible
    });
    mapobj.addObject(polyline);
    return polyline.getId();
  }

  public changePolylineWidth(mapobj: any, polyline_id: number, width: number) {
    let obj = this.findPolylineShape(mapobj, polyline_id);
    if (obj) {
      const newStyle = obj.getStyle().getCopy({ lineWidth: width });
      obj.setStyle(newStyle);
    }
  }

  public changePolylineColor(mapobj: any, polyline_id: number, color: string) {
    let obj = this.findPolylineShape(mapobj, polyline_id);
    if (obj) {
      const newStyle = obj.getStyle().getCopy({ strokeColor: color });
      obj.setStyle(newStyle);
    }
  }
}
