import { forkJoin, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AppHelperService } from '../_services/app-helper.service';
import { Settings } from '../settings';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public settings: Settings = new Settings();

  constructor(
    private apphelper: AppHelperService,
    private datasrv: DataService
  ) { }

  ngOnInit() {
    this.settings = this.apphelper.getSettings();
  }

  public saveChanges() {
    if (this.apphelper.saveSettings(this.settings)) {
      const it_settings = Object.getOwnPropertyNames(this.settings);
      const observ_objs: Observable<any>[] = [];
      for (const it of it_settings) {
        const o = this.datasrv.updateSettings$(it, this.settings[it]);
        observ_objs.push(o);
      }
      forkJoin(observ_objs).subscribe(
        data => { },
        error => this.apphelper.error_handler(error, 'Settings.saveChanges'),
        () => { }
      );
    }
  }
}
