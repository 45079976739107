<div class="d-flex card-mappoint">
  <div class="flex-grow-1 text-left">
    <p class="mappoint-addr">
      <span class="badge-info px-1">{{lp_rozp}}</span>
      {{model.addr1}}
      <span *ngIf="model.addr2.length > 0">, {{model.addr2}}</span>
    </p>
    <label class="mappoint-label">Przyjazd:</label>
    <input type="number" min="0" max="23" placeholder="G" [ngModel]="model.time_hour"
      (ngModelChange)="onTimeHourChange($event)" [ngClass]="{'error': !is_valid_hour}">
    :
    <input type="number" min="0" max="59" placeholder="M" [ngModel]="model.time_minutes"
      (ngModelChange)="onTimeMinutesChange($event)" [ngClass]="{'error': !is_valid_minutes}">
  </div>
  <div class="d-inline-block">
    <span *ngIf="!model.has_gps_data()" class="badge badge-pill badge-danger">GPS</span>
    <span class="badge badge-pill badge-light">{{model.total_persons}}</span>
    <!-- <span class="handle ml-1">=</span> -->
  </div>
</div>