import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MapPoint } from '../map-point';
import { CarTask } from '../car-task';

@Component({
  selector: 'app-modal-map-point-position',
  templateUrl: './modal-map-point-position.component.html',
  styleUrls: ['./modal-map-point-position.component.css']
})
export class ModalMapPointPositionComponent implements OnInit {

  closeResult: string;

  public mappoints: MapPoint[] = [];
  public cartask_start: CarTask = null;
  public cartask_stop: CarTask = null;
  public selected_mappoint_idx = -1;
  public selected_radio = 'mp';

  @ViewChild('content', { static: true }) content: any;

  constructor(private modal: NgbModal) { }

  private init_selected_ids(): void {
    if (this.mappoints.length > 0) {
      this.selected_radio = 'mp';
      this.selected_mappoint_idx = this.mappoints[0].idx;
    }
    else if (this.cartask_start)
      this.selected_radio = 'start';
    else if (this.cartask_stop)
      this.selected_radio = 'stop';
    else { }
  }

  public open_promise(): Promise<any> {
    this.init_selected_ids();
    return this.modal.open(this.content).result;
  }

  public open(): void {
    this.init_selected_ids();
    this.modal.open(this.content).result.then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public is_editable(): boolean {
    return (this.mappoints.length > 0) ||
      (this.cartask_start != null) ||
      (this.cartask_stop != null);
  }

  public onChangeMapPoint(value: string): void {
    this.selected_mappoint_idx = Number(value);
  }

  public onRadioChange(value: string): void {
    this.selected_radio = value;
  }

  public attr_disabled(val: string): string {
    return this.selected_radio === val ? '' : 'disabled';
  }

  public selected_mp(): boolean {
    return this.selected_radio === 'mp';
  }

  public selected_start(): boolean {
    return this.selected_radio === 'start';
  }

  public selected_stop(): boolean {
    return this.selected_radio === 'stop';
  }

  ngOnInit() {
  }
}
