import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';

@Component({
  selector: 'app-modal-edit-car-task',
  templateUrl: './modal-edit-car-task.component.html',
  styleUrls: ['./modal-edit-car-task.component.css']
})
export class ModalEditCarTaskComponent implements OnInit {

  closeResult: string;

  @Input() stoppoints: StopPoint[] = [];
  @Input() transferpoints: TransferPoint[] = [];
  @Input() cartask_name = '';
  public selected_gps_lat = '';
  public selected_gps_lon = '';
  public selected_radio = 'sp';
  public selected_stoppoint_id = -1;
  public selected_transferpoint_id = -1;
  public selected_address = '';

  @ViewChild('content', { static: true }) content: any;

  constructor(private modal: NgbModal) { }

  private init_selected_values(): void {
    this.selected_stoppoint_id = this.stoppoints.length > 0 ? this.stoppoints[0].id : -1;
    this.selected_transferpoint_id = this.transferpoints.length > 0 ? this.transferpoints[0].id : -1;
    this.selected_address = '';

    if (this.stoppoints.length > 0)
      this.selected_radio = 'sp';
    else if (this.transferpoints.length > 0)
      this.selected_radio = 'tp';
    else
      this.selected_radio = 'ap';
  }

  public open_promise(): Promise<any> {
    this.init_selected_values();
    return this.modal.open(this.content).result;
  }

  public open(): void {
    this.init_selected_values();
    this.modal.open(this.content).result.then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onChangeStopPoint(value: string): void {
    this.selected_stoppoint_id = Number(value);
  }

  public onChangeTransferPoint(value: string): void {
    this.selected_transferpoint_id = Number(value);
  }

  public onRadioChange(value: string): void {
    this.selected_radio = value;
  }

  public attr_disabled(val: string): string {
    return this.selected_radio === val ? '' : 'disabled';
  }

  public attr_readonly(val: string): string {
    return this.selected_radio === val ? '' : 'readonly';
  }

  public selected_sp(): boolean {
    return this.selected_radio === 'sp';
  }

  public selected_tp(): boolean {
    return this.selected_radio === 'tp';
  }

  public selected_ap(): boolean {
    return this.selected_radio === 'ap';
  }

  ngOnInit() {
  }

}
