
export class Settings {
  public here_api_key = '';
  public stop_time_person = 3; // minuty, czas postoju na osobe
  public stop_time_min = 15; // minuty, minimalny czas postoju
  public round_time_to_min = 5; // minuty, zaokraglanie w dol do nabliszych x minut

  constructor() { }

  public clear() {
    this.here_api_key = '';
    this.stop_time_person = 3;
    this.stop_time_min = 15;
    this.round_time_to_min = 5;
  }

  public is_valid(): boolean {
    return (this.here_api_key.length > 0 &&
      this.stop_time_person >= 0 &&
      this.stop_time_min >= 0 &&
      this.round_time_to_min >= 0
    ) ? true : false;
  }

  public to_json(): string {
    const o = {
      'here_api_key': this.here_api_key,
      'stop_time_person': this.stop_time_person,
      'stop_time_min': this.stop_time_min,
      'round_time_to_min': this.round_time_to_min,
    };
    return JSON.stringify(o);
  }

  public from_json(json: string): boolean {
    const o = JSON.parse(json);
    if (!o)
      return false;

    if (o['here_api_key'])
      this.here_api_key = o['here_api_key'];
    else
      return false;

    if (o['stop_time_person'])
      this.stop_time_person = Number(o['stop_time_person']);
    else
      return false;

    if (o['stop_time_min'])
      this.stop_time_min = Number(o['stop_time_min']);
    else
      return false;

    if (o['round_time_to_min'])
      this.round_time_to_min = Number(o['round_time_to_min']);
    else
      return false;

    return true;
  }

}
