import { Component, OnInit, Input } from '@angular/core';
import { StopPoint } from '../stop-point';

@Component({
  selector: 'app-card-stop-point',
  templateUrl: './card-stop-point.component.html',
  styleUrls: ['./card-stop-point.component.css']
})
export class CardStopPointComponent implements OnInit {

  @Input() model: StopPoint;

  constructor() { }

  ngOnInit() {
  }
}
