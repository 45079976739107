import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Car } from '../car';

@Component({
  selector: 'app-modal-change-car',
  templateUrl: './modal-change-car.component.html',
  styleUrls: ['./modal-change-car.component.css']
})
export class ModalChangeCarComponent implements OnInit {

  closeResult: string;
  public cars: Car[] = [];

  @ViewChild('content', { static: true }) content: any;
  @Input() person_name = 'Person name';
  @Input() person_car = 'Person car';
  @Input() map_cars: any = {};
  @Input() selected_car_id = -1;

  constructor(private modal: NgbModal) { }

  public refresh_cars(): void {
    const arr: Car[] = [];
    const it_cars = Object.getOwnPropertyNames(this.map_cars);
    for (const it of it_cars) {
      const car_obj = this.map_cars[it];
      arr.push(car_obj);
    }
    arr.sort((c1, c2) => c1.name.localeCompare(c2.name));
    this.cars = arr;
  }

  public open_promise(): Promise<any> {
    this.refresh_cars();
    return this.modal.open(this.content).result;
  }

  public open(): void {
    this.open_promise().then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onChange(value: string): void {
    this.selected_car_id = Number(value);
  }

  ngOnInit() {
  }

}
