
import { forkJoin, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';

import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';
import { DataService } from '../_services/data.service';
import { HereMapsService } from '../_services/heremaps.service';
import { AppHelperService } from '../_services/app-helper.service';
import { ModalPointPositionComponent } from '../modal-point-position/modal-point-position.component';

declare var H: any;

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.css']
})
export class PointsComponent implements OnInit, OnDestroy, AfterViewInit {

  public points: [StopPoint | null, TransferPoint | null][] = [];
  private markers: any[] = [];
  private marker_group: any = null;
  private the_map: any = null;
  public total_stoppoints_nogps = 0;
  public total_transferpoints_nogps = 0;
  public filter_stoppoints = true;
  public filter_transferpoints = true;

  @ViewChild('modalPointPosition', { static: true }) modal: ModalPointPositionComponent;

  constructor(
    private data_service: DataService,
    private maps_service: HereMapsService,
    private apphelper: AppHelperService,
    public router: Router,
    private _ngZone: NgZone) {

    window['angularComponentRef'] = {
      zone: this._ngZone,
      updateStopPointPositionFn: (value) => this.updateStopPointPosition(value),
      updateTransferPointPositionFn: (value) => this.updateTransferPointPosition(value),
      component: this
    };
  }

  // ---------------------------------------------------------------------------
  public ngOnInit() {
    this.total_stoppoints_nogps = 0;
    this.total_transferpoints_nogps = 0;
    this.points.length = 0;
  }

  // ---------------------------------------------------------------------------
  public ngOnDestroy(): void {
    window['angularComponentRef'] = null;
  }

  // ---------------------------------------------------------------------------
  public ngAfterViewInit() {
    this.the_map = this.maps_service.createHereMap();
    this.the_map.addEventListener('tap', (evt) => {  //  function (evt) {
      const target = evt.target;
      const pointer = evt.currentPointer;
      if (target instanceof H.Map) {
        const pos = this.the_map.screenToGeo(pointer.viewportX, pointer.viewportY);
        this.setPointPosition(pos);
      }
    }, false);

    this.refresh_all();
  }

  // ---------------------------------------------------------------------------
  private get_point_obj(idx: number): any {
    let ret = null;
    if (idx >= 0 && idx < this.points.length) {
      const tup = this.points[idx];
      ret = (tup[1] == null) ? tup[0] : tup[1];
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  private find_point_obj_idx(id: number, firstnull: boolean, secondnull: boolean): number {
    let ret = -1;
    if (firstnull === secondnull)
      return ret;
    for (let i = 0; i < this.points.length; i++) {
      if (firstnull) {
        if (this.points[i][0] == null)
          if (this.points[i][1] && Number(this.points[i][1].id) === Number(id)) {
            ret = i;
            break;
          }
      } else { // if (secondnull)
        if (this.points[i][1] == null)
          if (this.points[i][0] && Number(this.points[i][0].id) === Number(id)) {
            ret = i;
            break;
          }
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  private clear_map(): void {
    this.maps_service.clearHereMap(this.the_map);
  }

  // ---------------------------------------------------------------------------
  private refresh_map(): void {
    // pokaz krecaca sie ikonke

    this.clear_map();

    const arr_marker_objs: any[] = [];
    for (let i = 0; i < this.points.length; i++) {
      const obj = this.get_point_obj(i);
      if (obj && obj.has_gps_data()) {
        if (obj instanceof StopPoint) {
          const markerobj = this.apphelper.create_stoppoint_map_marker(this.maps_service, obj);
          markerobj.draggable = true;
          arr_marker_objs.push(markerobj);
        }
        else { // if obj instanceof TransferPoint
          const markerobj = this.apphelper.create_transferpoint_map_marker(this.maps_service, obj);
          markerobj.draggable = true;
          arr_marker_objs.push(markerobj);
        }
      }
    }

    this.marker_group = this.maps_service.marker_group_create(arr_marker_objs);
    if (this.marker_group) {
      this.the_map.addObject(this.marker_group);
      const _bounds = this.marker_group.getBoundingBox();
      if (_bounds)
        this.the_map.getViewModel().setLookAtData({ bounds: _bounds });
    }
    // schowaj krecaca sie ikonke
  }

  // ---------------------------------------------------------------------------
  public refresh_all(): void {
    this.points.length = 0;
    this.total_stoppoints_nogps = 0;
    this.total_transferpoints_nogps = 0;
    const o1 = this.data_service.getPunktZbiorkiAll$();
    const o2 = this.data_service.getPunktPrzesiadkiAll$();
    forkJoin([o1, o2]).subscribe(
      (data: any) => {
        if (this.filter_stoppoints) {
          for (let i = 0; i < data[0].length; i++) {
            const obj = StopPoint.createObject(data[0][i]);
            if (!obj.is_inne())
              this.points.push([obj, null]);
            if (!obj.has_gps_data())
              this.total_stoppoints_nogps++;
          }
        }
        if (this.filter_transferpoints) {
          for (let i = 0; i < data[1].length; i++) {
            const obj = TransferPoint.createObject(data[1][i]);
            this.points.push([null, obj]);
            if (!obj.has_gps_data())
              this.total_transferpoints_nogps++;
          }
        }
      },
      error => {
        this.apphelper.error_handler(error, 'PointsComponent.refresh_all');
      },
      () => {
        // tslint:disable-next-line:max-line-length
        this.refresh_map();
      }
    );
  }

  // ---------------------------------------------------------------------------
  // pos: Object { lat: 50.82744843143973, lng: 17.788191840809304 }
  public setPointPosition(pos: any) {
    this.modal.stop_points.length = 0;
    this.modal.transfer_points.length = 0;

    for (let i = 0; i < this.points.length; i++) {
      const obj = this.get_point_obj(i);
      if (obj instanceof StopPoint) {
        if (!obj.has_gps_data())
          this.modal.stop_points.push(obj);
      } else { // if (obj instanceof TransferPoint)
        if (!obj.has_gps_data())
          this.modal.transfer_points.push(obj);
      }
    }

    this.modal.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          if (this.modal.selected_radio === 'sp') {
            const idx = this.find_point_obj_idx(this.modal.selected_stoppoint_id, false, true);
            if (idx >= 0) {
              this.points[idx][0].gps_lat = pos.lat;
              this.points[idx][0].gps_lon = pos.lng;
              this.data_service.updateStopPoint$(this.points[idx][0]).subscribe(
                data => () => { },
                error => {
                  this.apphelper.error_handler(error, 'PointsComponent.setPointPosition #1');
                },
                () => {
                  this.refresh_map();
                }
              );
            }
          }
          else { // if (this.modal.selected_radio === 'tp') {
            const idx = this.find_point_obj_idx(this.modal.selected_transferpoint_id, true, false);
            if (idx >= 0) {
              this.points[idx][1].gps_lat = pos.lat;
              this.points[idx][1].gps_lon = pos.lng;
              this.data_service.updateTransferPoint$(this.points[idx][1]).subscribe(
                data => () => { },
                error => {
                  this.apphelper.error_handler(error, 'PointsComponent.setPointPOsition #2');
                },
                () => {
                  this.refresh_map();
                }
              );
            }
          }
        }
      },
      (cancel) => { }
    );
  }

  // ---------------------------------------------------------------------------
  private find_position_of_point(mapobj: any /* H.Map */, _id: number, _type: string): [string, string] | null {
    let ret = null;
    if (mapobj === undefined || mapobj === null)
      return ret;
    if (!(mapobj instanceof H.Map))
      return ret;
    const objs = mapobj.getObjects(true);
    for (let i = 0; i < objs.length; i++) {
      let ok = false;
      if (objs[i] instanceof H.map.Marker) {
        if (_type === 'stoppoint')
          ok = (Number(objs[i].id_stoppoint) === _id && objs[i].typename === _type);
        if (_type === 'transferpoint')
          ok = (Number(objs[i].id_transferpoint) === _id && objs[i].typename === _type);
      }
      if (ok) {
        ret = [`${objs[i].b.lat}`, `${objs[i].b.lng}`];
        break;
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public updateStopPointPosition(id: number): void {
    const idx = this.find_point_obj_idx(id, false, true);
    const gps = this.find_position_of_point(this.the_map, id, 'stoppoint');
    if (idx >= 0 && gps != null) {
      this.points[idx][0].gps_lat = gps[0];
      this.points[idx][0].gps_lon = gps[1];
      this.data_service.updateStopPoint$(this.points[idx][0]).subscribe(
        data => () => { },
        error => {
          this.apphelper.error_handler(error, 'PointsComponent.updateStopPosition');
        },
        () => () => { }
      );
    }
  }

  // ---------------------------------------------------------------------------
  public updateTransferPointPosition(id: number): void {
    const idx = this.find_point_obj_idx(id, true, false);
    const gps = this.find_position_of_point(this.the_map, id, 'transferpoint');
    if (idx >= 0 && gps != null) {
      this.points[idx][1].gps_lat = gps[0];
      this.points[idx][1].gps_lon = gps[1];
      this.data_service.updateTransferPoint$(this.points[idx][1]).subscribe(
        data => () => { },
        error => {
          this.apphelper.error_handler(error, 'PointsComponent.updateTransferPointPosition');
        },
        () => () => { }
      );
    }
  }

  // ---------------------------------------------------------------------------
  public doRefreshMap(): void {
    this.refresh_map();
  }

  // ---------------------------------------------------------------------------
  public onFilterChange() {
    this.refresh_all();
  }
}
