import { Injectable } from '@angular/core';

@Injectable()
export class SharedDataService {

  public user_name: string = '';
  public db_name: string = '';

  constructor() { }

}
