import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';

@Component({
  selector: 'app-modal-point-position',
  templateUrl: './modal-point-position.component.html',
  styleUrls: ['./modal-point-position.component.css']
})
export class ModalPointPositionComponent implements OnInit {

  closeResult: string;
  public selected_stoppoint_id = -1;
  public selected_transferpoint_id = -1;
  public selected_radio = 'sp';
  public stop_points: StopPoint[] = [];
  public transfer_points: TransferPoint[] = [];

  @ViewChild('content', { static: true }) content: any;

  constructor(private modal: NgbModal) { }

  private init_selected_ids(): void {
    if (this.stop_points.length > 0)
      this.selected_stoppoint_id = Number(this.stop_points[0].id);
    if (this.transfer_points.length > 0)
      this.selected_transferpoint_id = Number(this.transfer_points[0].id);
    if (this.stop_points.length > 0)
      this.selected_radio = 'sp';
    else
      this.selected_radio = 'tp';
  }

  public open_promise(): Promise<any> {
    this.init_selected_ids();
    return this.modal.open(this.content).result;
  }

  public open(): void {
    this.init_selected_ids();
    this.modal.open(this.content).result.then(
      (result) => { this.closeResult = `Closed with: ${result}`; console.log(this.closeResult); },
      (reason) => { console.log('Dismissed: ', this.getDismissReason(reason)); }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onChangeStopPoint(value: string): void {
    this.selected_stoppoint_id = Number(value);
  }

  public onChangeTransferPoint(value: string): void {
    this.selected_transferpoint_id = Number(value);
  }

  public onRadioChange(value: string): void {
    this.selected_radio = value;
  }

  public attr_disabled(val: string): string {
    return this.selected_radio === val ? '' : 'disabled';
  }

  public has_stoppoints(): boolean {
    return this.stop_points.length > 0;
  }

  public has_transferpoints(): boolean {
    return this.transfer_points.length > 0;
  }

  public selected_sp(): boolean {
    return this.selected_radio === 'sp';
  }

  public selected_tp(): boolean {
    return this.selected_radio === 'tp';
  }


  ngOnInit() {
  }

}
