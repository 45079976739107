<!-- based on samples:
  http://plnkr.co/edit/wKsmGJe4yIZEwZ31gM0B?p=preview
  http://embed.plnkr.co/vonFT19P1Y8UDr9EghB8/
-->
<div class="input-group">
  <input class="form-control form-control-sm" placeholder="rrrr-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
    #d="ngbDatepicker" (ngModelChange)="onDateChange($event)">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary btn-sm" (click)="d.toggle()" type="button" title="Pokaż kalendarz">
      <img src="/assets/calendar-icon.svg" style="width: 1rem; cursor: pointer;" />
    </button>
  </div>
  <div class="input-group-append">
    <button class="btn btn-outline-secondary btn-sm" (click)="select_today()" type="button" title="Dzisiaj">
      <img src="/assets/calendar-today.svg" style="width: 1rem; cursor: pointer;" />
    </button>
  </div>
</div>