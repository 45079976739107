import { Person } from './person';
import { CarTask } from './car-task';
import { AppHelperService } from './_services/app-helper.service';

export class Address {
    fulltext: string;
    country: string;
    country_code: string;
    city: string;
    street: string;
    house_number: string;
    postal_code: string;

    // --------------------------------------------------------------------------
    constructor(private apphelper: AppHelperService) {
        this.reset();
    }

    // --------------------------------------------------------------------------
    public reset() {
        this.fulltext = '';
        this.country = '';
        this.country_code = this.apphelper.get_country_isocode(-1);
        this.city = '';
        this.street = '';
        this.house_number = '';
        this.postal_code = '';
    }

    // --------------------------------------------------------------------------
    public from_person(p: Person, samtyp: number) {
        this.reset();
        this.fulltext = this.apphelper.get_person_address_geo(p, samtyp);
        this.country = p.adres_kraj;
        this.country_code = this.apphelper.get_country_isocode(p.adres_kraj_id);
        this.city = p.adres_miejsc;
        this.street = p.adres_ulica;
        this.house_number = p.adres_nrdomu;
        this.postal_code = p.adres_kodpoczt;
    }

    // --------------------------------------------------------------------------
    public from_cartask(ct: CarTask) {
        this.reset();
        this.fulltext = ct.address;
    }

    // --------------------------------------------------------------------------
    public has_structured_address() {
        let ret = false;
        if (
            this.postal_code.length > 0 &&
            this.city.length > 0 &&
            // this.street.length > 0 &&
            this.country.length > 0
        )
            ret = true;
        return ret;
    }

    // --------------------------------------------------------------------------
    public filter_var_address(val: string): string {
        let ret = val;
        // usuwam sredniki
        while (ret.includes(";"))
            ret = ret.replace(";", ",");
        // usuwam \,
        ret = ret.replace("\\,", ",");
        ret = ret.trim();
        return ret;
    }

    // --------------------------------------------------------------------------
    public filter_var_housenumber(val: string): string {
        let ret = val;
        let idx = ret.indexOf("/");
        if (idx != -1)
            ret = ret.slice(0, idx);
        idx = ret.indexOf("\\");
        if (idx != -1)
            ret = ret.slice(0, idx);
        idx = ret.search(/[^\d]/);
        if (idx != -1)
            ret = ret.slice(0, idx);
        ret = ret.trim();
        return ret;
    }

    // --------------------------------------------------------------------------
    public remove_wojewodztwo(val: string): string {
        const arr_woj = [
            "woj. dolnośląskie",
            "woj. kujawsko-pomorskie",
            "woj. lubelskie",
            "woj. lubuskie",
            "woj. łódzkie",
            "woj. małopolskie",
            "woj. mazowieckie",
            "woj. opolskie",
            "woj. podkarpackie",
            "woj. podlaskie",
            "woj. pomorskie",
            "woj. śląskie",
            "woj. świętokrzyskie",
            "woj. warmińsko-mazurskie",
            "woj. wielkopolskie",
            "woj. zachodniopomorskie"
        ];
        let ret = val;
        const lower_val = val.toLocaleLowerCase();
        for (let i = 0; i < arr_woj.length; i++) {
            const cur_woj = arr_woj[i];
            const woj_idx = lower_val.indexOf(cur_woj);
            if (woj_idx != -1) {
                const woj_len = cur_woj.length;
                const woj_name = ret.substring(woj_idx, woj_idx + woj_len);
                ret = ret.replace(woj_name, "");
                ret = ret.trim();
                break;
            }
        }
        return ret;
    }

    // --------------------------------------------------------------------------
    // fields recognized by HERE API: country, state, county, city, district, street, houseNumber, postalCode.
    // https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html#/paths/~1geocode/get
    public get_structured_address() {
        let arr_addr = []

        const s_postalcode = this.filter_var_address(this.postal_code);
        if (s_postalcode.length > 0) {
            arr_addr.push(`postalCode=${s_postalcode}`);
        }

        let s_city = this.filter_var_address(this.city);
        s_city = this.remove_wojewodztwo(s_city);
        if (s_city.length > 0) {
            arr_addr.push(`city=${s_city}`);
        }

        const s_street = this.filter_var_address(this.street);
        if (s_street.length > 0) {
            arr_addr.push(`street=${s_street}`);
        }

        let s_houseno = this.filter_var_address(this.house_number);
        s_houseno = this.filter_var_housenumber(s_houseno);
        if (this.house_number.length > 0) {
            arr_addr.push(`houseNumber=${s_houseno}`);
        }

        // zakomentowane w wersji 3.2.0, kraj wysylam w osobnym polu zapytania(in:countryCode)
        // if (this.country.length > 0)
        //     arr_addr.push("country=" + this.country);
        return arr_addr.join(";");
    }

    // --------------------------------------------------------------------------    
    public create_fulltext_address() {
        let ret = ''

        let s_code = this.filter_var_address(this.postal_code);
        if (s_code.length > 0)
            ret += s_code;

        if (ret.length > 0)
            ret += " ";

        let s_city = this.filter_var_address(this.city);
        s_city = this.remove_wojewodztwo(s_city);
        if (s_city.length > 0)
            ret += s_city;

        if (ret.length > 0)
            ret += ', ';

        let s_street = this.filter_var_address(this.street);
        if (s_street.length > 0)
            ret += s_street;

        if (ret.length > 0)
            ret += " ";

        let s_houseno = this.filter_var_address(this.house_number);
        s_houseno = this.filter_var_housenumber(s_houseno);
        if (s_houseno.length > 0)
            ret += s_houseno;

        // zakomentowane w wersji 3.2.0, kraj wysylam w osobnym polu zapytania(in:countryCode)
        // if (ret.length > 0)
        //     ret += ', ';
        // let s_country = this.filter_var_address(this.country);
        // if (s_country.length > 0)
        //     ret += s_country;

        return ret;
    }

    // --------------------------------------------------------------------------
    public dump(): string {
        const obj = {
            fulltext: this.fulltext,
            country: this.country,
            country_code: this.country_code,
            city: this.city,
            street: this.street,
            house_number: this.house_number,
            postal_code: this.postal_code
        };
        return JSON.stringify(obj);
    }
}
