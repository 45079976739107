import { from, forkJoin, Observable, Observer } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, AfterViewInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Car } from '../car';
import { Person } from '../person';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';
import { CarTask } from '../car-task';
import { MapPoint } from '../map-point';
import { DataService } from '../_services/data.service';
import { HereMapsService } from '../_services/heremaps.service';
import { AppHelperService } from '../_services/app-helper.service';
import { ModalChangeCarComponent } from '../modal-change-car/modal-change-car.component';
import { ModalMapPointPositionComponent } from '../modal-map-point-position/modal-map-point-position.component';
import { ModalEditCarTaskComponent } from '../modal-edit-car-task/modal-edit-car-task.component';
import { ModalChangeColourComponent } from '../modal-change-colour/modal-change-colour.component';
import { environment } from '../../environments/environment';
import { Address } from '../address';

declare var H: any;

const now = new Date();

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modalChangeCar', { static: true }) modal: ModalChangeCarComponent;
  @ViewChild('modalMapPointPosition', { static: true }) modal2: ModalMapPointPositionComponent;
  @ViewChild('modalEditCarTask', { static: true }) modal3: ModalEditCarTaskComponent;
  @ViewChild('modalChangeColour', { static: true }) modal_chg_colour: ModalChangeColourComponent;

  public filter_date: any = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  public filter_wyjpow = 1; // 0 - powrot po pol, 1 - wyj po pol, 2 - wyj przed pol, 3 - powrot przed pol
  public filter_auto = 1;
  public filter_sam = 1; // 1 - dowozacy, 2 - docelowy, 3 - rozwozacy

  public visible_stop_start = false;
  public visible_start_stop = false;

  public cars: Car[] = []; // should be std::map
  public map_cars = {};
  public stop_points: StopPoint[] = []; // should be std::map
  public transfer_points: TransferPoint[] = []; // should be std::map
  public cartask_start: CarTask = null;
  public cartask_stop: CarTask = null;
  public total_persons = 0;
  public route_distance = '';
  public route_time = '';
  public route_time_2 = '';
  public route_map: any = null;
  public route_map_params: any = null;

  public mappoints: MapPoint[] = [];

  private the_map: any = null;

  public selected_person_name = '';
  public selected_person_car = '';
  public selected_cartask_name = '';
  private route_has_params = false;
  public last_sel_car_id = -1;
  public use_traffic_time = true;

  public restore_zoom_on_refresh = false;
  public css_colors: string[] = [];

  public label_start_stop_date = '';
  public start_stop_date = '';

  // ---------------------------------------------------------------------------
  constructor(
    private cd: ChangeDetectorRef,
    private data_service: DataService,
    private maps_service: HereMapsService,
    private apphelper: AppHelperService,
    private _ngZone: NgZone,
    public router: Router,
    private route: ActivatedRoute) {

    window['angularComponentRef'] = {
      zone: this._ngZone,
      updateMapPointPositionFn: (val1, val2) => this.updateMapPointPosition(val1, val2),
      changePersonCarFn: (value) => this.changePersonCar(value),
      changeAllPersonsCarFn: (value) => this.changeAllPersonsCar(value),
      changePersonColourFn: (value) => this.changePersonColour(value),
      changeAllPersonsColourFn: (value) => this.changeAllPersonsColour(value),
      component: this
    };

    this.css_colors = this.apphelper.get_css_colours();
  }

  // ---------------------------------------------------------------------------
  public ngOnInit() {
    this.cars.length = 0;
    this.transfer_points.length = 0;
    this.stop_points.length = 0;
    this.route_map = null;
    this.route_map_params = null;
    this.map_cars = {};

    const param_data = this.route.snapshot.paramMap.get('data');
    const param_wyjpow = this.route.snapshot.paramMap.get('wyjpow');
    const param_carid = this.route.snapshot.paramMap.get('car_id');
    const param_samtyp = this.route.snapshot.paramMap.get('samtyp');

    this.route_has_params = (param_data != null) && (param_wyjpow != null)
      && (param_carid != null) && (param_samtyp != null);
    if (this.route_has_params) {
      this.filter_date = this.apphelper.get_filter_date_from_string(param_data);
      this.filter_wyjpow = Number(param_wyjpow);
      this.filter_auto = Number(param_carid);
      this.filter_sam = Number(param_samtyp);
    }
    else {
      if (sessionStorage.getItem('filter_date'))
        this.filter_date = JSON.parse(sessionStorage.getItem('filter_date'));
      else
        this.filter_date = this.apphelper.get_filter_date_from_date(new Date());

      if (sessionStorage.getItem('filter_wyjpow'))
        this.filter_wyjpow = Number(sessionStorage.getItem('filter_wyjpow'));

      if (sessionStorage.getItem('filter_sam'))
        this.filter_sam = Number(sessionStorage.getItem('filter_sam'));
    }

    const o1 = this.data_service.getPunktPrzesiadkiAll$();
    const o2 = this.data_service.getPunktZbiorkiAll$();
    const o3 = this.data_service.getCarsAll$();

    forkJoin([o1, o2, o3]).subscribe(
      (data: any) => {
        this.transfer_points = data[0].map((obj: any) => TransferPoint.createObject(obj));
        this.stop_points = data[1].map((obj: any) => StopPoint.createObject(obj));
        this.cars = data[2].map((obj: any) => Car.createObject(obj)); // uzywam this.cars jako temp arr
      },
      error => {
        this.apphelper.error_handler(error, 'DetailComponent::ngOnInit');
      },
      () => {
        for (const o of this.cars)
          this.map_cars[o.id] = o;
      }
    );
  }

  // ---------------------------------------------------------------------------
  public ngAfterViewInit() {
    this.the_map = this.maps_service.createHereMap();
    this.the_map.addEventListener('tap', (evt: any) => {  //  function (evt) {
      const target = evt.target;
      const pointer = evt.currentPointer;
      if (target instanceof H.Map) {
        const pos = this.the_map.screenToGeo(pointer.viewportX, pointer.viewportY);
        this.setMapPointPosition(pos);
      }
    }, false);

    if (this.route_has_params)
      this.showData();
    this.cd.detectChanges();
  }

  // ---------------------------------------------------------------------------
  public ngOnDestroy() {
    window['angularComponentRef'] = null;
  }

  // ---------------------------------------------------------------------------
  public onNotify(date): void {
    this.filter_date = date;
    sessionStorage.setItem('filter_date', JSON.stringify(this.filter_date));
  }

  // ---------------------------------------------------------------------------
  public onChangeSam(val): void {
    this.filter_sam = Number(val);
    sessionStorage.setItem('filter_sam', this.filter_sam.toString());
  }

  // ---------------------------------------------------------------------------
  public onChangeWyjPow(val): void {
    this.filter_wyjpow = Number(val);
    sessionStorage.setItem('filter_wyjpow', this.filter_wyjpow.toString());
  }

  // ---------------------------------------------------------------------------
  public onChangeStartStopDate(val): void {
    this.start_stop_date = val;
  }

  // ---------------------------------------------------------------------------
  private clear_map(): void {
    this.maps_service.clearHereMap(this.the_map);
    this.route_map = null;
    this.route_map_params = null;
  }

  // ---------------------------------------------------------------------------
  private refresh_totals(): void {
    let total = 0;
    for (const mp of this.mappoints)
      total += mp.persons.length;
    this.total_persons = total;
  }

  // ---------------------------------------------------------------------------
  private get_persons$(): Observable<any> {
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_auto = Number(this.filter_auto);
    this.filter_sam = Number(this.filter_sam);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    const _samtyp = Number(this.filter_sam);
    const _id_auto = Number(this.filter_auto);
    return this.data_service.getPersons$(_date, _powrot, _porawyj, '', _samtyp, _id_auto);
  }

  // ---------------------------------------------------------------------------
  private get_cartasks$(): Observable<any> {
    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_auto = Number(this.filter_auto);
    this.filter_sam = Number(this.filter_sam);
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    const _samtyp = Number(this.filter_sam);
    const _id_auto = Number(this.filter_auto);
    return this.data_service.getCarTasks$(_date, _powrot, _porawyj, _samtyp, _id_auto);
  }

  // ---------------------------------------------------------------------------
  private save_persons(data: any, map_persons: any, samtyp: number) {
    for (const o of data) {
      const obj = Person.createObject(o, samtyp);
      map_persons[obj.id] = obj;
    }
  }

  // ---------------------------------------------------------------------------
  private save_cartasks(data: any, map_cartasks: any) {
    for (const o of data) {
      const obj = CarTask.createObject(o);
      if (obj.is_start())
        this.cartask_start = obj;
      if (obj.is_stop())
        this.cartask_stop = obj;
      map_cartasks[obj.id] = obj;
    }
  }

  // ---------------------------------------------------------------------------
  private get_geocoding_data$(map_persons: any, map_cartasks: any, persons_nogps: number[],
    cartasks_nogps: number[]): Observable<any[]> {
    const observ_objs: Observable<any>[] = [];
    for (let i = 0; i < persons_nogps.length; i++) {
      const p = map_persons[persons_nogps[i]];
      if (p) {
        let addr = new Address(this.apphelper);
        addr.from_person(p, this.filter_sam);
        const o = this.maps_service.geocodeObservable$(addr);
        if (o)
          observ_objs.push(o);
      }
    }
    for (let i = 0; i < cartasks_nogps.length; i++) {
      const ct = map_cartasks[cartasks_nogps[i]];
      if (ct) {
        let addr = new Address(this.apphelper);
        addr.from_cartask(ct);
        const o = this.maps_service.geocodeObservable$(addr);
        if (o)
          observ_objs.push(o);
      }
    }
    if (observ_objs.length !== (persons_nogps.length + cartasks_nogps.length))
      console.log('DetailComponent.getGeocodingData$: some observables were not created.');
    return forkJoin(observ_objs);
  }

  // ---------------------------------------------------------------------------
  private save_geocoding_data(data: any[], map_persons: any, map_cartasks: any,
    persons_nogps: number[], cartasks_nogps: number[],
    persons_updatedb: number[], cartasks_updatedb: number[]): void {
    for (let i = 0; i < data.length; i++) {
      const pos = this.maps_service.geocodeResult(data[i]);
      if (i < persons_nogps.length) {
        const p = map_persons[persons_nogps[i]];
        if (pos && p && this.apphelper.postal_code_matches(p, pos)) {
          p.gps_lat = pos.Latitude;
          p.gps_lon = pos.Longitude;
          p.rejon = pos.State;
          p.kraj = pos.Country;
          persons_updatedb.push(p.id);
        }
      }
      else {
        const j = i - persons_nogps.length;
        const ct = map_cartasks[cartasks_nogps[j]];
        if (pos && ct) {
          ct.gps_lat = pos.Latitude;
          ct.gps_lon = pos.Longitude;
          ct.rejon = pos.State;
          ct.kraj = pos.Country;
          cartasks_updatedb.push(ct.id);
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  private refresh_map_objects(): void {
    const _map_zoom = this.the_map.getZoom();
    const _map_center = this.the_map.getCenter();
    this.clear_map();

    const mappoints_extra: MapPoint[] = [];
    if (this.cartask_start) {
      const mp = new MapPoint(this.apphelper);
      mp.add_cartask(this.cartask_start);
      mappoints_extra.push(mp);
    }
    if (this.cartask_stop) {
      const mp = new MapPoint(this.apphelper);
      mp.add_cartask(this.cartask_stop);
      mappoints_extra.push(mp);
    }

    const marker_objs: any[] = this.apphelper.prepare_map_markers(this.maps_service,
      this.mappoints, this.map_cars, this.filter_sam, 2);
    for (const mo of marker_objs)
      mo.setZIndex(5); // pinezka ma byc wyzej od flagi start/stop, jesli na siebie najda
    const marker_objs_extra: any[] = this.apphelper.prepare_map_markers(this.maps_service,
      mappoints_extra, this.map_cars, this.filter_sam, 2);
    for (const mox of marker_objs_extra)
      mox.setZIndex(3); // pinezka ma byc wyzej od flagi start/stop, jesli na siebie najda
    for (const o of marker_objs_extra)
      marker_objs.push(o);

    if (marker_objs && marker_objs.length > 0) {
      const marker_group: any = this.maps_service.marker_group_create(marker_objs);
      if (marker_group) {
        this.the_map.addObject(marker_group);
        if (this.restore_zoom_on_refresh) {
          this.the_map.setZoom(_map_zoom);
          this.the_map.setCenter(_map_center, false);
        }
        else {
          this.the_map.getViewModel().setLookAtData({ bounds: marker_group.getBoundingBox() });
          this.restore_zoom_on_refresh = true;
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  private refresh_error_info(errorlog: string[]): void {
    for (let i = 0; i < errorlog.length; i++)
      console.log(errorlog[i]);
  }

  // ---------------------------------------------------------------------------
  public showData(): void {
    this.cartask_start = null;
    this.cartask_stop = null;
    this.mappoints = [];
    this.cars.length = 0;
    this.route_distance = '';
    this.route_time = '';

    const map_persons: any = {}; // std::map kind: map_persons[person.id] = person_object
    const map_cartasks: any = {}; // std::map kind: map_cartasks[cartask.id] = cartask_object
    const persons_nogps: number[] = []; // array of Person.id without GPS data
    const persons_updatedb: number[] = []; // array of Person.id that should update GPS to database
    const cartasks_nogps: number[] = []; // array of CarTask.id without GPS data
    const cartasks_updatedb: number[] = []; // array of CarTask.id that should update GPS to database
    const errorlog: string[] = [];

    this.filter_wyjpow = Number(this.filter_wyjpow);
    this.filter_auto = Number(this.filter_auto);
    this.filter_sam = Number(this.filter_sam);
    const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
    const _porawyj = this.apphelper.get_porawyj(this.filter_wyjpow);
    this.visible_start_stop = this.apphelper.is_start_stop_visible(_powrot == 1, this.filter_sam);
    this.visible_stop_start = this.apphelper.is_stop_start_visible(_powrot == 1, this.filter_sam);
    this.label_start_stop_date = this.visible_start_stop ? "START" : "STOP";
    const _date = this.apphelper.get_date_from_filter(this.filter_date);
    const _samtyp = Number(this.filter_sam);

    const o1 = this.data_service.getCars$(_date, _powrot, _porawyj, _samtyp);
    const o2 = this.get_persons$();
    const o3 = this.get_cartasks$();
    forkJoin([o1, o2, o3]).pipe(
      mergeMap((data: any) => {
        this.cars = data[0].map((obj: any) => Car.createObject(obj));
        this.save_persons(data[1], map_persons, _samtyp);
        this.save_cartasks(data[2], map_cartasks);
        this.apphelper.prepare_persons_for_geocoding(map_persons, this.stop_points, _powrot, _samtyp,
          persons_nogps, persons_updatedb, errorlog);
        this.apphelper.prepare_cartasks_for_geocoding(map_cartasks, this.stop_points, _powrot, _samtyp,
          cartasks_nogps, cartasks_updatedb, errorlog);
        return this.get_geocoding_data$(map_persons, map_cartasks, persons_nogps, cartasks_nogps);
      }))
      .subscribe(
        data => {
          this.save_geocoding_data(data, map_persons, map_cartasks, persons_nogps, cartasks_nogps, persons_updatedb, cartasks_updatedb);
        },
        error => {
          this.apphelper.error_handler(error, 'DetailComponent.showData');
        },
        () => {
          this.mappoints = this.apphelper.prepare_mappoints(map_persons, map_cartasks, _samtyp, _powrot == 1, errorlog, true);
          this.refresh_map_objects();
          this.refresh_totals();
          this.refresh_error_info(errorlog);
          this.apphelper.update_gps_and_address_state_to_db(this.data_service, _samtyp, map_persons, map_cartasks, persons_updatedb, cartasks_updatedb);
          this.setStartStopDate(_date, _powrot, _samtyp, _porawyj, this.cartask_start, this.cartask_stop);
          this.saveChanges(); // w this.apphelper.prepare_mappoints mogla zmienic sie kolejnosc na rozpisce i trzeba to zapisac
        }
      );
  }

  // ---------------------------------------------------------------------------
  public updateMapPointPosition(arr_id_person: number[], arr_id_cartask: number[]): void {
    const observ_objs: Observable<any>[] = [];
    for (let i = 0; i < arr_id_person.length; i++) {
      const p_id = arr_id_person[i];
      const gps = this.apphelper.find_position_of_person(this.the_map, p_id);
      const p: Person = this.apphelper.get_person_object(this.mappoints, p_id);
      if (p && gps) {
        p.gps_lat = gps[0];
        p.gps_lon = gps[1];
        const o = this.data_service.updatePerson$(p, this.filter_sam);
        if (o)
          observ_objs.push(o);
        else
          console.log('DetailComponent.updateMapPointPosition: null observable got from this.data_service.updatePerson of id', p.id);
      }
    }
    for (let i = 0; i < arr_id_cartask.length; i++) {
      const ct_id = arr_id_cartask[i];
      const gps = this.apphelper.find_position_of_cartask(this.the_map, ct_id);
      const ct: CarTask = this.apphelper.get_cartask_object(this.mappoints, this.cartask_start, this.cartask_stop, ct_id);
      if (ct && gps) {
        ct.gps_lat = gps[0];
        ct.gps_lon = gps[1];
        const o = this.data_service.updateCarTask$(ct);
        if (o)
          observ_objs.push(o);
        else
          console.log('DetailComponent.updateMapPointPosition: null observable got from this.data_service.updateCarTask of id', ct.id);
      }
    }
    forkJoin(observ_objs).subscribe(
      data => { },
      error => {
        this.apphelper.error_handler(error, 'DetailComponent.updateMapPointPosition');
      },
      () => {
        const mp = this.apphelper.get_mappoint_object(this.mappoints, arr_id_person, arr_id_cartask);
        if (mp)
          mp.update_all();
      }
    );
  }

  // ---------------------------------------------------------------------------
  public changePersonCar(id_person: number) {
    const p: Person = this.apphelper.get_person_object(this.mappoints, id_person);
    if (p) {
      this.selected_person_name = `${p.nazwisko} ${p.imie}`;
      const id_auto = p.id_auto;
      this.selected_person_car = this.apphelper.get_car_name(this.cars, id_auto);
      this.last_sel_car_id = this.apphelper.getLastSelectedCarId();
      this.modal.open_promise().then(
        (submit) => {
          if (submit === 'save-changes') {
            p.id_auto = Number(this.modal.selected_car_id);
            this.apphelper.setLastSelectedCarId(p.id_auto);
            this.data_service.updatePerson$(p, this.filter_sam).subscribe(
              data => () => { },
              error => {
                this.apphelper.error_handler(error, 'DetailComponent.changePersonCar');
              },
              () => {
                this.showData();
              }
            );
          }
        },
        (cancel) => { /* console.log('cancelled with ', cancel); */ }
      );
    }
  }

  // ---------------------------------------------------------------------------
  public changePersonColour(id_person: number) {
    const p: Person = this.apphelper.get_person_object(this.mappoints, id_person);
    if (p) {
      this.selected_person_name = `${p.nazwisko} ${p.imie}`;
      let sel_color = p.pin_kolor && p.pin_kolor.length > 0 ? p.pin_kolor : 'blue';
      this.modal_chg_colour.selected_colour = sel_color;
      this.modal_chg_colour.open_promise().then(
        (submit) => {
          if (submit === 'save-changes') {
            console.log('color changed to ', this.modal_chg_colour.selected_colour);
            const post_data = { PIN_KOLOR: this.modal_chg_colour.selected_colour };
            this.data_service.updatePersonData$(p, post_data).subscribe(
              data => () => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changePersonCarColour');
              },
              () => { this.showData(); }
            );
          }
        },
        (cancel) => { /* console.log('cancelled with ', cancel); */ }
      );
    }
  }


  // ---------------------------------------------------------------------------
  public changeAllPersonsCar(arr_id_person: number[]) {
    this.selected_person_name = 'Zaznaczone osoby';
    this.selected_person_car = 'Zaznaczone samochody';
    this.last_sel_car_id = this.apphelper.getLastSelectedCarId();
    this.modal.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const selected_id_auto = Number(this.modal.selected_car_id);
          this.apphelper.setLastSelectedCarId(selected_id_auto);
          const arr_objs: Observable<any>[] = [];
          for (let i = 0; i < arr_id_person.length; i++) {
            const p: Person = this.apphelper.get_person_object(this.mappoints, arr_id_person[i]);
            if (p) {
              p.id_auto = selected_id_auto;
              const o = this.data_service.updatePerson$(p, this.filter_sam);
              arr_objs.push(o);
            }
          }
          if (arr_objs.length > 0) {
            forkJoin(arr_objs).subscribe(
              data => () => { },
              error => {
                this.apphelper.error_handler(error, 'DetailComponent.changeAllPersonsCar');
              },
              () => { this.showData(); }
            );
          }
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  public changeAllPersonsColour(arr_id_person: number[]) {
    let sel_color = 'blue';
    this.modal_chg_colour.selected_colour = sel_color;
    this.modal_chg_colour.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          const arr_objs: Observable<any>[] = [];
          for (let i = 0; i < arr_id_person.length; i++) {
            const p: Person = this.apphelper.get_person_object(this.mappoints, arr_id_person[i]);
            if (p) {
              const post_data = { PIN_KOLOR: this.modal_chg_colour.selected_colour };
              const o = this.data_service.updatePersonData$(p, post_data);
              arr_objs.push(o);
            }
          }
          if (arr_objs.length > 0) {
            forkJoin(arr_objs).subscribe(
              data => { },
              error => {
                this.apphelper.error_handler(error, 'MasterComponent::changeAllPersonsColour');
              },
              () => {
                this.showData();
              }
            );
          }
        }
      },
      (cancel) => { /* console.log('cancelled with ', cancel); */ }
    );
  }

  // ---------------------------------------------------------------------------
  private get_stoppoint_by_id(id_stoppoint: number): StopPoint | null {
    if (this.stop_points && id_stoppoint) {
      const id = Number(id_stoppoint);
      for (const sp of this.stop_points) {
        if (Number(sp.id) === id)
          return sp;
      }
    }
    return null;
  }

  // ---------------------------------------------------------------------------
  private get_transferpoint_by_id(id_transferpoint: number): TransferPoint | null {
    if (this.transfer_points && id_transferpoint) {
      const id = Number(id_transferpoint);
      for (const tp of this.transfer_points) {
        if (Number(tp.id) === id)
          return tp;
      }
    }
    return null;
  }

  // ---------------------------------------------------------------------------
  public editCarTask(id_cartask) {
    let ct: CarTask = this.apphelper.get_cartask_object(this.mappoints, this.cartask_start, this.cartask_stop, id_cartask);
    if (ct) {
      this.selected_cartask_name = ct.zadanie_info;
      this.modal3.open_promise().then(
        (submit) => {
          if (submit === 'save-changes') {
            let ok = false;
            if (this.modal3.selected_sp()) {
              const sp = this.get_stoppoint_by_id(this.modal3.selected_stoppoint_id);
              if (sp) {
                ct.address = sp.nazwa;
                ct.gps_lat = sp.gps_lat;
                ct.gps_lon = sp.gps_lon;
                ok = true;
              }
            }
            else if (this.modal3.selected_tp()) {
              const tp = this.get_transferpoint_by_id(this.modal3.selected_transferpoint_id);
              if (tp) {
                ct.address = tp.nazwa;
                ct.gps_lat = tp.gps_lat;
                ct.gps_lon = tp.gps_lon;
                ok = true;
              }
            }
            else if (this.modal3.selected_ap()) {
              ct.address = this.modal3.selected_address;
              ct.gps_lat = '';
              ct.gps_lon = '';
              ok = true;
              //const o = from(this.maps_service.geocodePromise(ct.address.trimEnd()));
              // wyznaczenie wspolrzednych GPS nastapi podczas nastepnego wywolania showData
            }
            else {
            }

            if (ok) {
              this.data_service.updateCarTask$(ct)
                .subscribe(
                  data => {
                  },
                  error => {
                    this.apphelper.error_handler(error, 'DetailComponent.editCarTask');
                  },
                  () => {
                    this.showData();
                  }
                );
            }
          }
        },
        (cancel) => { /* console.log('cancelled with ', cancel); */ }
      );
    }
  }

  // ---------------------------------------------------------------------------
  private prepareWayPoints(): [string, string][] {
    const ret: [string, string][] = [];

    if (this.cartask_start && this.cartask_start.has_gps_data())
      ret.push([this.cartask_start.gps_lat, this.cartask_start.gps_lon]);

    for (let i = 0; i < this.mappoints.length; i++) {
      const mp = this.mappoints[i];
      if (mp.has_gps_data())
        ret.push([mp.gps_lat, mp.gps_lon]);
    }

    if (this.cartask_stop && this.cartask_stop.has_gps_data())
      ret.push([this.cartask_stop.gps_lat, this.cartask_stop.gps_lon]);

    return ret;
  }

  // ---------------------------------------------------------------------------
  public onDrop(person: Person, position: number): void {
    for (let i = 0; i < this.mappoints.length; i++)
      this.mappoints[i].lp_rozp = i + 1;
    this.refresh_map_objects();
    if (this.show_stop_start())
      this.redrawRouteBackward();
    else
      this.redrawRouteForward();
  }

  // ---------------------------------------------------------------------------
  public saveChanges(): void {
    const observ_objs: Observable<any>[] = [];

    let num = 0;
    if (this.cartask_start) {
      this.cartask_start.lp_rozp = num++;
      observ_objs.push(this.data_service.updateCarTask$(this.cartask_start));
    }

    for (let i = 0; i < this.mappoints.length; i++) {
      const mp = this.mappoints[i];
      mp.lp_rozp = num++;
      for (const p of mp.persons) {
        p.lp_rozp = mp.lp_rozp;
        observ_objs.push(this.data_service.updatePerson$(p, this.filter_sam));
      }
      for (const ct of mp.cartasks) {
        ct.lp_rozp = mp.lp_rozp;
        observ_objs.push(this.data_service.updateCarTask$(ct));
      }
    }

    if (this.cartask_stop) {
      this.cartask_stop.lp_rozp = num;
      observ_objs.push(this.data_service.updateCarTask$(this.cartask_stop));
    }

    if (this.route_map) {
      const _date = this.apphelper.get_date_from_filter(this.filter_date);
      const _powrot = this.apphelper.get_powrot(this.filter_wyjpow);
      const _samtyp = Number(this.filter_sam);
      const _id_auto = Number(this.filter_auto);
      const _json_route = JSON.stringify(this.route_map);
      const _json_params = JSON.stringify(this.route_map_params);
      let _json_distance = 0.0;
      let _route_summary = this.getRouteSummary(this.route_map);
      if (_route_summary.route_distance) {
        const _kilometers = Number(_route_summary.route_distance) / 1000.0;
        _json_distance = Number(_kilometers.toFixed(0));
      }
      observ_objs.push(this.data_service.updateRoute$(_date, _powrot, _samtyp, _id_auto,
        _json_route, _json_params, _json_distance));
    }

    if (observ_objs.length > 0) {
      forkJoin(observ_objs).subscribe(
        data => () => { },
        error => {
          this.apphelper.error_handler(error, 'DetailComponent::saveChanges');
        },
        () => { }
      );
    }
  }

  // ---------------------------------------------------------------------------
  private get_stoptime_seconds(persons_count: number, one_person_time: number, min_time: number): number {
    const count = Math.floor(persons_count * one_person_time);
    let ret = count < min_time ? min_time : count;
    return Math.floor(ret * 60);
  }

  // ---------------------------------------------------------------------------
  private get_section_duration(section_summary: any, use_traffic: boolean): number {
    return use_traffic ? section_summary.typicalDuration : section_summary.baseDuration;
  }

  // ---------------------------------------------------------------------------
  private round_moment_down(mom: any, round_to_minutes: number): any {
    if (round_to_minutes <= 0 || round_to_minutes >= 60) {
      console.log(`DetailComponent.round_time_down: parameter num_minutes out of range (0, 60): ${round_to_minutes}`);
      return mom;
    }
    const minutes_round_intval = Math.floor(round_to_minutes);
    let rounded_minutes = Math.floor(mom.minutes() / minutes_round_intval) * minutes_round_intval;
    mom.minutes(rounded_minutes);
    return mom;
  }

  // ---------------------------------------------------------------------------
  private is_selected_car_virtual(): boolean {
    const obj = this.map_cars[this.filter_auto];
    return obj ? obj.is_virtual : false;
  }

  // ---------------------------------------------------------------------------
  private update_cartask(cartask: CarTask, mom: moment.Moment) {
    if (!this.is_selected_car_virtual())
      cartask.godz = mom.format('HH:mm');
    cartask.czas_2 = mom.format('YYYY-MM-DD HH:mm:00');
    cartask.refresh_time_props();
  }

  // ---------------------------------------------------------------------------
  private update_person(person: Person, mom: moment.Moment, odb_doc: number) {
    if (!this.is_selected_car_virtual())
      if (odb_doc == 1 || (odb_doc == 2 && person.id_auto_dow <= 1))
        person.godz_sug = mom.format('HH:mm');
    person.czas_2 = mom.format('YYYY-MM-DD HH:mm:00');
  }

  // ---------------------------------------------------------------------------
  private updateMappointTimes(route: any, use_traffic: boolean, backwards: boolean) {
    if (!route || !route.sections) {
      console.log('DetailComponent.updateMappointTimes: invalid input data');
      return;
    }

    if (!(this.cartask_start && this.cartask_stop)) {
      console.log('DetailComponent.updateMappointTimes: no START and STOP tasks');
      return;
    }

    const sections_count = route.sections.length;
    const points_count = this.mappoints.length + 2; // + start + stop
    if (points_count !== sections_count + 1) { // ilosc punktow jest o 1 wieksza niz odcinkow miedzy nimi
      console.log('DetailComponent.updateMappointTimes: invalid number of sections');
      return;
    }

    if (backwards)
      this.updateMappointTimesBackward(route, use_traffic);
    else
      this.updateMappointTimesForward(route, use_traffic);

    // diagnostyka, docelowo do wyciecia
    if (!environment.production) {
      console.log('DetailComponent.updateMappointTimes: route param');
      for (let i = 0; i < route.sections.length; i++) {
        const section_obj = route.sections[i];
        let num = i;
        let arr_time = section_obj.arrival.time;
        let dep_time = section_obj.departure.time;
        let dur_duration = moment.duration(section_obj.summary.duration, 'seconds').toISOString();
        let dur_baseduration = moment.duration(section_obj.summary.baseDuration, 'seconds').toISOString();
        let dur_typDuration = moment.duration(section_obj.summary.typicalDuration, 'seconds').toISOString();
        let dur_length = section_obj.summary.length / 1000.0;
        let dump_obj = {
          num: num,
          departure: dep_time,
          arrival: arr_time,
          summary: `${dur_duration}, typical: ${dur_typDuration}, base: ${dur_baseduration}, length: ${dur_length}`,
        };
        console.log(dump_obj);
      }
    }
    // diagnostyka, docelowo do wyciecia
  }

  // ---------------------------------------------------------------------------
  /* logika obliczania kolejnych czasow, 
          time = SECTION0.departure.time
  START:  save time to db
          time = time + SECTION0.duration
  PUNKT1: save time to db // zapisujemy czas przyjazdu
          time = time + PUNKT1.stop_time
          time = time + SECTION1.duration
  PUNKT2: save time to db // zapisujemy czas przyjazdu
          time = time + PUNKT2,stop_time
          time = time + SECTION2.duration
  STOP:   save time to db
  */
  private updateMappointTimesForward(route: any, use_traffic: boolean) {
    const section_count = route.sections.length;
    if (section_count === 1) {
      const section = route.sections[0];
      const mom_start = moment(section.departure.time);  // "time": "2022-04-08T03:04:28+02:00",
      this.update_cartask(this.cartask_start, mom_start);
      const mom_stop = moment(section.arrival.time);
      this.update_cartask(this.cartask_stop, mom_stop);
    }
    else {
      const sett = this.apphelper.getSettings();
      const stoptime_min = Number(sett.stop_time_min);
      const stoptime_per_person = Number(sett.stop_time_person);
      const round_minutes = Number(sett.round_time_to_min);

      const section_start = route.sections[0];
      let cur_mom = moment(section_start.departure.time);
      this.update_cartask(this.cartask_start, cur_mom);

      let section_idx = 0;
      let mappoint_idx = 0;
      while (mappoint_idx < this.mappoints.length) {
        const mp = this.mappoints[mappoint_idx];
        if (section_idx < section_count) {
          const section = route.sections[section_idx]
          const section_duration = this.get_section_duration(section.summary, use_traffic);
          let dur = moment.duration(section_duration, 'seconds');
          cur_mom.add(dur)

          const rounded_mom = this.round_moment_down(cur_mom, round_minutes);
          for (const p of mp.persons)
            this.update_person(p, rounded_mom, this.filter_sam);
          for (const ct of mp.cartasks)
            this.update_cartask(ct, rounded_mom);
          mp.refresh_time_props();

          const stop_duration = this.get_stoptime_seconds(mp.persons.length, stoptime_per_person, stoptime_min);
          dur = moment.duration(stop_duration, 'seconds');
          cur_mom.add(dur)

        }
        mappoint_idx++;
        section_idx++;
      }

      if (section_idx < section_count) {
        const section = route.sections[section_idx]
        const section_duration = this.get_section_duration(section.summary, use_traffic);
        const dur = moment.duration(section_duration, 'seconds');
        cur_mom.add(dur)
        this.update_cartask(this.cartask_stop, cur_mom);
      }
    }
  }

  // ---------------------------------------------------------------------------
  /* logika obliczania kolejnych czasow
          time = SECTION2.arrival.time
  STOP:   save time to db
          time = time - SECTION2.duration
          time = time - PUNKT2.stop_time  
  PUNKT2: save time to db // zapisujemy czas przyjazdu
          time = time - SECTION1.duration
          time = time - PUNKT1.stop_time
  PUNKT1: save time to db // zapisujemy czas przyjazdu
          time = time - SECTION0.duration
  START:  save time to db
  */
  private updateMappointTimesBackward(route: any, use_traffic: boolean) {
    const section_count = route.sections.length;
    if (section_count === 1) {
      const section = route.sections[0];
      const mom_start = moment(section.departure.time);  // "time": "2022-04-08T03:04:28+02:00",
      this.update_cartask(this.cartask_start, mom_start);
      const mom_stop = moment(section.arrival.time);
      this.update_cartask(this.cartask_stop, mom_stop);
    }
    else {
      const sett = this.apphelper.getSettings();
      const stoptime_min = Number(sett.stop_time_min);
      const stoptime_per_person = Number(sett.stop_time_person);
      const round_minutes = Number(sett.round_time_to_min);

      const section_stop = route.sections[section_count - 1];
      let cur_mom = moment(section_stop.arrival.time);
      this.update_cartask(this.cartask_stop, cur_mom);

      let section_idx = section_count - 1;
      let mappoint_idx = this.mappoints.length - 1;
      while (mappoint_idx >= 0) {
        const mp = this.mappoints[mappoint_idx];
        if (section_idx >= 0) {
          const section = route.sections[section_idx]
          const section_duration = this.get_section_duration(section.summary, use_traffic);
          const stop_duration = this.get_stoptime_seconds(mp.persons.length, stoptime_per_person, stoptime_min);
          const dur = moment.duration(section_duration - stop_duration, 'seconds');
          cur_mom.subtract(dur)

          const rounded_mom = this.round_moment_down(cur_mom, round_minutes);
          for (const p of mp.persons)
            this.update_person(p, rounded_mom, this.filter_sam);
          for (const ct of mp.cartasks)
            this.update_cartask(ct, rounded_mom);
          mp.refresh_time_props();

        }
        mappoint_idx--;
        section_idx--;
      }

      if (section_idx >= 0) {
        const section = route.sections[section_idx]
        const section_duration = this.get_section_duration(section.summary, use_traffic);
        const dur = moment.duration(section_duration, 'seconds');
        cur_mom.subtract(dur)
        this.update_cartask(this.cartask_start, cur_mom);
      }
    }
  }

  // ---------------------------------------------------------------------------
  public getRouteSummary(route: any): any {
    let total_sec = 0;
    let total_m = 0;
    for (const section_obj of route.sections) {
      if (this.use_traffic_time) {
        if ('typicalDuration' in section_obj.summary)
          total_sec += section_obj.summary.typicalDuration;
        else
          total_sec += section_obj.summary.duration;
      }
      else {
        if ('baseDuration' in section_obj.summary)
          total_sec += section_obj.summary.baseDuration;
        else
          total_sec += section_obj.summary.duration;
      }
      total_m += section_obj.summary.length;
    }
    return { route_duration: total_sec, route_distance: total_m };
  }

  // ---------------------------------------------------------------------------
  public redrawRouteBackward(): void {
    const dt_stop_filter = this.apphelper.get_date_from_filter(this.filter_date);
    let dt_start_stop = null;
    let m = moment(this.start_stop_date);
    if (m.isValid())
      dt_start_stop = m.toDate();
    const dt_stop = dt_start_stop ?? dt_stop_filter;

    if (this.cartask_stop && this.cartask_stop.has_valid_time())
      dt_stop.setHours(this.cartask_stop.time_hour, this.cartask_stop.time_minutes, 0, 0);

    this.maps_service.clearPolylineShape(this.the_map);
    const waypoints: [string, string][] = this.prepareWayPoints();

    from(this.maps_service.getRouteDetailsNew(waypoints, null, dt_stop))
      .subscribe(
        data => { // data is: { route: "route_json", params: "params_json" }
          const route_params = data.params;
          const route = data.route;
          const summary = this.getRouteSummary(route);
          if (route) {
            this.route_map = route;
            this.route_map_params = route_params;
            this.maps_service.addRouteShapeToMap(this.the_map, route);
            this.updateMappointTimes(route, this.use_traffic_time, true);
          }
          this.route_time = (summary.route_duration / 60).toFixed(0);
          this.route_distance = (summary.route_distance / 1000.0).toFixed(1);
        },
        error => {
          this.apphelper.error_handler(error, 'DetailComponent.redrawRouteBackward');
        },
        () => {
        }
      );
  }

  // ---------------------------------------------------------------------------
  public redrawRouteForward(): void {
    const dt_start_filter = this.apphelper.get_date_from_filter(this.filter_date);
    let dt_start_stop = null;
    let m = moment(this.start_stop_date);
    if (m.isValid())
      dt_start_stop = m.toDate();
    const dt_start = dt_start_stop ?? dt_start_filter;

    if (this.cartask_start && this.cartask_start.has_valid_time())
      dt_start.setHours(this.cartask_start.time_hour, this.cartask_start.time_minutes, 0, 0);

    this.maps_service.clearPolylineShape(this.the_map);
    const waypoints: [string, string][] = this.prepareWayPoints();

    from(this.maps_service.getRouteDetailsNew(waypoints, dt_start, null))
      .subscribe(
        data => { // data is: { route: "route_json", params: "params_json" }
          const route_params = data.params;
          const route = data.route;
          const summary = this.getRouteSummary(data.route);;
          if (route) {
            this.route_map = route;
            this.route_map_params = route_params;
            this.maps_service.addRouteShapeToMap(this.the_map, route);
            this.updateMappointTimes(route, this.use_traffic_time, false);
          }
          this.route_time_2 = (summary.route_duration / 60).toFixed(0);
          this.route_distance = (summary.route_distance / 1000.0).toFixed(1);
        },
        error => {
          this.apphelper.error_handler(error, 'DetailComponent.redrawRouteForwardNew');
        },
        () => {
        }
      );
  }


  // ---------------------------------------------------------------------------
  // pos: Object { lat: 50.82744843143973, lng: 17.788191840809304 }
  public setMapPointPosition(pos: any) {
    const dlg = this.modal2;
    dlg.mappoints.length = 0;
    for (let i = 0; i < this.mappoints.length; i++) {
      const mp = this.mappoints[i];
      if (!mp.has_gps_data()) {
        mp['idx'] = i;
        dlg.mappoints.push(mp);
      }
    }
    if (this.cartask_start && !this.cartask_start.has_gps_data())
      dlg.cartask_start = this.cartask_start;
    if (this.cartask_stop && !this.cartask_stop.has_gps_data())
      dlg.cartask_stop = this.cartask_stop;

    const _samtyp = Number(this.filter_sam);

    const observ_objs: Observable<any>[] = [];
    dlg.open_promise().then(
      (submit) => {
        if (submit === 'save-changes') {
          if (dlg.selected_radio === 'mp') {
            if (dlg.selected_mappoint_idx >= 0) {
              const mp = this.mappoints[dlg.selected_mappoint_idx];
              if (mp) {
                for (const p of mp.persons) {
                  p.gps_lat = pos.lat;
                  p.gps_lon = pos.lng;
                  observ_objs.push(this.data_service.updatePerson$(p, _samtyp));
                }
                for (const ct of mp.cartasks) {
                  ct.gps_lat = pos.lat;
                  ct.gps_lon = pos.lng;
                  observ_objs.push(this.data_service.updateCarTask$(ct));
                }
                mp.update_all();
              }
            }
          }
          else if (dlg.selected_radio === 'start') {
            if (this.cartask_start) {
              this.cartask_start.gps_lat = pos.lat;
              this.cartask_start.gps_lon = pos.lng;
              observ_objs.push(this.data_service.updateCarTask$(this.cartask_start));
            }
          }
          else { // if (dlg.selected_radio === 'stop') {
            if (this.cartask_stop) {
              this.cartask_stop.gps_lat = pos.lat;
              this.cartask_stop.gps_lon = pos.lng;
              observ_objs.push(this.data_service.updateCarTask$(this.cartask_stop));
            }
          }
          forkJoin(observ_objs).subscribe(
            data => () => { },
            error => {
              this.apphelper.error_handler(error, 'DetailComponent.setMapPointPosition');
            },
            () => {
              this.refresh_map_objects();
            }
          );
        }
      },
      (cancel) => { }
    );
  }

  // -----------------------------------------------------------------------------
  public show_start_stop(): boolean {
    return this.visible_start_stop;
  }

  // -----------------------------------------------------------------------------
  public show_stop_start(): boolean {
    return this.visible_stop_start;
  }

  // -----------------------------------------------------------------------------
  public resetZoom() {
    const arr_objects = this.the_map.getObjects();
    if (arr_objects && arr_objects.length) // powinna byc tylko jedna grupa
      this.the_map.getViewModel().setLookAtData({ bounds: arr_objects[0].getBoundingBox() });
  }

  // -----------------------------------------------------------------------------
  // Jedynym odstępstwem od tego są przesiadki na wyjeździe popołudniowym w sobotę na mostkach które są po północy, 
  // ale to jest około 10% sobotniego wyjazdu i może to być zmieniane ręcznie przez dyspozytora.
  public setStartStopDate(_filter_date: Date, _powrot: number, _samtyp: number, _porawyj: number, _cartask_start: CarTask, _cartask_stop: CarTask): void {
    let _dt1 = _filter_date;
    let _dt2 = null;

    // Dla wyjazdu popołudniowego i powrotu jest tak:
    if (_powrot == 1 || (_powrot == 0 && _porawyj == 1)) {
      switch (_samtyp) {
        case 1: // Dowożący startuje i kończy w dniu na który jest przejazd, STOP->START
          _dt1 = _filter_date;
          _dt2 = _cartask_stop?.get_date_from_czas2();
          break;
        case 2: // Docelowy jedzie przez noc, czyli zaczyna w dacie przejazdu a kończy dnia następnego
          if (_powrot == 1) { // STOP->START
            _dt1 = moment(_filter_date).add(1, 'days').toDate();
            _dt2 = _cartask_stop?.get_date_from_czas2();
          }
          else { // START->STOP
            _dt1 = _filter_date;
            _dt2 = _cartask_start?.get_date_from_czas2();
          }
          break;
        case 3: // Rozwożący zaczyna i kończy dnia następnego po dacie przejazdu, START->STOP
        default:
          _dt1 = moment(_filter_date).add(1, 'days').toDate();
          _dt2 = _cartask_start?.get_date_from_czas2();
          break;
      }
    }
    // Dla wyjazdu porannego:
    else {
      switch (_samtyp) {
        case 1: // Dowożący kończy w dniu przejazdu (przesiadki są około 5:00 nad ranem), 
          // ale start może być w dniu poprzedzającym jeśli wyjazd ze ściany wschodniej. STOP->START
          _dt1 = _filter_date;
          _dt2 = _cartask_stop?.get_date_from_czas2();
          break;
        case 2: // Docelowy startuje i kończy w dniu przejazdu, START->STOP
          _dt1 = _filter_date;
          _dt2 = _cartask_start?.get_date_from_czas2();
          break;
        case 3:
        default: // Rozwożący startuje i kończy w dniu przejazdu, START->STOP
          _dt1 = _filter_date;
          _dt2 = _cartask_start?.get_date_from_czas2();
          break;
      }
    }

    if (_dt2)
      this.start_stop_date = moment(_dt2).format('YYYY-MM-DD');
    else
      this.start_stop_date = moment(_dt1).format('YYYY-MM-DD');
  }
}

// private CSS_COLOR_NAMES = ['blue', 'red', 'aqua', 'black', 'fuchsia', 'gray',
//   'green', 'lime', 'maroon', 'navy', 'olive', 'purple', 'silver', 'teal',
//   'white', 'yellow'
// ];

// private CSS_COLOR_NAMES_ALL = ['AliceBlue', 'AntiqueWhite', 'Aqua', 'Aquamarine',
//   'Azure', 'Beige', 'Bisque', 'Black', 'BlanchedAlmond', 'Blue', 'BlueViolet',
//   'Brown', 'BurlyWood', 'CadetBlue', 'Chartreuse', 'Chocolate', 'Coral',
//   'CornflowerBlue', 'Cornsilk', 'Crimson', 'Cyan', 'DarkBlue', 'DarkCyan',
//   'DarkGoldenRod', 'DarkGray', 'DarkGrey', 'DarkGreen', 'DarkKhaki', 'DarkMagenta',
//   'DarkOliveGreen', 'Darkorange', 'DarkOrchid', 'DarkRed', 'DarkSalmon', 'DarkSeaGreen',
//   'DarkSlateBlue', 'DarkSlateGray', 'DarkSlateGrey', 'DarkTurquoise', 'DarkViolet',
//   'DeepPink', 'DeepSkyBlue', 'DimGray', 'DimGrey', 'DodgerBlue', 'FireBrick',
//   'FloralWhite', 'ForestGreen', 'Fuchsia', 'Gainsboro', 'GhostWhite', 'Gold',
//   'GoldenRod', 'Gray', 'Grey', 'Green', 'GreenYellow', 'HoneyDew', 'HotPink', 'IndianRed',
//   'Indigo', 'Ivory', 'Khaki', 'Lavender', 'LavenderBlush', 'LawnGreen', 'LemonChiffon',
//   'LightBlue', 'LightCoral', 'LightCyan', 'LightGoldenRodYellow', 'LightGray',
//   'LightGrey', 'LightGreen', 'LightPink', 'LightSalmon', 'LightSeaGreen', 'LightSkyBlue',
//   'LightSlateGray', 'LightSlateGrey', 'LightSteelBlue', 'LightYellow', 'Lime',
//   'LimeGreen', 'Linen', 'Magenta', 'Maroon', 'MediumAquaMarine', 'MediumBlue',
//   'MediumOrchid', 'MediumPurple', 'MediumSeaGreen', 'MediumSlateBlue',
//   'MediumSpringGreen', 'MediumTurquoise', 'MediumVioletRed', 'MidnightBlue',
//   'MintCream', 'MistyRose', 'Moccasin', 'NavajoWhite', 'Navy', 'OldLace', 'Olive',
//   'OliveDrab', 'Orange', 'OrangeRed', 'Orchid', 'PaleGoldenRod', 'PaleGreen',
//   'PaleTurquoise', 'PaleVioletRed', 'PapayaWhip', 'PeachPuff', 'Peru', 'Pink', 'Plum',
//   'PowderBlue', 'Purple', 'Red', 'RosyBrown', 'RoyalBlue', 'SaddleBrown', 'Salmon',
//   'SandyBrown', 'SeaGreen', 'SeaShell', 'Sienna', 'Silver', 'SkyBlue', 'SlateBlue',
//   'SlateGray', 'SlateGrey', 'Snow', 'SpringGreen', 'SteelBlue', 'Tan', 'Teal', 'Thistle',
//   'Tomato', 'Turquoise', 'Violet', 'Wheat', 'White', 'WhiteSmoke', 'Yellow', 'YellowGreen'];
