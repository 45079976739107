<div class="container-fluid">
  <div class="row mb-2">
    <div class="col-7">
      <form class="form-inline">
        <label>Pokaż:</label>
        <div class="form-check form-check-inline ml-2">
          <input class="form-check-input ml-2" type="checkbox" id="check_stoppoints" name="check_stoppoints"
            [(ngModel)]="filter_stoppoints" (ngModelChange)="onFilterChange()">
          <label class="form-check-label" for="check_stoppoints">punkty zbiórki</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input  ml-2" type="checkbox" id="check_transferpoints" name="check_transferpoints"
            [(ngModel)]="filter_transferpoints" (ngModelChange)="onFilterChange()">
          <label class="form-check-label" for="check_transferpoints">punkty przesiadkowe</label>
        </div>
        <button type="button" class="btn btn-sm btn-primary ml-2" (click)="doRefreshMap()">Odśwież</button>
      </form>
    </div>
    <div class="col-5 text-right">
      <span class="font-weight-light">
        Wskaż miejsce na mapie, żeby zapisać pozycję GPS dla punktu...
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-3 scrollable">
      <div class="card scrolly">
        <ul class="list-group list-group-flush scrolly">
          <div class="list-group-item list-group-item-action px-0 py-0" *ngFor="let p of points"
            [ngSwitch]="p[1]==null">
            <ng-template [ngSwitchCase]="true">
              <app-card-stop-point [model]="p[0]"></app-card-stop-point>
            </ng-template>
            <ng-template [ngSwitchCase]="false">
              <app-card-transfer-point [model]="p[1]"></app-card-transfer-point>
            </ng-template>
          </div>
        </ul>
        <div class="card-footer">
          <section *ngIf="filter_stoppoints">
            Punkty zbiórki bez GPS: {{total_stoppoints_nogps}}
          </section>
          <section *ngIf="filter_transferpoints">
            Punkty przesiadki bez GPS: {{total_transferpoints_nogps}}
          </section>
        </div>
      </div>
    </div>
    <div class="col-9 scrollable scrolly">
      <div id="themap"></div>
    </div>
  </div>
</div>
<app-modal-point-position #modalPointPosition></app-modal-point-position>