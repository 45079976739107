<ng-template #content let-cs="close('save-changes')" let-dc="dismiss('close-window')" let-db="dismiss('close-button')">
  <div class="modal-header">
    <h4 class="modal-title">Edycja przypisania do samochodu</h4>
    <button type="button" class="close" aria-label="Close" (click)="dc">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <b>Osoba:</b>&nbsp;{{person_name}}<br>
    <b>Samochód:</b>&nbsp;{{person_car}}<br>
    <form>
      <div class="form-group">
        <label for="select-car" class="col-form-label">Wybierz samochód:</label>
        <select type="text" class="form-control" id="select-car" #t (change)="onChange(t.value)">
          <option *ngFor="let c of cars; let i = index" [value]="c.id"
            [attr.selected]="(c.id == selected_car_id) ? true : null">{{c.name}}</option>
        </select>
      </div>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="db">Zamknij</button>
      <button type="button" class="btn btn-primary" (click)="cs">Zapisz zmiany</button>
    </div>
  </div>
</ng-template>