import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterComponent } from './master/master.component';
import { DetailComponent } from './detail/detail.component';
import { PointsComponent } from './points/points.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { ErrorComponent } from './error/error.component';
import { TestingComponent } from './testing/testing.component';
import { AuthGuard } from './_services/auth.guard';
import { AdminGuard } from './_services/admin.guard';

const routes: Routes = [
  { path: 'przeglad', component: MasterComponent, canActivate: [AuthGuard] },
  { path: 'szczegoly', component: DetailComponent, canActivate: [AuthGuard] },
  { path: 'punkty', component: PointsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'ustawienia', component: SettingsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'problem', component: ErrorComponent, canActivate: [AuthGuard] },
  { path: 'testing', component: TestingComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' } // pagenotfound docelowo ?
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
