export class Car {
  id: number;
  name: string;
  person_count: number;
  is_virtual: boolean;

  constructor(_id: number, _name: string, _pers_count: number, _virtual: number) {
    this.id = _id;
    this.name = _name;
    this.person_count = _pers_count;
    this.is_virtual = _virtual == 1 ? true : false;
  }

  public static createObject(obj: any): Car {
    return new Car(
      obj.ID as number,
      obj.NAZWA as string,
      obj.ILOSC_OSOB as number,
      obj.WIRTUALNY as number
    );
  }
}
