import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Person } from '../person';
import { Car } from '../car';
import { StopPoint } from '../stop-point';
import { TransferPoint } from '../transfer-point';
import { HereMapsService } from './heremaps.service';
import { DataService } from '../_services/data.service';
import { MapPoint } from '../map-point';
import { CarTask } from '../car-task';
import { Settings } from '../settings';

declare var H: any;

@Injectable()
export class AppHelperService {

  private settings_key = 'settings';

  private css_colors = [
    '#000000', // black
    '#c0c0c0', // silver
    '#808080', // gray
    '#800000', // maroon
    '#ff0000', // red
    '#ffa500', // orange
    '#800080', // purple
    '#ff00ff', // fuchsia
    '#008000', // green
    '#00ff00', // lime
    '#808000', // olive
    '#ffff00', // yellow
    '#000080', // navy
    '#0000ff', // blue
    '#008080', // teal
    '#00ffff', // aqua
  ];

  constructor(
    public router: Router
  ) { }

  public get_css_colours(): string[] {
    return this.css_colors;
  }

  public get_short_time_str(d: Date): string {
    return `${d.getHours()}:${d.getMinutes()}`;
  }

  // filter_date_obj: { year:1970, month: 0, day: 1}, obiekt zwracany z app-datepicker-i18n
  public get_date_from_filter(filter_date_obj: any): Date {
    return new Date(filter_date_obj.year, filter_date_obj.month - 1, filter_date_obj.day);
  }

  public clearSettings() {
    sessionStorage.removeItem(this.settings_key);
  }

  public saveSettings(_settings: Settings): boolean {
    let ret = false;
    if (_settings) {
      sessionStorage.setItem(this.settings_key, _settings.to_json());
      ret = true;
    }
    return ret;
  }

  public getSettings(): Settings | null {
    const json = sessionStorage.getItem(this.settings_key);
    const s = new Settings;
    if (s.from_json(json)) {
      console.log('AppHelperService:.getSettings returned:', s);
      return s;
    }
    else {
      console.log('AppHelperService:.getSettings returned null');
      return null;
    }
  }

  public getLastSelectedCarId(): number {
    const val = sessionStorage.getItem('last_sel_car_id');
    // console.log('getLastSelectedCarId', val);
    return val != null ? Number(val) : -1;
  }

  public setLastSelectedCarId(val: number): void {
    sessionStorage.setItem('last_sel_car_id', val.toString());
    // console.log('setLastSelectedCarId', val);
  }

  /* tslint:disable:max-line-length */

  public svg_icon_pin(bgcolor: string, text: string = '', txtcolor: string = 'black'): string {
    let ret = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477 477" width="32px" height="32px">` +
      `<path d="M238,0c-40,0-74,13.833-102,41.5S94,102.334,94,141c0,23.333,13.333,65.333,40,126s48,106,64,136 ` +
      `s29.333,54.667,40,74c10.667-19.333,24-44,40-74s37.5-75.333,64.5-136S383,164.333,383,141c0-38.667-14.167-71.833-42.5-99.5 S278,0,238,0L238,0z" ` +
      `fill="${bgcolor}"/>`;
    if (text.length > 0) {
      ret += `<text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" dy="-30px" dx="0px" ` +
        `style="font-size:190px; font-weight:bold; font-family:Arial, helvetica; fill:${txtcolor}">${text}</text> `;
    }
    ret += '</svg>';
    return ret;
  }

  public svg_icon_flag(bgcolor: string, text: string = '', txtcolor: string = 'black'): string {
    let ret = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 492 492">` +
      `<g transform="translate(120 0)"><path style="fill:${bgcolor};" d="m 107.5,0 c -7,0 -13,2.5 -18,7.5 -5,5 -7.5,11.167 -7.5,18.5 v 26 26 ` +
      `155 233 c 0,6.667 2.667,12.667 8,18 5.333,5.333 11.167,8 17.5,8 6.333,0 12.167,-2.667 17.5,-8 ` +
      `5.333,-5.333 8,-11.333 8,-18 V 317 c 9.333,-4.667 17.667,-7 25,-7 16.667,0 45.834,10.833 87.5,32.5 ` +
      `41.667,21.667 71.167,32.5 88.5,32.5 28,0 53.333,-8.667 76,-26 V 32 c -11.333,8.667 -20,15.167 -26, ` +
      `19.5 -6,4.333 -13.833,8.667 -23.5,13 C 350.833,68.833 342,71 334,71 317.333,71 288,60.167 246,38.5 ` +
      `204,16.833 174.667,6 158,6 152,6 143.667,7 133,9 l -7.5,-1.5 c -5,-5 -11,-7.5 -18,-7.5 z"/> `;
    if (text.length > 0) {
      ret += `<text style="font-weight:bold;font-size:240px;font-family:Arial;fill:${txtcolor};" x="140" y="280">${text}</text>`;
    }
    ret += `</g></svg>`;
    return ret;
  }

  public svg_icon_square(bgcolor: string, text: string, txtcolor: string = 'black'): string {
    let ret = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 492 492">` +
      `<g><path style="fill:${bgcolor};" d="M451,0H41v410h155l55,82l56-82h144V0z"/>`;
    if (text.length > 0) {
      ret += `<text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" dy="-5px" ` +
        `style="font-size:190px; font-weight:bold; font-family:Arial, helvetica; fill:${txtcolor}">${text}</text> `;
    }
    ret += `</g></svg>`;
    return ret;
  }

  public svg_icon_circle(bgcolor: string, text: string = '', txtcolor: string = 'black'): string {
    let ret = `<svg width="32px" height="32px" xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 400 400">` +
      `<circle cx="200" cy="200" r="180" fill="blue" stroke-width="0" />`; // `<circle cx="200" cy="200" r="190" fill="red" stroke="blue" stroke-width="30" />`;
    if (text.length > 0) {
      ret += `<text x="200" y="250" text-anchor="middle" alignment-baseline="middle" ` + // dy="-20px"
        `style="font-size:180px; font-weight:bold; font-family:Arial, helvetica; fill:${txtcolor}">${text}</text>`;
    }
    ret += `</svg>`;
    return ret;
  }

  /* tslint:enable:max-line-length */

  // // Kopia logiki z programu Przewóz Osób (autko): ModelRozpiskaEdit::choose_fields
  // private choose_person_addr_fields(p: Person, samtyp: number): { addr1: string, addr2: string } {
  //   const ret = { addr1: '', addr2: '' };
  //   const powrot = Number(p.powrot) === 1 ? true : false;
  //   switch (Number(samtyp)) {
  //     case 1: // DOW
  //       ret.addr1 = p.msc_odb1;
  //       ret.addr2 = p.msc_odb2;
  //       break;
  //     case 2: // DOC
  //       ret.addr1 = powrot ? p.msc_odb1 : p.msc_doc1;
  //       ret.addr2 = powrot ? p.msc_odb2 : p.msc_doc2;
  //       break;
  //     case 3: // ROZ
  //       ret.addr1 = p.msc_doc1;
  //       ret.addr2 = p.msc_doc2;
  //       break;
  //     default:
  //       console.log('AppHelperservice::choose_person_addr_fields: ivnvalid samtyp param: ', samtyp);
  //       break;
  //   }
  //   return ret;
  // }

  // ---------------------------------------------------------------------------
  /**
   * Tworzy string z adresem osoby, uwzgledniajac wszystkie dziwne konwencje stosowane
   * w programie Przewóz Osób
   * @param p obiekt z danymi osoby
   * @param samtyp rodzaj samochodu: dow (1), doc (2) lub roz (3)
   */
  public get_person_address(p: Person, samtyp: number): string {
    // const obj_addr = this.choose_person_addr_fields(p, samtyp);
    let s = `${p.adr1},${p.adr2}`;
    if (Number(p.powrot) !== 1 && Number(samtyp) === 1) { // wyjazd i sam dow
      const s_mscodb1 = p.adr1.toLocaleUpperCase();
      if (s_mscodb1.startsWith('INNE'))
        s = `${p.miejscowosc},${p.adr2}`;
    }

    // wyciagam nazwe kraju ze srodka adresu i dodaje na koncu
    const s_arr = ['NIEMCY', 'BELGIA', 'HOLANDIA', 'POLSKA'];
    for (let i = 0; i < s_arr.length; i++) {
      const s_country = s_arr[i];
      const s_addr = s.toLocaleUpperCase();
      const idx = s_addr.indexOf(s_country);
      if (idx !== -1) {
        const s_kraj = s.substr(idx, s_country.length);
        const s1 = s.replace(s_kraj, '').concat(',', s_kraj);
        s = s1;
      }
    }
    return s;
  }

  // ---------------------------------------------------------------------------
  /**
   * Tworzy string z adresem osoby, ktory pozniej bedzie podany do uslugi
   * geolokalizacyjnej. Uwzglednia wszystkie dziwne konwencje stosowane
   * w programie Przewóz Osób
   * @param p obiekt z danymi osoby
   * @param samtyp rodzaj samochodu: dow (1), doc (2) roz (3)
   */
  public get_person_address_geo(p: Person, samtyp: number): string {
    const s = this.get_person_address(p, samtyp);
    // const re = /,|_/g; // przedtem usuwalem tez przecinek
    return s.replace('|', ' ').replace('_', ' ').trim();
  }

  // ---------------------------------------------------------------------------
  /**
   * Zwraca string z nazwa punktu zbiorki, jezeli klient na nim wsiada. Jezeli nie
   * wsiada na punkcie (powrot albo trasa Inne), funkcja zwraca pusty string
   * @param p : Person
   */
  public get_person_stoppoint(p: Person): string {
    let ret = '';
    if (p != null) {
      if (p.powrot !== 1 && !p.is_inne())
        ret = p.adr1;
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Tworzy obiekt H.map.Marker dla punktu zbiorki
   * @param service instancja serwisu HereMapsService
   * @param sp obiekt StopPoint
   */
  public create_stoppoint_map_marker(service: HereMapsService, sp: StopPoint): any {
    const svgicon = service.create_icon_pin('navy');
    const s_markerhtml = `<div class="stoppoint-info">${sp.nazwa}<br>${sp.trasa} (${sp.pora_dnia()})<br>` +
      `<button class="marker-button" onclick="update_stoppoint_gps(${sp.id});">Zapisz pozycję</button></div>`;
    const marker_obj = service.marker_create_custom_icon(sp.gps_lat, sp.gps_lon, svgicon, s_markerhtml);
    if (marker_obj) {
      marker_obj.id_stoppoint = Number(sp.id);
      marker_obj.typename = 'stoppoint';
    }
    return marker_obj;
  }

  // ---------------------------------------------------------------------------
  /**
   * Tworzy obiekt H.map.Marker dla punktu przesiadki
   * @param service instancja serwisu HereMapsService
   * @param sp obiekt StopPoint
   */
  public create_transferpoint_map_marker(service: HereMapsService, tp: TransferPoint): any {
    const svgicon = service.create_icon_pin('blue');
    const s_markerhtml = `<div class="transferpoint-info">${tp.nazwa}<br>(punkt przesiadki)<br>` +
      `<button class="marker-button" onclick="update_transferpoint_gps(${tp.id});">Zapisz pozycję</button></div>`;
    const marker_obj = service.marker_create_custom_icon(tp.gps_lat, tp.gps_lon, svgicon, s_markerhtml);
    if (marker_obj) {
      marker_obj.id_transferpoint = Number(tp.id);
      marker_obj.typename = 'transferpoint';
    }
    return marker_obj;
  }


  // ---------------------------------------------------------------------------
  public find_car_idx(arr_cars: Car[], id_auto: number): number {
    let ret = -1;
    if (arr_cars) {

      if (arr_cars.length <= 0)
        return ret;
      for (let i = 0; i < arr_cars.length && ret === -1; i++) {
        if (Number(arr_cars[i].id) === id_auto)
          ret = i;
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public get_car_name(arr_cars: Car[], id_auto: number): string {
    let ret = '???';
    const idx = this.find_car_idx(arr_cars, id_auto);
    if (idx >= 0 && idx < arr_cars.length)
      ret = arr_cars[idx].name;
    return ret;
  }

  // ---------------------------------------------------------------------------
  public find_stoppoint_idx(arr_sp: StopPoint[], s_name: string): number {
    let ret = -1;
    if (arr_sp && s_name) {
      if (arr_sp.length <= 0)
        return ret;
      if (s_name.length <= 0)
        return ret;
      for (let i = 0; i < arr_sp.length && ret === -1; i++) {
        if (arr_sp[i].nazwa === s_name)
          ret = i;
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public find_transferpoint_idx(arr_tp: TransferPoint[], s_name: string): number {
    let ret = -1;
    if (arr_tp && s_name) {
      if (arr_tp.length <= 0)
        return ret;
      if (s_name.length <= 0)
        return ret;
      for (let i = 0; i < arr_tp.length && ret === -1; i++) {
        if (arr_tp[i].nazwa === s_name)
          ret = i;
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public get_wyjazd_powrot_info(id_wyjpow: number): string {
    let ret = '';
    switch (id_wyjpow) {
      case 0: { ret = 'powrót po południu'; break; }
      case 1: { ret = 'wyjazd po południu'; break; }
      case 2: { ret = 'wyjazd przed południem'; break; }
      case 3: { ret = 'powrót przed południem'; break; }
      default: { ret = '???'; }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Konwertuje obiekt Date na obiekt { year, month, day } używany w komponencie NgDatePicker
   * @param data obiekt typu Date
   */
  public get_filter_date_from_date(data: Date): any {
    let ret = null;
    if (data)
      ret = { year: data.getFullYear(), month: data.getMonth() + 1, day: data.getDate() };
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Konwertuje datę zapisaną jako string na obiekt { year, month, day } używany w komponencie NgDatePicker
   * @param data obiekt zapisany jako string
   */
  public get_filter_date_from_string(data: string): any {
    let ret = null;
    const d = new Date(data);
    if (d && !isNaN(d.getTime()))
      ret = this.get_filter_date_from_date(d);
    return ret;
  }


  // // ---------------------------------------------------------------------------
  // public select_id_auto(p: Person, _filter_sam: number): number {
  //   let ret = -1;
  //   if (p != null && _filter_sam != null) {
  //     switch (_filter_sam) {
  //       case 1: ret = p.id_auto_dow; break;
  //       case 2: ret = p.id_auto_doc; break;
  //       case 3: ret = p.id_auto_roz; break;
  //     }
  //   }
  //   return Number(ret);
  // }

  // // ---------------------------------------------------------------------------
  // public set_id_auto(p: Person, _filter_sam: number, _id_auto: number): void {
  //   if (p != null && _filter_sam != null && _id_auto != null) {
  //     switch (_filter_sam) {
  //       case 1: p.id_auto_dow = Number(_id_auto); break;
  //       case 2: p.id_auto_doc = Number(_id_auto); break;
  //       case 3: p.id_auto_roz = Number(_id_auto); break;
  //     }
  //   }
  // }

  // ---------------------------------------------------------------------------
  public get_auto_typ(_filter_sam: number): string {
    let ret = '?';
    switch (_filter_sam) {
      case 1: ret = 'dowożący'; break;
      case 2: ret = 'docelowy'; break;
      case 3: ret = 'rozwożący'; break;
    }
    return ret;
  }

  // // ---------------------------------------------------------------------------
  // public set_lp_rozp(p: Person, _filter_sam: number, _lp_rozp: number): void {
  //   if (p != null && _filter_sam != null && _lp_rozp != null) {
  //     switch (_filter_sam) {
  //       case 1: p.lp_rozp_dow = Number(_lp_rozp); break;
  //       case 2: p.lp_rozp_doc = Number(_lp_rozp); break;
  //       case 3: p.lp_rozp_roz = Number(_lp_rozp); break;
  //     }
  //   }
  // }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // REFRESH MAP common code

  // ---------------------------------------------------------------------------
  public precisionRound(number, precision): number {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  // ---------------------------------------------------------------------------
  public gps_equal(gps1_lat: string, gps1_lon: string, gps2_lat: string, gps2_lon: string): boolean {
    let lat1: number = Number.parseFloat(gps1_lat);
    let lon1: number = Number.parseFloat(gps1_lon);
    let lat2: number = Number.parseFloat(gps2_lat);
    let lon2: number = Number.parseFloat(gps2_lon);

    if (isNaN(lat1) || isNaN(lon1) || isNaN(lat2) || isNaN(lon2))
      return false;
    const prec = 5; // ustalone doswiadczalnie, http://www.movable-type.co.uk/scripts/latlong.html
    lat1 = this.precisionRound(lat1, prec);
    lon1 = this.precisionRound(lon1, prec);
    lat2 = this.precisionRound(lat2, prec);
    lon2 = this.precisionRound(lon2, prec);
    return (lat1 === lat2 && lon1 === lon2) ? true : false;
  }

  // ---------------------------------------------------------------------------
  public gps_to_string(gps_lat: string, gps_lon: string, rounded = true) {
    let lat: number = Number.parseFloat(gps_lat);
    let lon: number = Number.parseFloat(gps_lon);
    if (isNaN(lat) || isNaN(lon))
      return '';
    if (rounded) {
      const prec = 4; // ustalone doswiadczalnie, http://www.movable-type.co.uk/scripts/latlong.html
      lat = this.precisionRound(lat, prec);
      lon = this.precisionRound(lon, prec);
    }
    return `${lat}_${lon}`;
  }

  // ---------------------------------------------------------------------------
  public get_matching_stoppoint(address: string, stoppoints: StopPoint[]): StopPoint {
    let ret: StopPoint = null;
    const idx_stoppoint = this.find_stoppoint_idx(stoppoints, address);
    if (idx_stoppoint >= 0)
      ret = stoppoints[idx_stoppoint];
    return ret;
  }

  // ---------------------------------------------------------------------------
  public get_matching_transferpoint(address: string, transferpoints: TransferPoint[]): TransferPoint {
    let ret: TransferPoint = null;
    const idx_transferpoint = this.find_transferpoint_idx(transferpoints, address);
    if (idx_transferpoint >= 0)
      ret = transferpoints[idx_transferpoint];
    return ret;
  }

  // ---------------------------------------------------------------------------
  public prepare_persons_for_geocoding(
    map_persons: any,
    stoppoints: StopPoint[],
    powrot: number,
    samtyp: number,
    persons_nogps: number[],
    persons_updatedb: number[],
    errorlog: string[]): void {

    const errorlog_idx = {};
    // const check_sp = powrot === 0 && samtyp === 1; // punkty zbiorki tylko dla aut dow na wyjezdzie
    const check_sp = true; // punkty zbiorki always

    const it_persons = Object.getOwnPropertyNames(map_persons);
    for (const it of it_persons) {
      const person: Person = map_persons[it];
      let search_for_gps = false;
      if (person && !person.has_gps_data()) {
        if (check_sp) {
          const sp = this.get_matching_stoppoint(this.get_person_stoppoint(person), stoppoints);
          if (sp) {
            if (sp.has_gps_data()) {
              person.gps_lat = sp.gps_lat;
              person.gps_lon = sp.gps_lon;
              persons_updatedb.push(person.id);
            }
            else {
              if (!errorlog_idx[sp.id]) {
                errorlog_idx[sp.id] = true;
                const s = `Punkt zbiórki ${sp.nazwa} nie ma przypisanych współrzędnych GPS`;
                console.log(s);
                errorlog.push(s);
              }
            }
          }
          else {
            // szukam gps tylko dla osob nie przypisanych do punktu zbiorki
            // jesli punkt zbiorki nie ma wpisanego GPS to zglaszam blad powyzej
            // nie probuje znajdowac pozycji punktu zbiorki oraz osob do niego
            // przypisanych
            search_for_gps = true;
          }
        }
        else { // not check_sp
          search_for_gps = true;
        }
        if (search_for_gps) {

        }
      }
      if (search_for_gps && !person.has_gps_data())
        persons_nogps.push(person.id);
    }
  }

  // ---------------------------------------------------------------------------
  public prepare_cartasks_for_geocoding(
    map_cartasks: any,
    stoppoints: StopPoint[],
    powrot: number,
    samtyp: number,
    cartasks_nogps: number[],
    cartasks_updatedb: number[],
    errorlog: string[]): void {

    const errorlog_idx = {};
    // const check_sp = powrot === 0 && samtyp === 1; // punkty zbiorki tylko dla aut dow na wyjezdzie
    const check_sp = true; // punkty zbiorki always

    const it_cartasks = Object.getOwnPropertyNames(map_cartasks);
    for (const it of it_cartasks) {
      const cartask: CarTask = map_cartasks[it];
      let search_for_gps = false;
      if (cartask && !cartask.has_gps_data()) {
        if (check_sp) {
          const sp = this.get_matching_stoppoint(cartask.address, stoppoints);
          if (sp) {
            if (sp.has_gps_data()) {
              cartask.gps_lat = sp.gps_lat;
              cartask.gps_lon = sp.gps_lon;
              cartasks_updatedb.push(cartask.id);
            }
            else {
              if (!errorlog_idx[sp.id]) {
                errorlog_idx[sp.id] = true;
                const s = `Punkt zbiórki ${sp.nazwa} nie ma przypisanych współrzędnych GPS`;
                console.log(s);
                errorlog.push(s);
              }
            }
          }
          else {
            if (cartask.address && cartask.address.length > 0)
              search_for_gps = true;
          }
        }
        else {
          if (cartask.address && cartask.address.length > 0)
            search_for_gps = true;
        }
      }
      if (search_for_gps && !cartask.has_gps_data())
        cartasks_nogps.push(cartask.id);
    }
  }

  // ---------------------------------------------------------------------------
  public prepare_mappoints(map_persons: any, map_cartasks: any, samtyp: number, powrot: boolean, errorlog: string[], no_stop_start: boolean): MapPoint[] {
    const mapidx = {};
    const it_persons = Object.getOwnPropertyNames(map_persons);
    for (const it of it_persons) {
      const person: Person = map_persons[it];
      if (person) {
        let s_key = '';
        if (person.has_gps_data())
          s_key = this.gps_to_string(person.gps_lat, person.gps_lon, true);
        else
          s_key = this.get_person_address(person, samtyp);
        if (s_key.length > 0) {
          const mp: MapPoint = mapidx[s_key];
          if (mp) {
            mp.add_person(person);
          }
          else {
            const mp_new: MapPoint = new MapPoint(this);
            mp_new.samtyp = samtyp;
            mp_new.powrot = powrot;
            mp_new.add_person(person);
            mapidx[s_key] = mp_new;
          }
        }
        else {
          const s = `AppHelperService.gps_to_string zwrocilo blad dla osoby o id=${person.id}`;
          console.log(s);
        }
      }
    }
    const it_cartasks = Object.getOwnPropertyNames(map_cartasks);
    for (const it of it_cartasks) {
      const cartask: CarTask = map_cartasks[it];
      if (cartask) {
        if (no_stop_start && (cartask.is_start() || cartask.is_stop()))
          continue; // START i STOP są dodawane osobno w refresh_map_objects

        let s_key = '';
        if (cartask.has_gps_data())
          s_key = this.gps_to_string(cartask.gps_lat, cartask.gps_lon, true);
        else
          s_key = cartask.address;
        if (s_key.length > 0) {
          const mp: MapPoint = mapidx[s_key];
          if (mp) {
            mp.add_cartask(cartask);
          }
          else {
            const mp_new: MapPoint = new MapPoint(this);
            mp_new.add_cartask(cartask);
            mp_new.powrot = powrot;
            mapidx[s_key] = mp_new;
          }
        }
        else {
          const s = `AppHelperService.gps_to_string zwrocilo blad dla zadania o id=${cartask.id}`;
          console.log(s);
        }
      }
    }

    const ret: MapPoint[] = [];
    const it_mapidx = Object.getOwnPropertyNames(mapidx);
    for (const it of it_mapidx) {
      const mp: MapPoint = mapidx[it];
      mp.samtyp = samtyp;
      mp.powrot = powrot;
      mp.update_all();
      ret.push(mp);
    }
    ret.sort((a, b) => a.lp_rozp < b.lp_rozp ? -1 : (a.lp_rozp > b.lp_rozp ? 1 : 0));

    // czasem MapPoints mają nieprawidłowe lp_rozp, zwłaszcza po przeniesieniu z innego auta, gdzie osoba była na 16 pozycji
    // a w nowym aucie osób jest np. 6
    // po posortowaniu MapPointów trzeba je ponownie poustawiać lp_rozp od 1 po kolei
    for (let i = 0; i < ret.length; i++)
      ret[i].lp_rozp = i + 1;

    return ret;
  }

  // ---------------------------------------------------------------------------
  public create_mappoint_html(mp: MapPoint, map_cars: any, samtyp: number, bgcolor: string = null): string {
    if (!(mp && mp.has_gps_data()))
      return '';
    const s_bgcolor = (bgcolor) ? bgcolor : 'black';
    const s_id = this.gps_to_string(mp.gps_lat, mp.gps_lon, false).replace('.', '-').replace('.', '-');

    let ret = `
    <div class="mappoint-info" id="${s_id}" style="background-color: ${s_bgcolor}">
    <h2>${mp.addr1}, ${mp.addr2}</h2>
    <table>`;
    const arr_p_id: number[] = [];
    for (let i = 0; i < mp.persons.length; i++) {
      const p: Person = mp.persons[i];
      arr_p_id.push(Number(p.id));
      const s_carname = map_cars[p.id_auto] ? map_cars[p.id_auto].name : '???';
      const s_pin_colour = p.pin_kolor && p.pin_kolor.length > 0 ? p.pin_kolor : 'blue';
      const s_row = `
      <tr>
        <td>
          ${p.nazwisko} ${p.imie}
        </td>
        <td>
          <a href="#" class="change-colour" onclick="change_person_colour(${p.id}); return false;" title="Zmień kolor">
            <span class="badge badge-pill" role="button" style="background-color: ${s_pin_colour};">&nbsp;&nbsp;</span>
          </a>
        </td>
        <td>
          <a href="#" class="change-car" onclick="change_person_auto(${p.id}); return false;" title="Zmień auto">
            ${s_carname}
            <img src="/assets/minibus-svgrepo-com.svg" height="22px">
          </a>
        </td>
        <td class="text-center">
          <input type="checkbox" data-id="${p.id}" class="mappoint-info-check-person" checked>
        </td>
      </tr>`;
      ret += s_row;
    }
    const arr_ct_id: number[] = [];
    for (let i = 0; i < mp.cartasks.length; i++) {
      const ct: CarTask = mp.cartasks[i];
      arr_ct_id.push(Number(ct.id));
      const s_carname = map_cars[ct.id_samochod] ? map_cars[ct.id_samochod].name : '???';
      const s_row = `
      <tr>
        <td>${ct.zadanie_info}</td>
        <td>${s_carname}</td>
        <td>&nbsp;</td>
      </tr>`;
      ret += s_row;
    }
    ret += `
    </table>
    <button class="marker-button btn btn-secondary btn-sm"
      onclick="update_mappoint_gps([${arr_p_id}],[${arr_ct_id}]);">
      Zapisz pozycję GPS
    </button>
    <button class="marker-button btn btn-secondary btn-sm"
      onclick="change_all_persons_auto('${s_id}');">
      Zmień zaznaczone auta
    </button>
    <button class="marker-button btn btn-secondary btn-sm"
      onclick="change_all_persons_colour('${s_id}');">
      Zmień zaznaczone kolory
    </button>
    <button class="marker-button btn btn-outline-secondary btn-sm ml-1"
      onclick="toggle_checkboxes('#${s_id} input.mappoint-info-check-person');"
      title="Zaznacz/odznacz wszystkie osoby">
      <img src="assets/check-square.svg" width="16" height="16">
    </button>
    </div>`;
    return ret;
  }

  // ---------------------------------------------------------------------------
  private get_label_colour(pin_color: string, labeL_color_dark: string, label_color_light: string): string {
    const brightness = this.get_color_brightness(pin_color);
    return brightness < 145 ? label_color_light : labeL_color_dark;
  }

  // ---------------------------------------------------------------------------
  private get_person_icon_svg(_persons: Person[], _label: string): string {
    const default_pin_colour = 'blue';
    const default_label_colour_light = 'white';
    const default_label_colour_dark = 'black';
    let pin_colour = default_pin_colour;

    if (_persons.length == 0) {
      console.error('AppHelperService::get_person_icon_svg: empty _persons data');
      return '';
    }

    if (_persons.length == 1) {
      if (_persons[0].pin_kolor.length > 0)
        pin_colour = _persons[0].pin_kolor;
      let label_colour = this.get_label_colour(pin_colour, default_label_colour_dark, default_label_colour_light);
      return this.svg_icon_pin(pin_colour, _label, label_colour);
    }

    // if (_persons.length > 1)
    const m_colours = new Map();
    for (let i = 0; i < _persons.length; i++) {
      const _p = _persons[i];
      let _c = default_pin_colour;
      if (_p.pin_kolor && _p.pin_kolor.length > 0)
        _c = _p.pin_kolor.trim();
      if (!m_colours.has(_c))
        m_colours.set(_c, true);
      if (m_colours.size > 1)
        break;
    }

    if (m_colours.size <= 1) {
      pin_colour = m_colours.keys().next().value;
      let label_colour = this.get_label_colour(pin_colour, default_label_colour_dark, default_label_colour_light);
      return this.svg_icon_pin(pin_colour, _label, label_colour); // persons with the same colour
    }
    else {
      let label_colour = this.get_label_colour(pin_colour, default_label_colour_dark, default_label_colour_light);
      return this.svg_icon_square(pin_colour, _label, label_colour); // persons with different colours
    }
  }

  // ---------------------------------------------------------------------------
  private get_task_icon_svg(_cartasks: CarTask[], _label: string): string {
    const default_pin_colour = 'blue';
    const default_stop_colour = '#d40000';
    const default_start_colour = '#008000';
    const default_label_colour = 'white';
    let pin_colour = default_pin_colour;
    let label_colour = default_label_colour;

    if (_cartasks.length == 0) {
      console.error('AppHelperService::get_task_icon_svg: empty _cartasks data');
      return '';
    }

    let is_stop = false;
    let is_start = false;
    for (let i = 0; i < _cartasks.length; i++) {
      const _t = _cartasks[i];
      is_start = is_start || _t.is_start();
      is_stop = is_stop || _t.is_stop();
    }

    if (is_stop === is_start)
      console.log('Invalid data in MapPoint? stop and start tasks in the same mappoint');

    if (is_stop)
      pin_colour = default_stop_colour;
    if (is_start)
      pin_colour = default_start_colour;

    if (is_stop || is_start)
      return this.svg_icon_flag(pin_colour);
    else
      return this.svg_icon_flag(pin_colour, _label, label_colour);
  }


  // ---------------------------------------------------------------------------
  // counter_mode: 0 - bez liczby, 1 - liczba osob i zadan, 2 - pozycja na rozpisce
  // logika wyboru svg dla ikony:
  //            | ct_len == 0 | ct_len == 1 | ct_len > 1
  // ---------------------------------------------------
  // p_len == 0 |      -      |    flag     |  flag+num
  // p_len == 1 |     pin     | circle+num  | circle+num
  // p_len >  1 |   pin+num   | circle+num  | circle+num
  public create_mappoint_icon_svg(mp: MapPoint, counter_mode: number): string {
    let s_label = '';
    switch (counter_mode) {
      case 1: s_label = mp.total_persons.toString(); break;
      case 2: s_label = mp.lp_rozp.toString(); break;
      default: s_label = ''; break;
    }

    const p_len = mp.persons.length;
    const ct_len = mp.cartasks.length;
    let ret = '';
    if (p_len === 0 && ct_len === 0) {
      console.log('AppHelperService.create_mappoint_icon_svg: empty mappoint data');
      return '';
    }
    else if (p_len === 0) {
      ret = this.get_task_icon_svg(mp.cartasks, s_label);
    }
    else if (ct_len === 0) {
      ret = this.get_person_icon_svg(mp.persons, s_label);
    }
    else {
      ret = this.svg_icon_circle('#DFDFDF', s_label, 'white');
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public create_map_marker(mapsrv: HereMapsService, mp: MapPoint, map_cars: any, samtyp: number, counter_mode: number): any {
    let ret = null;
    const s_html = this.create_mappoint_html(mp, map_cars, samtyp, 'smokewhite');
    const svg_icon_markup = this.create_mappoint_icon_svg(mp, counter_mode);
    if (svg_icon_markup.length > 0) {
      const icon = new H.map.Icon(svg_icon_markup);
      const mapobj = mapsrv.marker_create_custom_icon(mp.gps_lat, mp.gps_lon, icon, s_html);
      if (mapobj) {
        mapobj.id_persons = [];
        for (let j = 0; j < mp.persons.length; j++)
          mapobj.id_persons.push(Number(mp.persons[j].id));
        mapobj.id_cartasks = [];
        for (let j = 0; j < mp.cartasks.length; j++)
          mapobj.id_cartasks.push(Number(mp.cartasks[j].id));
        mapobj.setVisibility(true);
        mapobj.draggable = true;
        ret = mapobj;
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public prepare_map_markers(mapsrv: HereMapsService, mappoints: MapPoint[], map_cars: any, samtyp: number, counter_mode: number): any[] {
    const ret: any[] = [];
    for (let i = 0; i < mappoints.length; i++) {
      const mp: MapPoint = mappoints[i];
      const marker = this.create_map_marker(mapsrv, mp, map_cars, samtyp, counter_mode);
      if (marker)
        ret.push(marker);
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public update_gps_and_address_state_to_db(datasrv: DataService,
    samtyp: number,
    persons: any, // object - map
    cartasks: any, // object - map
    person_id: number[],
    cartask_id: number[]): void {
    const update_objs: Observable<any>[] = [];
    for (let i = 0; i < person_id.length; i++) {
      const p = persons[person_id[i]];
      if (p) {
        const o = datasrv.updatePerson$(p, samtyp);
        update_objs.push(o);
        const o1 = datasrv.updatePersonAddressState$(p, samtyp);
        update_objs.push(o1);
      }
    }
    for (let i = 0; i < cartask_id.length; i++) {
      const ct = cartasks[cartask_id[i]];
      if (ct) {
        const o = datasrv.updateCarTask$(ct);
        update_objs.push(o);
        const o1 = datasrv.updateCarTaskAddressState$(ct);
        update_objs.push(o1);
      }
    }
    forkJoin(update_objs).subscribe(
      data => { },
      error => {
        this.error_handler(error, 'AppHelperService.update_gps_to_db');
      },
      () => { }
    );
  }

  // ---------------------------------------------------------------------------
  public get_person_object(mappoints: MapPoint[], id_person: number): Person {
    let ret: Person = null;
    if (mappoints) {
      for (const mp of mappoints)
        for (const p of mp.persons)
          if (p.id === id_person) {
            ret = p;
            break;
          }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public get_cartask_object(mappoints: MapPoint[], ct_start: CarTask, ct_stop: CarTask, id_cartask: number): CarTask {
    if (ct_start && ct_start.id === id_cartask)
      return ct_start;
    if (ct_stop && ct_stop.id === id_cartask)
      return ct_stop;
    let ret: CarTask = null;
    if (mappoints) {
      for (const mp of mappoints)
        for (const ct of mp.cartasks)
          if (ct.id === id_cartask) {
            ret = ct;
            break;
          }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public is_array(obj: any): boolean {
    return obj && obj.constructor === Array;
  }

  // ---------------------------------------------------------------------------
  public array_has_value(arr: any, val: any): boolean {
    let ret = false;
    const s_val = val.toString();
    if (this.is_array(arr)) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].toString() === s_val) {
          ret = true;
          break;
        }
      }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public find_position_of_person(mapobj: any /* H.Map */, _id_person: number): [string, string] | null {
    let ret = null;
    if (mapobj === undefined || mapobj === null)
      return ret;
    if (!(mapobj instanceof H.Map))
      return ret;
    const objs = mapobj.getObjects(true);
    for (let i = 0; i < objs.length; i++) {
      if (objs[i] instanceof H.map.Marker)
        if (this.array_has_value(objs[i].id_persons, _id_person)) {
          const geom = objs[i].getGeometry();
          ret = [`${geom.lat}`, `${geom.lng}`];
          break;
        }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public find_position_of_cartask(mapobj: any /* H.Map */, _id_cartask: number): [string, string] | null {
    let ret = null;
    if (mapobj === undefined || mapobj === null)
      return ret;
    if (!(mapobj instanceof H.Map))
      return ret;
    const objs = mapobj.getObjects(true);
    for (let i = 0; i < objs.length; i++) {
      if (objs[i] instanceof H.map.Marker)
        if (this.array_has_value(objs[i].id_cartasks, _id_cartask)) {
          const geom = objs[i].getGeometry();
          ret = [`${geom.lat}`, `${geom.lng}`];
          break;
        }
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public get_mappoint_object(mappoints: MapPoint[], arr_id_person: number[], arr_id_cartask: number[]): MapPoint | null {
    if (!mappoints)
      return null;

    for (const mp of mappoints) {
      for (const p of mp.persons)
        for (const id of arr_id_person)
          if (id === p.id)
            return mp;
      for (const ct of mp.cartasks)
        for (const id of arr_id_cartask)
          if (id === ct.id)
            return mp;
    }
    return null;
  }

  // ---------------------------------------------------------------------------
  public error_handler(errobj: any, custom_msg: string) {
    console.error('Error: ', custom_msg, errobj);
    if (errobj && errobj.status && errobj.status === 401) {
      this.router.navigate(['/login']);
    }
  }


  // ---------------------------------------------------------------------------
  public is_start_stop_visible(powrot: boolean, odbdoc: number): boolean {
    let ret = false;
    if (powrot) {
      if (odbdoc == 3) // roz
        ret = true;
    } else {
      if (odbdoc == 2 || odbdoc == 3) // doc || roz
        ret = true;
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  public is_stop_start_visible(powrot: boolean, odbdoc: number): boolean {
    let ret = false;
    if (powrot) {
      if (odbdoc == 1 || odbdoc == 2) // dow || doc
        ret = true;
    } else {
      if (odbdoc == 1) // dow
        ret = true;
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  // from https://gist.github.com/w3core/e3d9b5b6d69a3ba8671cc84714cca8a4#gistcomment-3125287
  //
  // Calculate brightness value by RGB or HEX color.
  // @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
  // @returns (Number) The brightness value (dark) 0 ... 255 (light)
  public get_color_brightness(csscolor: string): number {
    const color = "" + csscolor;
    const isHEX = color.indexOf("#") == 0;
    const isRGB = color.indexOf("rgb") == 0;
    let r = 0;
    let g = 0
    let b = 0;
    if (isHEX) {
      const hasFullSpec = color.length == 7;
      const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
      if (m) {
        r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
        g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
        b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
      }
    }
    if (isRGB) {
      const m = color.match(/(\d+){3}/g);
      if (m) {
        r = parseInt(m[0]);
        g = parseInt(m[1]);
        b = parseInt(m[2]);
      }
    }
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  }

  // ---------------------------------------------------------------------------
  public get_powrot(filter_wyjpow: number): number {
    return (filter_wyjpow === 0 || filter_wyjpow === 3) ? 1 : 0;
  }

  // ---------------------------------------------------------------------------
  public get_porawyj(filter_wyjpow: number): number {
    return (filter_wyjpow === 0 || filter_wyjpow === 1) ? 1 : 2; // App::PORA_ODJAZDU z autka
  }

  // ---------------------------------------------------------------------------
  public get_country_isocode(country_id: number): string {
    let ret = "???";
    switch (country_id) {
      case 1: ret = "POL"; break;
      case 2: ret = "DEU"; break;
      case 3: ret = "NLD"; break;
      case 5: ret = "BEL"; break;
      case 6: ret = "ROU"; break;
      case 7: ret = "HUN"; break;
      case 8: ret = "SVK"; break;
      case 9: ret = "CZE"; break;
      case 10: ret = "LTU"; break;
      case 11: ret = "LVA"; break;
      case 12: ret = "CHE"; break;
      case 13: ret = "DNK"; break;
      case 14: ret = "LUX"; break;
      case 15: ret = "FRA"; break;
      case 16: ret = "UKR"; break;
      case 17: ret = "AUT"; break;
      case 18: ret = "GBR"; break;
      default:
        ret = "???";
        break;
    }
    return ret;
  }

  // ---------------------------------------------------------------------------
  /**
   * Porownuje odpowiedz z geocodingu HERE z kodem pocztowym z zapytania
   * @param person obiekt typu Person, dane z bazy
   * @param georesult obiekt zwracany przez geocode_result, zawiera dane otrzymane z HERE
   * @returns true jesli mozna zakceptowac georesult jako poprawna odpowiedz HERE lub false jesli nie
   */
  public postal_code_matches(person: Person, georesult: any): boolean {
    // wraunki poczatkowe
    if (!georesult || !person)
      return false;

    // zawsze akceptujemy georesult dla osob bez kodu pocztowego (import z excela)
    if (person.adres_kodpoczt.length == 0)
      return true;

    // nigdy nie akceptujemy, jezeli kraj w zapytaniu jest rozny od kraju w odpowiedzi
    const person_countrycode = this.get_country_isocode(person.adres_kraj_id);
    if (person_countrycode != georesult.CountryCode)
      return false;

    // zawsze akceptujemy odpowiedzi nie z Polski
    if (person_countrycode != "POL")
      return true;

    // jezeli zapytanie i odpowiedz dotycza Polski, porownuje pierwsze trzy cyfry kodu
    const s_code1 = "" + georesult.PostalCode;
    const s_code2 = "" + person.adres_kodpoczt;
    const search_re = /[^\d]/g; // wszystko oprocz cyfr
    const s1 = s_code1.replace(search_re, "")
    const s2 = s_code2.replace(search_re, "")
    if (s1.substring(0, 3) == s2.substring(0, 3))
      return true;
    else
      return false;
  }
}
