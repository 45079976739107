import { Person } from './person';
import { CarTask } from './car-task';
import { AppHelperService } from './_services/app-helper.service';
import * as moment from 'moment';

export class MapPoint {

  persons: Person[] = [];
  cartasks: CarTask[] = [];
  addr1: string;
  addr2: string;
  gps_lat: string;
  gps_lon: string;
  lp_rozp: number;
  total_persons: number;
  filtered_persons: number;
  idx: number; // pole robocze, nie ma go w bazie
  samtyp: number; // pole robocze, nie ma go w bazie
  powrot: boolean;
  time_hour: number; // nie ma w bazie danych
  time_minutes: number;  // nie ma w bazie danych

  // --------------------------------------------------------------------------
  constructor(private apphelper: AppHelperService) {
    this.persons = [];
    this.cartasks = [];
    this.addr1 = '';
    this.addr2 = '';
    this.gps_lat = '';
    this.gps_lon = '';
    this.lp_rozp = 0;
    this.total_persons = 0;
    this.filtered_persons = 0;
    this.idx = 0;
    this.samtyp = 0;
    this.powrot = false;
    this.time_hour = 0;
    this.time_minutes = 0;
  }

  // --------------------------------------------------------------------------
  public clone(): MapPoint {
    let ret = new MapPoint(this.apphelper);
    ret.persons = this.persons.slice();
    ret.cartasks = this.cartasks.slice();
    ret.addr1 = this.addr1;
    ret.addr2 = this.addr2;
    ret.gps_lat = this.gps_lat;
    ret.gps_lon = this.gps_lon;
    ret.lp_rozp = this.lp_rozp;
    ret.total_persons = this.total_persons;
    ret.filtered_persons = this.filtered_persons;
    ret.idx = this.idx;
    ret.samtyp = this.samtyp;
    ret.powrot = this.powrot;
    ret.time_hour = this.time_hour;
    ret.time_minutes = this.time_minutes;
    return ret;
  }

  // --------------------------------------------------------------------------
  public add_person(p: Person) {
    if (p) {
      this.persons.push(p);
      this.update_all();
    }
  }

  // --------------------------------------------------------------------------
  public add_cartask(ct: CarTask) {
    if (ct) {
      this.cartasks.push(ct);
      this.update_all();
    }
  }

  // --------------------------------------------------------------------------
  public is_multiperson(): boolean {
    return this.persons.length > 1;
  }

  // --------------------------------------------------------------------------
  public is_multicartask(): boolean {
    return this.cartasks.length > 1;
  }

  // --------------------------------------------------------------------------
  public has_gps_data(): boolean {
    return this.gps_lat.length !== 0 && this.gps_lon.length !== 0 ? true : false;
  }

  // --------------------------------------------------------------------------
  public update_all() {
    this.refresh_address();
    this.refresh_gps();
    this.refresh_totals();
    this.refresh_lp_rozp();
    this.refresh_time_props();
  }

  // --------------------------------------------------------------------------
  private refresh_lp_rozp() {
    let lp = 0;
    for (const mp of this.persons)
      if (mp.lp_rozp > lp) lp = mp.lp_rozp;
    for (const ct of this.cartasks)
      if (ct.lp_rozp > lp) lp = ct.lp_rozp;
    this.lp_rozp = lp;
  }

  // --------------------------------------------------------------------------
  private refresh_totals() {
    let tot = 0;
    for (const p of this.persons) {
      if (p.is_real_person())
        tot++;
    }
    this.total_persons = tot;
    this.filtered_persons = this.total_persons;
  }

  // --------------------------------------------------------------------------
  private refresh_address() {
    this.addr1 = '';
    this.addr2 = '';
    if (this.persons.length > 0) {
      const s = this.apphelper.get_person_address(this.persons[0], this.samtyp);
      const a = s.split(',');
      for (let i = 0; i < a.length; i++) {
        if (i === 0)
          this.addr1 = a[i];
        else {
          if (i > 1)
            this.addr2 += ', ';
          this.addr2 += a[i];
        }
      }
    }
    else if (this.cartasks.length > 0) {
      const ct = this.cartasks[0];
      this.addr1 = ct.address;
    }
    else {
      this.addr1 = '???';
      this.addr2 = '???';
    }
  }

  // --------------------------------------------------------------------------
  private refresh_gps() {
    if (this.is_multiperson()) {
      this.gps_lat = this.persons[0].gps_lat;
      this.gps_lon = this.persons[0].gps_lon;
    }
    else if (this.is_multicartask()) {
      this.gps_lat = this.cartasks[0].gps_lat;
      this.gps_lon = this.cartasks[0].gps_lon;
    }
    else if (this.cartasks.length > 0) {
      this.gps_lat = this.cartasks[0].gps_lat;
      this.gps_lon = this.cartasks[0].gps_lon;
    }
    else if (this.persons.length > 0) {
      this.gps_lat = this.persons[0].gps_lat;
      this.gps_lon = this.persons[0].gps_lon;
    }
    else {
      console.log('Invalid MapPoint object without data: ', this);
    }
  }

  // --------------------------------------------------------------------------
  private get_godz_sug(): string {
    for (const mp of this.persons) {
      if (mp.in_auto_dow() || (mp.in_auto_doc() && mp.id_auto_dow <= 1)) {
        if (mp.godz_sug.length > 0) {
          return mp.godz_sug;
        }
      }
    }
    for (const ct of this.cartasks) {
      if (ct.godz.length > 0) {
        return ct.godz;
      }
    }
    return '';
  }

  // --------------------------------------------------------------------------
  private get_czas_2(): string {
    for (const mp of this.persons) {
      if (mp.czas_2 && mp.czas_2.length > 0) {
        return mp.czas_2;
      }
    }
    for (const ct of this.cartasks) {
      if (ct.czas_2 && ct.czas_2.length > 0) {
        return ct.czas_2;
      }
    }
    return '';
  }

  // --------------------------------------------------------------------------
  public refresh_time_props() {
    // najpierw z godz_sug
    const godz_sug = this.get_godz_sug();
    if (godz_sug.length > 0) {
      const a = godz_sug.split(':');
      const h = Number(a[0]);
      const m = Number(a[1]);
      if (!isNaN(h) && !isNaN(m)) {
        this.time_hour = h;
        this.time_minutes = m;
        return;
      }
    }
    // jak sie nie dalo, to z czas_2
    const czas_2 = this.get_czas_2();
    if (czas_2 && czas_2.length > 0) {
      const dt = moment(czas_2);
      if (dt.isValid()) {
        this.time_hour = dt.hour();
        this.time_minutes = dt.minute();
        return;
      }
    }
  }

  // --------------------------------------------------------------------------
  private get_hour_string(_hours: number, _minutes: number): string {
    const s_hour = (_hours < 10) ? `0${_hours}` : `${_hours}`;
    const s_min = (_minutes < 10) ? `0${_minutes}` : `${_minutes}`;
    return `${s_hour}:${s_min}`;
  }

  // --------------------------------------------------------------------------
  private change_timestamp(cur_timestamp: string, delta_hours: number, delta_minutes: number): string {
    let ret = cur_timestamp;
    let mom = moment(cur_timestamp, 'YYYY-MM-DD HH:mm:ss');
    if (mom.isValid()) {
      const total_minutes = (delta_hours * 60) + delta_minutes;
      const dur = moment.duration(Math.abs(total_minutes), 'minutes');
      if (total_minutes > 0)
        mom.add(dur);
      else
        mom.subtract(dur);
      ret = mom.format('YYYY-MM-DD HH:mm:00');
    }
    return ret;
  }

  // --------------------------------------------------------------------------
  private update_from_time_props(_hours: number, _minutes: number, _delta_hours: number, _delta_minutes: number) {
    const s_hour = this.get_hour_string(_hours, _minutes);
    for (const mp of this.persons) {
      if ((this.samtyp == 1 && mp.auto_dow_wirt == false) ||
        (this.samtyp == 2 && mp.auto_doc_wirt == false && mp.id_auto_dow <= 1))
        mp.godz_sug = s_hour;
      else
        mp.czas_2 = this.change_timestamp(mp.czas_2, _delta_hours, _delta_minutes);
    }
    for (const ct of this.cartasks) {
      ct.time_hour = _hours;
      ct.time_minutes = _minutes;
      ct.on_time_props_changed(_delta_hours, _delta_minutes);
    }
  }

  // --------------------------------------------------------------------------
  public on_time_props_changed(delta_hours: number, delta_minutes: number) {
    this.update_from_time_props(this.time_hour, this.time_minutes, delta_hours, delta_minutes);
  }
}
