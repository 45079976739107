<div class="container-fluid">
  <div class="row mb-2">
    <div class="col">
      <form class="form-inline">
        <label>Data:</label>
        <div class="form-group ml-2">
          <div class="input-group">
            <app-datepicker-i18n (notify)="onNotify($event)" [model]="filter_date"></app-datepicker-i18n>
          </div>
        </div>
        <label class="ml-2 mr-1">Rodzaj:</label>
        <select class="form-control form-control-sm ml-1" [ngModel]="filter_wyjpow"
          (ngModelChange)="onChangeWyjPow($event)" name="pw">
          <option value="0">powrót po południu</option>
          <option value="3">powrót przed południem</option>
          <option value="1" selected>wyjazd po południu</option>
          <option value="2">wyjazd przed południem</option>
        </select>
        <label class="ml-2 mr-1">Samochód:</label>
        <select class="form-control form-control-sm ml-1" [(ngModel)]="filter_auto" name="aw">
          <option *ngFor="let c of cars" value="{{ c.id }}" [class.selected]="c.id === filter_auto">
            {{ c.name }}
          </option>
        </select>
        <label class="ml-2 mr-1">Rodzaj:</label>
        <select class="form-control form-control-sm ml-1" [ngModel]="filter_sam" (ngModelChange)="onChangeSam($event)"
          name="sam">
          <option value="1" selected>dowożący</option>
          <option value="2">docelowy</option>
          <option value="3">rozwożący</option>
        </select>
        <button type="button" class="btn btn-sm btn-primary ml-2" (click)="showData()">
          Pokaż
        </button>
        <button type="button" class="btn btn-sm btn-primary ml-2" (click)="resetZoom()">
          Powiększenie 100%
        </button>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-3 scrollable">
      <div class="card scrolly">
        <ul class="list-group list-group-flush scrolly" dnd-sortable-container [sortableData]="mappoints">
          <li class="list-group-item list-group-item-action flex-column align-items-start p-0"
            *ngIf="cartask_start != null">
            <app-card-car-task [model]="cartask_start" (onEditCarTask)="editCarTask($event)"></app-card-car-task>
          </li>
          <li class="list-group-item list-group-item-action flex-column align-items-start p-0"
            *ngFor="let mp of mappoints; index as i" dnd-sortable [sortableIndex]="i" [dragData]="mp"
            (onDropSuccess)="onDrop($event, i)" title="Przeciągnij i upuść myszką, żeby zmienić kolejność">
            <app-card-map-point [model]="mp" [lp_rozp]="i + 1"></app-card-map-point>
          </li>
          <li class="list-group-item list-group-item-action flex-column align-items-start p-0"
            *ngIf="cartask_stop != null">
            <app-card-car-task [model]="cartask_stop" (onEditCarTask)="editCarTask($event)"></app-card-car-task>
          </li>
        </ul>
        <div class="card-footer detail-list-footer">
          <div class="form-check form-check-inline mb-1 ml-1">
            Uwzgl. ruch drogowy
            <input class="form-check-input position-static ml-1" type="checkbox" [(ngModel)]="use_traffic_time" />
          </div>
          <div class="form-inline mb-1 ml-1">
            <label>Data {{ label_start_stop_date }}</label>
            <input type="date" [ngModel]="start_stop_date | date:'YYYY-MM-dd'"
              (ngModelChange)="onChangeStartStopDate($event)" name="start_stop_date" id="start_stop_date"
              class="form-control form-control-sm ml-1" />
          </div>
          <div>
            <button class="btn btn-sm btn-outline-info mb-1 ml-1 stop-start" *ngIf="show_stop_start()"
              (click)="redrawRouteBackward()">
              Przelicz STOP->START
            </button>
            <button class="btn btn-sm btn-outline-info mb-1 ml-1 start-stop" *ngIf="show_start_stop()"
              (click)="redrawRouteForward()">
              Przelicz START->STOP
            </button>
          </div>
          <button class="btn btn-sm btn-primary mt-1 mb-1 ml-1" (click)="saveChanges()">
            Zapisz zmiany
          </button>
          <br />
          Ilość osób: {{ total_persons }} <br />
          Trasa:
          <span *ngIf="route_distance.length > 0"> {{ route_distance }} km</span>
          <span *ngIf="route_time.length > 0" class="stop-start"> {{ route_time }} min</span>
          <span *ngIf="route_time_2.length > 0" class="start-stop">
            {{ route_time_2 }} min</span>
        </div>
      </div>
    </div>
    <div class="col-9 scrollable scrolly">
      <div id="themap"></div>
    </div>
  </div>
</div>
<app-modal-change-car #modalChangeCar [person_name]="selected_person_name" [person_car]="selected_person_car"
  [map_cars]="map_cars" [selected_car_id]="last_sel_car_id"></app-modal-change-car>
<app-modal-map-point-position #modalMapPointPosition></app-modal-map-point-position>
<app-modal-edit-car-task #modalEditCarTask [stoppoints]="stop_points" [transferpoints]="transfer_points"
  [cartask_name]="selected_cartask_name"></app-modal-edit-car-task>
<app-modal-change-colour #modalChangeColour [preset_colors]="css_colors"></app-modal-change-colour>