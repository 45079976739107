import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppVersion } from '../app-version';
import { SharedDataService } from '../_services/shared-data.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public app_name = AppVersion.name;
  public app_version = AppVersion.version;
  public app_date = AppVersion.release_date;
  public version_info = '';
  public author_info = '';
  public production = true;

  constructor(
    public router: Router,
    public vcRef: ViewContainerRef, // dla Color Picker uzywanego w master.component,
    private shared_data: SharedDataService
  ) {
    this.version_info = `wersja ${this.app_version} z dnia ${this.app_date}`;
    this.production = environment.production;

    const first_year = 2018;
    const d = new Date();
    const current_year: number = d.getFullYear();
    this.author_info = current_year !== first_year ? `${first_year}-${current_year}` : `${first_year}`;
    this.author_info += ' WARE';
  }

  public conn_info_html(): string {
    let ret = '';

    const _dbname = this.shared_data.db_name;
    if (_dbname.length > 0) {
      ret = 'Baza danych: <b>' + _dbname + '</b>';
      const _username = this.shared_data.user_name;
      if (_username.length > 0)
        ret += ', Użytkownik: ' + _username;
      ret += ' &middot;'
    }

    return ret;
  }
}
