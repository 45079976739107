import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from './../environments/environment'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    // const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    // if (chunkFailedMessage.test(error.message)) {
    //   window.location.reload();
    // }
    // else {
    if (environment.production)
      Sentry.captureException(error.originalError || error);
    else
      console.error(error);
    //}
  }
}
