import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { AppVersion } from './app/app-version';

Sentry.init({
  dsn: "https://1dbb83f15bb24b409f005798472bf14a@o475948.ingest.sentry.io/4504544545865728",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["http://gtvmap.local", "https://mapa1.gtvbus.pl", "https://mapa2.gtvbus.pl"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,

  // release
  release: AppVersion.version,
});

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
