import { Component, OnInit, Input } from '@angular/core';
import { MapPoint } from '../map-point';
import { AppHelperService } from '../_services/app-helper.service';

@Component({
  selector: 'app-card-map-point',
  templateUrl: './card-map-point.component.html',
  styleUrls: ['./card-map-point.component.css']
})
export class CardMapPointComponent implements OnInit {

  @Input() model: MapPoint;
  @Input() lp_rozp: number;

  public is_valid_hour = true;
  public is_valid_minutes = true;

  // --------------------------------------------------------------------------
  private validate_hour(val: number): boolean {
    return val >= 0 && val < 24;
  }

  // --------------------------------------------------------------------------
  private validate_minutes(val: number): boolean {
    return val >= 0 && val < 60;
  }

  // --------------------------------------------------------------------------
  constructor(private apphelper: AppHelperService) { }

  // --------------------------------------------------------------------------
  ngOnInit() {
    this.model.refresh_time_props();
  }

  // --------------------------------------------------------------------------
  public onTimeHourChange(new_val: any) {
    const new_hour = Number(new_val);
    this.is_valid_hour = this.validate_hour(new_hour);
    if (this.is_valid_hour) {
      const delta_hour = new_hour - this.model.time_hour;
      this.model.time_hour = new_hour;
      this.model.on_time_props_changed(delta_hour, 0);
    }
  }

  // --------------------------------------------------------------------------
  public onTimeMinutesChange(new_val) {
    const new_min = Number(new_val);
    this.is_valid_minutes = this.validate_minutes(new_min);
    if (this.is_valid_minutes) {
      const delta_minutes = new_min - this.model.time_minutes;
      this.model.time_minutes = new_min;
      this.model.on_time_props_changed(0, delta_minutes);
    }
  }
}
