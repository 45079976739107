import { Component, OnInit, Input } from '@angular/core';
import { TransferPoint } from '../transfer-point';

@Component({
  selector: 'app-card-transfer-point',
  templateUrl: './card-transfer-point.component.html',
  styleUrls: ['./card-transfer-point.component.css']
})
export class CardTransferPointComponent implements OnInit {

  @Input() model: TransferPoint;

  constructor() { }

  ngOnInit() {
  }
}
