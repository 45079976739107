export class TransferPoint {
  id: number;
  nazwa: string;
  miejscowosc: string;
  adres: string;
  gps_lat: string;
  gps_lon: string;

  constructor(obj: any) {
    this.id = obj.ID;
    this.nazwa = obj.NAZWA;
    this.miejscowosc = obj.MIEJSCOWOSC;
    this.adres = obj.NAZWA;
    this.gps_lat = obj.GPS_LAT;
    this.gps_lon = obj.GPS_LON;
  }

  public static createObject(obj: any): TransferPoint {
    return new TransferPoint(obj);
  }

  public has_gps_data(): boolean {
    return this.gps_lat.length !== 0 && this.gps_lon.length !== 0 ? true : false;
  }
}
