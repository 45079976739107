export class Person {

  godz: string;
  godz_sug: string;
  id: number;
  id_auto: number;
  id_odjazd: number;
  imie: string;
  lp_rozp: number;
  miejscowosc: string;
  adr1: string;
  adr2: string;
  nazwisko: string;
  przesiadka: number;
  powrot: number;
  gps_lat: string;
  gps_lon: string;
  czas_2: string;
  data: string;
  pin_kolor: string;
  id_auto_dow: number;
  id_auto_doc: number;
  id_auto_roz: number;
  auto_dow_wirt: boolean;
  auto_doc_wirt: boolean;
  auto_roz_wirt: boolean;
  rejon: string;
  kraj: string;
  adres_kodpoczt: string;
  adres_miejsc: string;
  adres_ulica: string;
  adres_nrdomu: string;
  adres_kraj: string;
  adres_kraj_id: number;

  // --------------------------------------------------------------------------
  constructor() {
    this.godz = '';
    this.godz_sug = '';
    this.id = -1;
    this.id_auto = -1;
    this.id_odjazd = -1;
    this.imie = '';
    this.lp_rozp = -1;
    this.miejscowosc = '';
    this.adr1 = '';
    this.adr2 = '';
    this.nazwisko = '';
    this.przesiadka = -1;
    this.powrot = -1;
    this.gps_lat = '';
    this.gps_lon = '';
    this.czas_2 = '';
    this.data = '';
    this.pin_kolor = '';
    this.id_auto_dow = -1;
    this.id_auto_doc = -1;
    this.id_auto_roz = -1;
    this.auto_dow_wirt = false;
    this.auto_doc_wirt = false;
    this.auto_roz_wirt = false;
    this.rejon = '';
    this.kraj = '';
    this.adres_kodpoczt = '';
    this.adres_miejsc = '';
    this.adres_ulica = '';
    this.adres_nrdomu = '';
    this.adres_kraj = '';
    this.adres_kraj_id = -1;
  }

  // --------------------------------------------------------------------------
  public static createObject(obj: any, samtyp: number): Person {
    const ret = new Person();
    ret.godz = obj.GODZ;
    ret.godz_sug = obj.GODZ_SUG;
    ret.id = Number(obj.ID);
    ret.imie = obj.IMIE;
    ret.miejscowosc = obj.MIEJSCOWOSC;
    ret.id_odjazd = Number(obj.ID_ODJAZD);
    ret.nazwisko = obj.NAZWISKO;
    ret.przesiadka = Number(obj.PRZESIADKA);
    ret.powrot = Number(obj.POWROT);
    ret.data = obj.DATA;
    ret.pin_kolor = obj.PIN_KOLOR ? obj.PIN_KOLOR : '';
    ret.id_auto_dow = Number(obj.ID_AUTO_DOW);
    ret.id_auto_doc = Number(obj.ID_AUTO_DOC);
    ret.id_auto_roz = Number(obj.ID_AUTO_ROZ);
    ret.auto_dow_wirt = obj.AUTO_DOW_WIRT == 1 ? true : false;
    ret.auto_doc_wirt = obj.AUTO_DOC_WIRT == 1 ? true : false;
    ret.auto_roz_wirt = obj.AUTO_ROZ_WIRT == 1 ? true : false;

    // Kopia logiki z programu Przewóz Osób (autko): ModelRozpiskaEdit::choose_fields
    switch (samtyp) {
      case 1: // dow
        ret.id_auto = Number(obj.ID_AUTO_DOW);
        ret.lp_rozp = Number(obj.LP_ROZP_DOW);
        ret.adr1 = obj.MSC_ODB1;
        ret.adr2 = obj.MSC_ODB2;
        ret.gps_lat = obj.GPS_LAT_ODB;
        ret.gps_lon = obj.GPS_LON_ODB;
        ret.czas_2 = obj.CZAS_ODB_2;
        ret.adres_kodpoczt = obj.KODPOCZT_ODB;
        ret.adres_miejsc = obj.MIEJSC_ODB;
        ret.adres_ulica = obj.ULICA_ODB;
        ret.adres_nrdomu = obj.NRDOMU_ODB;
        ret.adres_kraj = obj.KRAJ_ODB;
        ret.adres_kraj_id = obj.ID_KRAJ_ODB;
        break;
      case 2: // doc
        ret.id_auto = Number(obj.ID_AUTO_DOC);
        ret.lp_rozp = Number(obj.LP_ROZP_DOC);
        ret.czas_2 = obj.CZAS_DOC_2;
        if (ret.powrot === 1) {
          ret.adr1 = obj.MSC_ODB1;
          ret.adr2 = obj.MSC_ODB2;
          ret.gps_lat = obj.GPS_LAT_ODB;
          ret.gps_lon = obj.GPS_LON_ODB;
          ret.adres_kodpoczt = obj.KODPOCZT_ODB;
          ret.adres_miejsc = obj.MIEJSC_ODB;
          ret.adres_ulica = obj.ULICA_ODB;
          ret.adres_nrdomu = obj.NRDOMU_ODB;
          ret.adres_kraj = obj.KRAJ_ODB;
          ret.adres_kraj_id = obj.ID_KRAJ_ODB;
        }
        else {
          ret.adr1 = obj.MSC_DOC1;
          ret.adr2 = obj.MSC_DOC2;
          ret.gps_lat = obj.GPS_LAT_DOC;
          ret.gps_lon = obj.GPS_LON_DOC;
          ret.adres_kodpoczt = obj.KODPOCZT_DOC;
          ret.adres_miejsc = obj.MIEJSC_DOC;
          ret.adres_ulica = obj.ULICA_DOC;
          ret.adres_nrdomu = obj.NRDOMU_DOC;
          ret.adres_kraj = obj.KRAJ_DOC;
          ret.adres_kraj_id = obj.ID_KRAJ_DOC;
        }
        break;
      case 3: // roz
        ret.id_auto = Number(obj.ID_AUTO_ROZ);
        ret.lp_rozp = Number(obj.LP_ROZP_ROZ);
        ret.adr1 = obj.MSC_DOC1;
        ret.adr2 = obj.MSC_DOC2;
        ret.gps_lat = obj.GPS_LAT_DOC;
        ret.gps_lon = obj.GPS_LON_DOC;
        ret.czas_2 = obj.CZAS_ROZ_2;
        ret.adres_kodpoczt = obj.KODPOCZT_DOC;
        ret.adres_miejsc = obj.MIEJSC_DOC;
        ret.adres_ulica = obj.ULICA_DOC;
        ret.adres_nrdomu = obj.NRDOMU_DOC;
        ret.adres_kraj = obj.KRAJ_DOC;
        ret.adres_kraj_id = obj.ID_KRAJ_DOC;
        break;
    }
    return ret;
  }

  // --------------------------------------------------------------------------
  public has_gps_data(): boolean {
    return this.gps_lat.length !== 0 && this.gps_lon.length !== 0 ? true : false;
  }

  // --------------------------------------------------------------------------
  public is_inne(): boolean {
    const s = this.adr1.toLocaleUpperCase();
    return s.startsWith('INNE');
  }

  // --------------------------------------------------------------------------
  public is_real_person(): boolean {
    const s = this.nazwisko;
    return (s.startsWith('*') || s.startsWith('!')) ? false : true;
  }

  // --------------------------------------------------------------------------
  public in_auto_dow(): boolean {
    return this.id_auto == this.id_auto_dow;
  }

  // --------------------------------------------------------------------------
  public in_auto_doc(): boolean {
    return this.id_auto == this.id_auto_doc;
  }

  // --------------------------------------------------------------------------
  public in_auto_roz(): boolean {
    return this.id_auto == this.id_auto_roz;
  }

  // --------------------------------------------------------------------------
  public dump(): string {
    return `${this.nazwisko} ${this.imie} | ${this.id} | ${this.adr1} | ${this.adr2} | ${this.miejscowosc}`;
  }
}
