<div class="bigone">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-2" *ngIf="router.url !==  '/login'">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      <div class="navbar-brand">
        <img src="/assets/logo_gtvbus.png" height="30px">
      </div>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <a class="nav-link" routerLink="/przeglad" routerLinkActive="active">Widok ogólny</a>
        <a class="nav-link" routerLink="/szczegoly" routerLinkActive="active">Widok szczegółowy</a>
        <a class="nav-link" routerLink="/punkty" routerLinkActive="active">Punkty zbiórki i przesiadki</a>
        <a class="nav-link" routerLink="/ustawienia" routerLinkActive="active">Ustawienia</a>
        <a class="nav-link" routerLink="/testing" routerLinkActive="active" *ngIf="!production">Testing</a>
        <a class="nav-link" routerLink="/login" routerLinkActive="active">Wyloguj</a>
      </ul>
    </div>
  </nav>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <div class="footer align-middle">{{app_name}} &middot; {{version_info}} &middot;
    <span [innerHTML]="conn_info_html()"></span> {{author_info}}
  </div>
</div>