import { Car } from './car';

export class CarInfo {
  car: Car;
  count: number;
  colour: string;
  visible: boolean;
  no_gps_count: number;
  polyline_id: number;

  constructor(_car: Car, _count: number, _colour: string = '', _visible = true, _no_gps_count: number = 0, _polyline_id = -1) {
    this.car = _car;
    this.count = _count;
    this.colour = _colour;
    this.visible = _visible;
    this.no_gps_count = _no_gps_count;
    this.polyline_id = _polyline_id;
  }
}
