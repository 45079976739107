<div class="container-fluid">
  <div class="row mb-2">
    <div class="col">
      <form class="form-inline">
        <label>Data:</label>
        <div class="form-group ml-2">
          <div class="input-group">
            <app-datepicker-i18n (notify)="onNotify($event)" [model]="filter_date"></app-datepicker-i18n>
          </div>
        </div>
        <label class="ml-2 mr-1">Rodzaj:</label>
        <select class="form-control form-control-sm ml-1" [ngModel]="filter_wyjpow"
          (ngModelChange)="onChangeWyjPow($event)" name="pw">
          <option value="0">powrót po południu</option>
          <option value="3">powrót przed południem</option>
          <option value="1" selected>wyjazd po południu</option>
          <option value="2">wyjazd przed południem</option>
        </select>
        <label class="ml-2 mr-1">Samochód:</label>
        <select class="form-control form-control-sm ml-1" [ngModel]="filter_sam" (ngModelChange)="onChangeSam($event)"
          name="sam">
          <option value="1" selected>dowożący</option>
          <option value="2">docelowy</option>
          <option value="3">rozwożący</option>
        </select>
        <label class="ml-2 ml-2 mr-1">Kolor:</label>
        <select class="form-control form-control-sm ml-1" [ngModel]="filter_color"
          (ngModelChange)="onChangeFilterColor($event)" name="kol">
          <option value="all" selected>dowolny</option>
          <option value="#000000" style="background-color: #000000; color: white;">czarny</option>
          <option value="#c0c0c0" style="background-color: #c0c0c0; color: white;">srebrny</option>
          <option value="#808080" style="background-color: #808080; color: white;">szary</option>
          <option value="#800000" style="background-color: #800000; color: white;">bordowy</option>
          <option value="#ff0000" style="background-color: #ff0000; color: white;">czerwony</option>
          <option value="#ffa500" style="background-color: #ffa500; color: white;">pomarańczowy</option>
          <option value="#800080" style="background-color: #800080; color: white;">purpurowy</option>
          <option value="#ff00ff" style="background-color: #ff00ff; color: white;">fuksja</option>
          <option value="#008000" style="background-color: #008000; color: white;">zielony</option>
          <option value="#00ff00" style="background-color: #00ff00">limonkowy</option>
          <option value="#808000" style="background-color: #808000; color: white;">oliwkowy</option>
          <option value="#ffff00" style="background-color: #ffff00">żółty</option>
          <option value="#000080" style="background-color: #000080; color: white;">granatowy</option>
          <option value="#0000ff" style="background-color: #0000ff; color: white;">niebieski</option>
          <option value="#008080" style="background-color: #008080; color: white;">morski</option>
          <option value="#00ffff" style="background-color: #00ffff">turkusowy</option>
        </select>
        <button type="button" class="btn btn-sm btn-primary ml-2 mr-4" (click)="showData()">Pokaż</button>
        <button type="button" class="btn btn-sm btn-primary ml-4" (click)="resetZoom()">Powiększenie 100%</button>

        <button type="button" class="btn btn-sm btn-primary ml-auto mr-1" (click)="addCar()">Dodaj auto</button>
        <button type="button" class="btn btn-sm btn-primary mr-1" (click)="delCar()">Usuń auto</button>
      </form>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-3 col-xl-2 scrollable">
      <div class="card scrolly">
        <ul class="list-group list-group-flush scrolly">
          <div class="list-group-item list-group-item-action d-flex align-items-left px-1 py-2 card-carinfo"
            *ngFor="let c of car_info">
            <div class="form-check form-check-inline m-0">
              <input class="form-check-input position-static" type="checkbox" [(ngModel)]="c.visible"
                (ngModelChange)="onFilterCar(c.car.id)">
            </div>
            <div>
              <a [routerLink]="['/szczegoly', {data: get_filter_date_str(), wyjpow: filter_wyjpow, samtyp: filter_sam, car_id: c.car.id}]"
                routerLinkActive="active" class="car-link" title="Kliknij, żeby przejść do widoku szczegółowego">
                {{c.car.name}}
              </a>
            </div>
            <div class="ml-auto mr-1">
              <span *ngIf="c.no_gps_count > 0" class="badge badge-pill badge-danger">GPS</span>
              <span class="badge badge-pill badge-light" role="button" (click)="showCarPersons(c.car.id)"
                title="Edycja listy osób w aucie">+</span>
              <span *ngIf="polylines_on" class="badge badge-pill" role="button" [style.background]="c.colour"
                [(colorPicker)]="c.colour" [cpCloseClickOutside]="false" [cpOKButton]="true" [cpOKButtonText]="'Zmień'"
                [cpOKButtonClass]="'btn btn-sm btn-outline-secondary'" [cpCancelButton]="true"
                [cpCancelButtonText]="'Anuluj'" [cpCancelButtonClass]="'btn btn-sm btn-outline-secondary'"
                [cpUseRootViewContainer]="true" [cpAlphaChannel]="'disabled'" [cpPosition]="'right'"
                [cpPositionRelativeToArrow]="true" [cpPositionOffset]="'50'" [cpPresetColors]="css_colors"
                [cpPresetLabel]="''" [cpDisableInput]="true" (colorPickerChange)="onChangeColour(c.car.id, $event)"
                (colorPickerSelect)="onSelectColour(c.car.id, $event)"
                (colorPickerOpen)="onColorPickerOpen(c.car.id, $event)"
                (colorPickerClose)="onColorPickerClose(c.car.id, $event)" title="Zmiana koloru linii">
                &nbsp;&nbsp;
              </span>
              <span class="badge badge-pill badge-light">{{c.count}}</span>
            </div>
          </div>
        </ul>
        <div class="card-footer">
          <div class="mx-auto">
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" (click)="checkAllCars()">Zaznacz wsz.</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" (click)="uncheckAllCars()">Odznacz wsz.</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="!polylines_on"
              (click)="showPolylines()">Połącz osoby</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="polylines_on"
              (click)="hidePolylines()">Ukryj połączenia</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="sel_car_rect == null"
              (click)="showMapSelection()">Zaznacz obszar</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="sel_car_rect != null"
              (click)="hideMapSelection()">Schowaj zaznaczenie</button>
            <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="sel_car_rect != null"
              (click)="changePersonsColorFromSelection()">Przypisz kolor</button>
          </div>
          Ilość osób: {{total_persons}}
          <br> W samochodzie: {{total_persons_assigned}}
          <br> Bez samochodu: {{total_persons_not_assigned}}
          <br>
          <button type="button" class="btn btn-outline-primary btn-sm ml-auto"
            (click)="isCollapsedGPSError = !isCollapsedGPSError; isCollapsedAppError=true;">
            Brak GPS <span *ngIf="gps_errors.length>0">({{gps_errors.length}})</span>
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm ml-auto"
            (click)="isCollapsedAppError = !isCollapsedAppError; isCollapsedGPSError=true;">
            Błędy <span *ngIf="app_errors.length>0">({{app_errors.length}})</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="carPersonsEnabled" class="col-2 col-xl-2 car_persons card scrollable">
      <div class="pl-2 py-1 bg-light border-bottom">
        <span class="badge badge-pill badge-secondary mr-2 mt-2 float-right font-weight-bold" role="button"
          data-container="body" [ngbPopover]="helpContent" placement="bottom">?</span>
        <h5 class="card-title">Przypisanie osób do auta</h5>
        <p class="mb-0 pb-0"><b>{{sel_car?.name}}</b></p>
      </div>
      <ul class="list-group list-group-flush car_persons_list scrolly">
        <div class="list-group-item list-group-item-action d-flex align-items-left border-0 px-1 py-1"
          *ngFor="let p of sel_car_persons">
          <div>{{p.nazwisko}} {{p.imie}}</div>
          <div class="ml-auto mr-1">
            <span class="badge badge-pill badge-light" role="button" (click)="removeCarPersonsItem(p.id)"
              title="Usuń osobę z listy">x</span>
          </div>
        </div>
      </ul>
      <div class="card-footer car_persons_footer">
        <div class="mx-auto">
          <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" (click)="removeCarPersonsAll()">Usuń
            wszystko</button>
          <br>
          <button class="btn btn-sm btn-outline-secondary mb-1 ml-1" *ngIf="sel_car_rect != null"
            (click)="addCarPersonsFromSelection()">Dodaj z obszaru</button>
          <br>
          <button class="btn btn-sm btn-outline-primary mb-1 ml-1" [disabled]="!sel_car_dirty"
            (click)="saveCarPersons()">Zapisz zmiany</button>
          <button class="btn btn-sm btn-outline-primary mb-1 ml-1" (click)="cancelCarPersons()">Anuluj</button>
        </div>
      </div>
    </div>
    <div
      [ngClass]="{'col-7 col-xl-8 scrollable scrolly': carPersonsEnabled, 'col-9 col-xl-10 scrollable scrolly': !carPersonsEnabled}">
      <div id="themap" [ngClass]="{'smaller': !isCollapsedGPSError, 'smaller2': !isCollapsedAppError}"></div>
      <div id="gps_errors" class="collapse mt-2" [ngbCollapse]="isCollapsedGPSError"
        [ngStyle]="isCollapsedGPSError && {height: '0'}" [ngStyle]="!isCollapsedGPSError && {height: '200px'}">
        <table>
          <tr>
            <th>Osoba / Zadanie</th>
            <th>Adres</th>
            <th>Samochód</th>
          </tr>
          <tr *ngFor="let gpserr of gps_errors">
            <td>{{gpserr.name}}</td>
            <td>{{gpserr.addr}}</td>
            <td>{{gpserr.car}}</td>
          </tr>
        </table>
      </div>
      <div id="app_errors" class="collapse mt-2" [ngbCollapse]="isCollapsedAppError"
        [ngStyle]="isCollapsedAppError && {height: '0'}" [ngStyle]="!isCollapsedAppError && {height: '200px'}">
        <ul class="list-group list-group-flush">
          <div class="list-group-item align-items-left" *ngFor="let apperr of app_errors" [innerHTML]="apperr">
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-modal-change-car #modalChangeCar [person_name]="selected_person_name" [person_car]="selected_person_car"
  [map_cars]="map_cars" [selected_car_id]="last_sel_car_id"></app-modal-change-car>
<app-modal-add-car #modalAddCar [filter_date]="filter_date" [filter_wyjpow]="filter_wyjpow"
  [filter_samtyp]="filter_sam"></app-modal-add-car>
<app-modal-map-point-position #modalMapPointPosition></app-modal-map-point-position>
<app-modal-del-car #modalDelCar [filter_date]="filter_date" [filter_wyjpow]="filter_wyjpow"
  [filter_samtyp]="filter_sam"></app-modal-del-car>
<app-modal-change-colour #modalChangeColour [preset_colors]="css_colors"></app-modal-change-colour>

<ng-template #helpContent>
  <h3>Pomoc - przypisanie osób do auta</h3>
  <p>Edycja osób w samochodzie polega na przygotowaniu listy osób oraz zapisaniu zmian do bazy danych</p>
  <p>Zapis nowej listy osób do bazy danych odbywa się <b>dopiero po kliknięciu w przycisk</b>
    <span class="btn btn-sm btn-outline-secondary mx-1">Zapisz zmiany</span>
  </p>
  <p>Przygotowanie listy osób w samochodzie polega na dodawaniu i usuwaniu osób wskazanych na mapie.</p>
  <p>Osoby można dodawać do listy:</p>
  <ul>
    <li>pojedynczo - poprzez kliknięcie lewym przyciskiem myszy na ikonie/pinezce na mapie</li>
    <li>grupowo - poprzez dodanie osób należących do zaznaczonego obszaru na mapie:
      <ul>
        <li>klikamy w przycisk <span class="btn btn-sm btn-outline-secondary mx-1">Zaznacz obszar</span></li>
        <li>na mapie wyświetlany jest prostokąt służący do zaznaczania osób</li>
        <li>dostosowaujemy rozmiar i położenie prostokąta obejmując nim grupę osób, które chcemy dodać</li>
        <li>klikamy w przycisk <span class="btn btn-sm btn-outline-secondary mx-1">Dodaj z obszaru</span></li>
        <li>klikamy w przycisk <span class="btn btn-sm btn-outline-secondary mx-1">Schowaj zaznaczenie</span></li>
      </ul>
    </li>
  </ul>
  <p>Osoby można usuwać z listy:</p>
  <ul>
    <li>pojedynczo - poprzez kliknięcie lewym przyciskiem myszy na ikonie
      <span class="badge badge-pill badge-light">x</span> przy nazwisku osoby na liście
    </li>
    <li>grupowo - poprzez klknięcie w przycisk <span class="btn btn-sm btn-outline-secondary mx-1">Usuń wszystko</span>
    </li>
  </ul>
  <p>Uwaga! Zmiany są zapisywane do bazy danych <b>dopiero po kliknięciu w przycisk "Zapisz zmiany"</b>.
    Chcąc wycofać się z wprowadzonych zmian wystarczy kliknąć w przycisk <span
      class="btn btn-sm btn-outline-primary mx-1">Anuluj</span>
    lub przeładować stronę.</p>
</ng-template>