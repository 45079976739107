<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-lg-4 offset-lg-4 col-6 offset-3">
      <div class="card w-100 mt-4 bg-light">
        <img src="/assets/logo_gtvbus.png" class="card-img-top w-50 mx-auto my-4">
        <div class="card-body">
          <h5 class="card-title mb-5 mx-auto text-center">Aplikacja do planowania przewozów</h5>
          <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
            <div class="form-group row" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
              <label for="username" class="col-4 col-form-label">Użytkownik:</label>
              <div class="col-8">
                <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel"
                  required />
                <div *ngIf="f.submitted && !username.valid" class="help-block">Podaj nazwę użytkownika</div>
              </div>
            </div>
            <div class="form-group row" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
              <label for="password" class="col-4 col-form-label">Hasło:</label>
              <div class="col-8">
                <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                  #password="ngModel" required />
                <div *ngIf="f.submitted && !password.valid" class="help-block">Podaj hasło</div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-error': f.submitted && !dbname.valid }">
              <label for="dbname" class="col-4 col-form-label">Baza danych:</label>
              <div class="col-8">
                <select class="form-control" name="dbname" [(ngModel)]="model.dbname" #dbname="ngModel" required>
                  <option value="gtv" selected>gtv</option>
                  <option value="gtv-ro">gtv-ro</option>
                  <option value="gtv-nl">gtv-nl</option>
                  <option value="szkolenie">szkolenie</option>
                  <option value="gtv-rc">gtv-rc</option>
                </select>
                <div *ngIf="f.submitted && !dbname.valid" class="help-block">Wybierz bazę danych</div>
              </div>
            </div>

            <div class="form-group row mt-4">
              <button [disabled]="loading" class="btn btn-primary mx-auto">Zaloguj</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <app-alert></app-alert>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col text-center">
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
  </div>
</div>