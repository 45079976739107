<div id="test">
    <h3>Testing mode: {{appmode}}</h3>
    <p>Test geocoding:</p>
    <div class="m-1">
        <input type="text" [(ngModel)]="addr_free_text" placeholder="free text address" id="free_text">
        <button class="btn btn-primary ml-2" (click)="searchFreeText()">Wyszukaj</button>
    </div>
    <div class="m-1">
        <input type="text" [(ngModel)]="addr_kod" placeholder="kod">
        <input type="text" [(ngModel)]="addr_miejsc" placeholder="miejscowosc">
        <input type="text" [(ngModel)]="addr_ulica" placeholder="ulica">
        <input type="text" [(ngModel)]="addr_nrdomu" placeholder="nr domu">
        <input type="text" [(ngModel)]="addr_kraj" placeholder="kraj">
        <button class="btn btn-primary ml-2" (click)="searchAddress()">Wyszukaj</button>
    </div>

    <div id="themap"></div>
    <br clear="both">
</div>
