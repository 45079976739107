import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { GlobalErrorHandler } from './global.error.handler';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DndModule } from 'ng2-dnd';
import { ColorPickerModule } from 'ngx-color-picker';

import { DataService } from './_services/data.service';
import { HereMapsService } from './_services/heremaps.service';
import { AppHelperService } from './_services/app-helper.service';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { SharedDataService } from './_services/shared-data.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { MasterComponent } from './master/master.component';
import { DetailComponent } from './detail/detail.component';
import { ModalErrorsComponent } from './modal-errors/modal-errors.component';
import { DatepickerI18nComponent } from './datepicker-i18n/datepicker-i18n.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';
import { AuthGuard } from './_services/auth.guard';
import { PointsComponent } from './points/points.component';
import { ModalPointPositionComponent } from './modal-point-position/modal-point-position.component';
import { CardStopPointComponent } from './card-stop-point/card-stop-point.component';
import { CardTransferPointComponent } from './card-transfer-point/card-transfer-point.component';
import { CardMapPointComponent } from './card-map-point/card-map-point.component';
import { CardCarTaskComponent } from './card-car-task/card-car-task.component';
import { ModalMapPointPositionComponent } from './modal-map-point-position/modal-map-point-position.component';
import { SettingsComponent } from './settings/settings.component';
import { ErrorComponent } from './error/error.component';
import { AdminGuard } from './_services/admin.guard';
import { ModalAddCarComponent } from './modal-add-car/modal-add-car.component';
import { ModalChangeCarComponent } from './modal-change-car/modal-change-car.component';
import { ModalEditCarTaskComponent } from './modal-edit-car-task/modal-edit-car-task.component';
import { ModalDelCarComponent } from './modal-del-car/modal-del-car.component';
import { ModalChangeColourComponent } from './modal-change-colour/modal-change-colour.component';
import { TestingComponent } from './testing/testing.component';
import { Router } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    DndModule.forRoot(),
    ColorPickerModule
  ],
  declarations: [
    AppComponent,
    MasterComponent,
    DetailComponent,
    ModalErrorsComponent,
    DatepickerI18nComponent,
    LoginComponent,
    ModalChangeCarComponent,
    AlertComponent,
    PointsComponent,
    ModalPointPositionComponent,
    CardStopPointComponent,
    CardTransferPointComponent,
    CardMapPointComponent,
    CardCarTaskComponent,
    ModalMapPointPositionComponent,
    SettingsComponent,
    ErrorComponent,
    ModalAddCarComponent,
    ModalEditCarTaskComponent,
    ModalDelCarComponent,
    ModalChangeColourComponent,
    TestingComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
      // useValue: Sentry.createErrorHandler({
      //  showDialog: false, logErrors: true
      //}),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DataService,
    HereMapsService,
    AppHelperService,
    AlertService,
    AuthenticationService,
    SharedDataService,
    AuthGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(trace: Sentry.TraceService) { }
}
