import { Injectable } from '@angular/core';
import { HereMapsService } from './heremaps.service';
import { AppHelperService } from './app-helper.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Address } from '../address';

declare var H: any;

@Injectable({
  providedIn: 'root'
})
export class HereMapsTestingService extends HereMapsService {

  constructor(
    private apphelper_testing: AppHelperService
  ) {
    super(apphelper_testing);
  }

  // --------------------------------------------------------------------
  protected getHereApiKey(): any {
    let ret: any = null;
    if (!environment.production) {
      console.log('HereMapsTestingService.getHereApiKey: using hardcoded api key');
      ret = 'uCrkvmThJD0BeH2O7oO0sQ6gXZx9f65sCsjMgtmjMDY';
    } else {
      console.log('HereMapsService.getHereApiKey: called in production mode not allowed');
    }
    return ret;
  }

  // --------------------------------------------------------------------
  public addRouteShapeToMap_v7(mapobj: any, route: any): void {
    const lineString = new H.geo.LineString();
    const routeShape = route.shape;
    routeShape.forEach(function (point) {
      const parts = point.split(',');
      lineString.pushLatLngAlt(parts[0], parts[1]);
    });
    const polyline = new H.map.Polyline(lineString, {
      style: {
        lineWidth: 4,
        strokeColor: 'rgba(0, 128, 255, 0.7)'
      }
    });
    // Add the polyline to the map
    mapobj.addObject(polyline);
    // And zoom to its bounding rectangle
    mapobj.getViewModel().setLookAtData({ bounds: polyline.getBoundingBox() });
  }

  // --------------------------------------------------------------------
  public addRouteShapeToMap_v8(mapobj: any, route: any): void {
    return this.addRouteShapeToMap(mapobj, route);
  }

  // --------------------------------------------------------------------
  public getRouteSummary_v7(waypoints: [string, string][], dt_start: Date, use_traffic: boolean, route_type: string): Promise<any> {
    const platform = this.platform;
    const router = platform.getRoutingService();
    const traffic_mode = use_traffic ? 'enabled' : 'disabled';
    let route_mode = route_type + ';car;traffic:' + traffic_mode; // 'fastest;car;traffic:disabled'
    const routeRequestParams = {
      mode: route_mode,
      representation: 'overview',
      departure: dt_start.toISOString(), // converted to UTC, '2013-07-04T17:00:00+02', // arrival
      routeattributes: 'summary',
      legAttributes: 'summary',
      maneuverattributes: '',
      'vehicle[speedCap]': this.get_meters_per_second(this.default_bus_speed_kph)
    };
    for (let i = 0; i < waypoints.length; i++)
      routeRequestParams[`waypoint${i}`] = `${waypoints[i][0]},${waypoints[i][1]}`;
    return new Promise(function (resolve, reject) {
      router.calculateRoute(routeRequestParams,
        function (result: any) { resolve(result); },
        function (error: any) { console.log('HereMapsService.getRouteSummary_v7 error'); reject(error); });
    });
  }

  // --------------------------------------------------------------------
  public getRouteSummary_v8(waypoints: [string, string][], dt_start: Date, route_type: string): Promise<any> {
    return this.getRouteSummary(waypoints, dt_start);
  }

  // --------------------------------------------------------------------
  public getRouteDetails_v7(waypoints: [string, string][], dt_start: Date, use_traffic: boolean): Promise<any> {
    const platform = this.platform;
    const router = platform.getRoutingService();
    const route_mode = use_traffic ? 'fastest;car;traffic:enabled' : 'fastest;car;traffic:disabled';
    const routeRequestParams = {
      mode: route_mode,
      representation: 'display',
      departure: dt_start.toISOString(), // converted to UTC, '2013-07-04T17:00:00+02', // arrival
      routeattributes: 'waypoints,summary,shape,legs',
      legAttributes: 'waypoint,length,travelTime,trafficTime,summary',
      maneuverattributes: 'direction,action',
      'vehicle[speedCap]': this.get_meters_per_second(this.default_bus_speed_kph),
      language: 'pl-pl'
    };
    for (let i = 0; i < waypoints.length; i++)
      routeRequestParams[`waypoint${i}`] = `${waypoints[i][0]},${waypoints[i][1]}`;
    return new Promise(function (resolve, reject) {
      router.calculateRoute(routeRequestParams,
        function (result: any) { resolve({ route: result, params: routeRequestParams }); },
        function (error: any) { console.log('HereMapsService.getRouteDetails_v7 error'); reject(error); });
    });
  }

  // --------------------------------------------------------------------
  public getRouteDetails_v8(waypoints: [string, string][], dt_start: Date) {
    return this.getRouteDetails(waypoints, dt_start);
  }

  // --------------------------------------------------------------------  
  public geocodeResult_v7(result: any): any {
    let ret = null;
    if (result)
      if (result.items)
        if (result.items.length > 0) {
          console.log(result.items);
        }
    return ret;
  }
}
