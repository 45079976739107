import { AfterViewInit, Component, OnInit, SecurityContext } from '@angular/core';
import { environment } from '../../environments/environment';
import { HereMapsTestingService } from '../_services/heremaps-testing.service';
import { AppHelperService } from '../_services/app-helper.service';
import { from } from 'rxjs';
import { Data } from 'popper.js';
import { Address } from '../address';

declare var H: any;

/* params: 
  transportMode: car
  routingMode: fast
  departureTime: 2022-04-08T13:18:52.000Z
  return: summary,travelSummary,polyline,actions,instructions,turnByTurnActions
  spans: names,length,duration
  vehicle[speedCap]: 36.111111111111117
  lang: pl-pl
  origin: 51.378840,5.111880
  via: 51.768660,6.463820
  via: 51.509280,7.485430
  via: 51.693660,7.859450
  via: 51.762890,7.871510
  via: 52.207220,8.021580
  via: 52.063320,9.138120
  via: 52.351090,9.836120
  via: 52.346460,10.314370
  via: 52.270560,15.379880
  destination: 51.019102,16.753975
  apikey: uCrkvmThJD0BeH2O7oO0sQ6gXZx9f65sCsjMgtmjMDY
*/


@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit, AfterViewInit {

  public appmode = 'unknown';
  public dt_start = new Date(2022, 3, 8);
  public arrgps: [string, string][] = [];
  public get_route_details_7_status = 'not started';
  public get_route_details_8_status = 'not started';
  public get_route_summary_7_status = 'not started';
  public get_route_summary_8_status = 'not started';

  private the_map: any = null;
  public route_distance = '';
  public route_time = '';
  public route_time_2 = '';
  public route_details_data: any = null;
  public addr_free_text = '';
  public addr_kod = '';
  public addr_miejsc = '';
  public addr_ulica = '';
  public addr_nrdomu = '';
  public addr_kraj = '';

  // ---------------------------------------------------------------------------
  constructor(
    private maps_service: HereMapsTestingService,
    private apphelper: AppHelperService
  ) { }

  // ---------------------------------------------------------------------------
  ngOnInit(): void {
    this.appmode = environment.production ? 'production' : 'debug';
  }

  // ---------------------------------------------------------------------------
  ngAfterViewInit(): void {
    this.the_map = this.maps_service.createHereMap();

    // this.add_map_markers();
  }

  // ---------------------------------------------------------------------------
  public add_map_markers(): void {
    this.arrgps.forEach((point) => {
      const mapobj = this.maps_service.marker_create_default(point[0], point[1]);
      this.the_map.addObject(mapobj);
    });
    if (this.arrgps.length > 0) {
      const centerPoint = new H.geo.Point(this.arrgps[0][0], this.arrgps[0][1]);
      this.the_map.setCenter(centerPoint, true);
    }
  }

  // ---------------------------------------------------------------------------
  public searchFreeText() {
    console.log("Searching for " + this.addr_free_text);
    this.maps_service.clearHereMap(this.the_map);
    const _this = this;
    let addr = new Address(this.apphelper);
    addr.fulltext = this.addr_free_text;
    from(this.maps_service.geocodePromise(addr))
      .subscribe(
        data => {
          console.log("success", data);
          _this.arrgps = [];
          if (data && data.items && data.items.length > 0) {
            for (let i = 0; i < data.items.length; i++) {
              const item = data.items[i];
              _this.arrgps.push([item.position.lat, item.position.lng]);
            }
          }
          _this.add_map_markers()
        },
        error => {
          console.log("error", error);
        },
        () => {
          console.log("and finally here");
        }
      );
  }

  // ---------------------------------------------------------------------------
  public searchAddress() {
    let addr = new Address(this.apphelper);
    if (this.addr_kod.length > 0)
      addr.postal_code = this.addr_kod;
    if (this.addr_miejsc.length > 0)
      addr.city = this.addr_miejsc;
    if (this.addr_ulica.length > 0)
      addr.street = this.addr_ulica;
    if (this.addr_nrdomu.length > 0)
      addr.house_number = this.addr_nrdomu;
    if (this.addr_kraj.length > 0)
      addr.country = this.addr_kraj;

    const s_addr = addr.get_structured_address();

    console.log("Searching for " + s_addr);
    this.maps_service.clearHereMap(this.the_map);
    const _this = this;
    from(this.maps_service.geocodePromise(addr))
      .subscribe(
        data => {
          console.log("success", data);
          _this.arrgps = [];
          if (data && data.items && data.items.length > 0) {
            for (let i = 0; i < data.items.length; i++) {
              const item = data.items[i];
              _this.arrgps.push([item.position.lat, item.position.lng]);
            }
          }
          _this.add_map_markers()
        },
        error => {
          console.log("error", error);
        },
        () => {
          console.log("and finally here");
        }
      );
  }

}
