import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Settings } from '../settings';
import { AppHelperService } from '../_services/app-helper.service';

class LoginModel {
  public username: string;
  public password: string;
  public dbname: string;

  constructor() {
    this.username = '';
    this.password = '';
    this.dbname = '';
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: LoginModel;
  loading = false;
  returnUrl: string;
  errorUrl: string;

  constructor(
    private data_service: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private apphelper: AppHelperService) {
    this.model = new LoginModel();
  }

  ngOnInit() {
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/przeglad';
    this.errorUrl = '/logout';
  }

  login() {
    this.loading = true;
    this.authService.login(this.model.username, this.model.password, this.model.dbname)
      .subscribe(
        (login_data: any) => {
          this.data_service.getSettingsAll$().subscribe(
            (sett_data: any) => {
              const _sett: Settings = new Settings();
              for (const it of sett_data) {
                if (it)
                  _sett[it['PARAMNAME']] = it['PARAMVALUE'];
              }
              if (_sett.is_valid()) {
                this.apphelper.saveSettings(_sett);
                this.router.navigate([this.returnUrl]);
              }
              else {
                this.router.navigate([this.errorUrl]);
              }
            },
            (sett_error: any) => { console.log('settings load error: ', sett_error); },
            () => { }
          )
        },
        (login_error: any) => {
          let message = '';
          if (login_error.status === 401)
            message = 'Nieprawidłowa nazwa użytkownika lub hasło';
          this.alertService.error(message);
          this.loading = false;
        },
        () => { }
      );
  }

}
